import Instance from '../../../utils/Axios';

// eslint-disable-next-line no-unused-vars
const EditarFuncionario = (id, ydata, show, toggle) => async (dispatch) => {
  //   const arrayEquality = JSON.stringify(oldUser) === JSON.stringify(ydata);

  await Instance()
    .put(`/recursos/funcionarios/${id}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_FUNCIONARIO', payload: data.yData[0] });
      show('success', 'Funcionário editado');
      // toggle();
    })
    .catch((err) => {
      if (err.response.status != 200) {
        show('warning', 'Verifique todos os campos');
      }
    });
};

export default EditarFuncionario;
