/* eslint-disable react/self-closing-comp */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Autocomplete, Box } from '@mui/material';

// Redux

// Actions

// Components

const RoleRecursosHumanos = ({
  infoValIndiv,
  tiposfaltas,
  disabledFaltou,
  valueTipoFaltaAutoComplete,
  setValueTipoFaltaAutoComplete,
  width,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [horas, setHoras] = useState(infoValIndiv.qtd);

  const [valueObservacoesTextField, setValueObservacoesTextField] = useState('');
  const [checkbox, setCheckbox] = useState((infoValIndiv && infoValIndiv.validadoRH) || false);

  useEffect(() => {
    setHoras(infoValIndiv.qtd);
    setCheckbox(infoValIndiv.validadoRH);
    const tipoFaltaSelected = tiposfaltas.find((falta) => falta.id === infoValIndiv.tipofaltas_id);

    if (tipoFaltaSelected && infoValIndiv.faltou) {
      setValueTipoFaltaAutoComplete(tipoFaltaSelected);
    } else {
      setValueTipoFaltaAutoComplete(null);
    }

    setValueObservacoesTextField(infoValIndiv.observacoesRH || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoValIndiv]);

  const handleChangeFalta = (value) => {
    setValueTipoFaltaAutoComplete(value);

    if (value) {
      infoValIndiv.tipofaltas_id = value.id;
    } else {
      infoValIndiv.tipofaltas_id = null;
    }
    // dispatch(GuardarTempos(infoValIndiv));
  };

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', my: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Recursos Humanos
        </h4>
      </Box>
      <Box sx={{ display: 'flex', my: 2 }}>
        <Box sx={{ width: width < 1100 ? '0%' : '20%' }}></Box>
        <Box sx={{ width: width < 1100 ? '28%' : '10%', mr: 2, mt: 2.5, height: '40px' }}>
          <FormControlLabel
            control={<Checkbox checked={checkbox} disabled />}
            label="Validado"
            // style={{ width: '20%' }}
          />
        </Box>
        <Box sx={{ width: '18%', mr: '2%' }}>
          <b>Tipo de Falta</b>
          <Autocomplete
            id="tipoFaltaId"
            name="tipoFaltaId"
            noOptionsText="Sem dados"
            onChange={(e, value) => handleChangeFalta(value)}
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            disabled={disabledFaltou}
            value={valueTipoFaltaAutoComplete}
            options={tiposfaltas || [{ id: 0, descricao: '' }]}
            getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
            filterOptions={(options, state) => {
              return options.filter(
                (option) =>
                  option.id > 0 &&
                  `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
              );
            }}
            getOptionSelected={(option, value) => {
              // if (!value.id) return true;

              return value.id === option.id;
            }}
            autoComplete
            style={{ width: '100%' }}
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                // required
                // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
              />
            )}
          />
        </Box>

        <Box sx={{ width: '50%' }}>
          <b>Obs. Recursos Humanos</b>
          <TextField
            name="observacoes"
            variant="outlined"
            style={{ width: '100%' }}
            size="small"
            value={valueObservacoesTextField || ''}
            disabled
          />
        </Box>
      </Box>
    </>
  );
};

export default RoleRecursosHumanos;
