import Instance from '../../../../utils/Axios';

const RelatorioVistaGeralDeProjeto = (funcionario, obra) => async (dispatch) => {
  const funcionarioId = funcionario && funcionario.id;
  const obrasId = obra && obra.id;

  await Instance()
    .get(`/relatorios/vistaglobalprojeto`, {
      params: { funcionario: funcionarioId, obra: obrasId },
    })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_RELATORIO_VISTA_GERAL_DE_PROJETO', payload: data });
    })
    .catch(({ err }) => console.log(err));
};

export default RelatorioVistaGeralDeProjeto;
