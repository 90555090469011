import Instance from '../../../utils/Axios';

const EditarUserPermissoes = (id, ydata, show) => async (dispatch) => {
  await Instance()
    .put(`/users/${id}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_USER', payload: data.yData[0] });

      show('success', 'Permissões Associadas');
    })
    .catch((err) => {
      if (err.response.status != 200) {
        show('warning', 'Verifique todos os campos');
      }
    });
};

export default EditarUserPermissoes;
