import Instance from '../../../../utils/Axios';

const RemoverFuncionarioObservacao = (obserId, show) => async (dispatch) => {
  await Instance()
    .delete(`/recursos/observacoes/${obserId}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_FUNCIONARIO_OBSERVACAO', payload: data });
      show('success', 'A observação selecionada foi eliminada');
    })
    .catch(({ err }) => {
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Não foi possivel remover a obervação selecionada`);
      }
    });
};

export default RemoverFuncionarioObservacao;
