import Instance from '../../../../utils/Axios';

const RemoverAusencia = (id, ausenciaId, show) => async (dispatch) => {
  console.log(id, ausenciaId);
  await Instance()
    .delete(`/recursos/ausencias/${ausenciaId}/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_AUSENCIA', payload: data });
      show('success', 'A ausência do funcionário foi eliminada');
    })
    .catch(({ err }) => {
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Preencha todos os campos`);
      }
    });
};

export default RemoverAusencia;
