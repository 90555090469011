/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Box, createTheme, FormGroup, TextField, ThemeProvider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#E21450' },
  },
  typography: {
    fontFamily: ['roboto', 'sans-serif'].join(','),
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected:hover': { backgroundColor: '#CCC' },

        '& td[class*="MUIDataTableSelectCell-fixedLeft-"]': {
          zIndex: '90 !important',
        },
      },
    },
    MuiTableHead: {
      root: {
        '& th[class*="MUIDataTableSelectCell-headerCell-"]': {
          zIndex: '90 !important',
        },
        '& th[class*="MUIDataTableHeadCell-fixedHeader-"]': {
          zIndex: '90 !important',
        },
      },
    },
  },
});

const DatePickerAusencias = ({
  handleDataInicioAusenciaChange,
  handleDataFimAusenciaChange,
  formatedDataInicio,
  formatedDataFim,
  errorsAusencia = null,
}) => {
  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Row>
          <Col md={12} sm={12} style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <b>Data Inicio</b>
                <DatePicker
                  style={{ width: '100%' }}
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={formatedDataInicio}
                  onChange={handleDataInicioAusenciaChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  {...(errorsAusencia.dataInicio && {
                    error: true,
                    helperText: errorsAusencia.dataInicio,
                  })}
                />
              </LocalizationProvider>
            </Box>
            <span style={{ width: '4%' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <b>Data Fim</b>
                <DatePicker
                  style={{ width: '100%' }}
                  margin="normal"
                  id="date-picker-dialog"
                  format="dd/MM/yyyy"
                  okLabel="ok"
                  cancelLabel="cancelar"
                  invalidDateMessage="Data Inválida"
                  value={formatedDataFim}
                  onChange={handleDataFimAusenciaChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  // minDate={dtMinDate}
                  // maxDate={dtMaxDate}
                  {...(errorsAusencia.dataFim && {
                    error: true,
                    helperText: errorsAusencia.dataFim,
                  })}
                />
              </LocalizationProvider>
            </Box>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col>
            <FormGroup>
              {/* <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={historico.historico}
                                  onChange={handleChangeHistorico}
                                  name="historico"
                                />
                              }
                              label="Histórico"
                            /> */}
            </FormGroup>
          </Col>
        </Row>
      </ThemeProvider>
    </>
  );
};

export default DatePickerAusencias;
