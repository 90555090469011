/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { format } from 'date-fns';
import Collapse from '../../../../../../../shared/components/Collapse';

const ListaHistorico = ({ registos }) => {
  const recursos = registos.funcionarios;

  const data = format(new Date(`${registos.data}`), 'dd/MM/yyyy');

  return (
    <Col md={12} lg={12}>
      <Collapse style={{ backgroundColor: 'lightblue' }} title={data} className="with-shadow">
        <>
          {recursos.map((funcionarios) => (
            <Fragment key={funcionarios.recursos_id}>
              <div className="stripedes">
                <Container>
                  <Row
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Col md="3" style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <p>
                        &nbsp; {funcionarios.recursos_nome} - {funcionarios.recursos_nome2}
                      </p>
                    </Col>
                    <Col md="2" style={{ marginTop: '10px' }}>
                      <p>&nbsp; {funcionarios.qtd}H</p>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Fragment>
          ))}
        </>
      </Collapse>
    </Col>
  );
};

export default ListaHistorico;
