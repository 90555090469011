import Instance from '../../../utils/Axios';

const EncarregadosRecursos = () => async (dispatch) => {
  const stateToken = localStorage.token;
  if (stateToken) {
    await Instance()
      .get('/recursos/encarregados')
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_ENCARREGADOS', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default EncarregadosRecursos;
