import Instance from '../../../utils/Axios';

const ApagarUser = (id) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .delete(`/users/${id}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'DELETE_USER', payload: data });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default ApagarUser;
