import Instance from '../../../../utils/Axios';

const EditarAllDiasFeriasRecursos = (recursoId, yearChosen, ydata, show) => async (dispatch) => {
  if (recursoId) {
    await Instance()
      .put(`/syncdiasferias/all_dias_ferias_recurso/${recursoId}/${yearChosen}`, { ydata })
      .then(({ data }) => {
        dispatch({ type: 'PUT_ALL_DIAS_FERIAS_RECURSO', payload: data });
        show('success', 'As Férias selecionadas, foram editadas');
      })
      .catch((err) => {
        // eslint-disable-next-line eqeqeq
        if (err.response.status != 200) {
          show('warning', 'Preencha todos os campos');
        }
      });
  }
};

export default EditarAllDiasFeriasRecursos;
