/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import withAuthFirebase from '../../../shared/components/auth/withAuthFirebase';
import DefinirSenha from './DefinirSenhaForm';
import SenhaJaAssociada from './SenhaJaAssociada';
import { DadosSignupAction } from '../../../redux/actions/UsersActions/DadosSignupAction';

const DefSenha = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hash = location.pathname.split('/')[2];
  const fetch = () => {
    dispatch(DadosSignupAction(hash));
  };
  const storage = localStorage;

  const dados = useSelector((state) => state.users.dadosDefSenha);

  useEffect(() => fetch(), []);

  return (
    <>
      {storage.token ? (
        navigate('/')
      ) : (
        <div className="account account--photo">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <span className="account__logo" />
              </div>
              {dados === undefined ? (
                fetch()
              ) : (
                <div>
                  {dados.length === 0 ? (
                    <SenhaJaAssociada onSubmit form="log_in_form" fetch={fetch} hash={hash} />
                  ) : (
                    <DefinirSenha onSubmit form="log_in_form" fetch={fetch} hash={hash} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

DefSenha.propTypes = {};

export default withAuthFirebase(DefSenha);
