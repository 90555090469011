import Instance from '../../../utils/Axios';

const RemoverTipoPeriodo = (id, show) => async (dispatch) => {
  await Instance()
    .delete(`/tipoperiodos/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_TIPOPERIODO', payload: data });
      show('success', 'Tipo de período removido com sucesso!');
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverTipoPeriodo;
