/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Col } from 'reactstrap';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

// PDF
import PDFGenerator from './pdfGenerator';

const RelatorioObraMensal = ({ fdate, btnObterDados, setBtnObterDados, classes }) => {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  const ydata = useSelector((state) => state.relatorios.relatorioVistaGeralDeProjeto.funcionarios);
  const RelatorioData = useSelector(
    (state) => state.relatorios.relatorioVistaGeralDeProjeto.funcionarios,
  );

  const ano = fdate.getFullYear();
  const mes = fdate.getMonth() + 1;
  const data = format(new Date(), 'dd-MM-yyyy');
  let mesAnterior = mes - 1;
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  const columns = [
    {
      name: 'nome2',
      label: 'Nome',
    },
    {
      name: 'obraDescricao',
      label: 'Obra',
    },
    {
      name: 'dataUltimoRegisto',
      label: 'Último Registo',
      options: {
        filter: true,
        // sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta) => {
          let ultimoRegisto = value;

          if (value.length > 0) {
            ultimoRegisto = format(parseISO(value), 'dd-MM-yyyy');
          }

          return (
            <>
              <div>{ultimoRegisto}</div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    search: false,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: true,
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
  };

  const handlePdf = (ydata, RelatorioData) => (e) => {
    e.preventDefault();

    // PDF(ano, mes, data, horas, obras);
    // PDFGenerator(ano, mes, data, horas, obras);
    PDFGenerator(ydata, ano, mes, data, RelatorioData);
    setBtnObterDados(false);
  };

  return (
    <React.Fragment>
      <Col md={12} sm={12}>
        <div style={{ textAlign: 'right' }}>
          <Button
            size="small"
            // color="primary"
            variant="contained"
            onClick={handlePdf(ydata, RelatorioData)}
            style={{ marginTop: '2px' }}
            className={classes.btnLightGreen}
            disabled={!btnObterDados || (ydata && ydata.length <= 0) || ydata == undefined}
          >
            Gerar PDF
          </Button>
        </div>
        <MUIDataTable
          title=""
          description="Nada para mostrar"
          data={ydata}
          columns={columns}
          options={options}
        />
      </Col>
    </React.Fragment>
  );
};

export default RelatorioObraMensal;
