/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// ACTIONS
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Box,
} from '@mui/material';
import { format } from 'date-fns';
import RegistoForm from './RegistoForm';
import EditarRegistoEscritorio from '../../../../../../../../redux/actions/TemposActions/Escritorio/Editar/EditarRegistoEscritorioAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const ModalEditar = ({
  openEditar,
  handleClose,
  validateOnChange = true,
  funcionariosEscritorio,
  novoRegisto,
  setNovoRegisto,
  useWindowDimensions,
  validationNovoRegistoDiario,
  errorsNovoRegistoDiario,
  show,
  classes,
  horas,
  setHoras,
  valueTipoAtrasoAutoComplete,
  setValueTipoAtrasoAutoComplete,
  valueTipoPeriodoAutoComplete,
  setValueTipoPeriodoAutoComplete,
  registoId,
  obraId,
  handleConsultar,
  loading,
  setLoading,
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const registoEscritorio = useSelector((state) => state.registoEscritorio);
  const [disabledFaltou, setDisabledFaltou] = useState(
    registoEscritorio && !registoEscritorio.faltou,
  );

  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);

  const [checkbox, setCheckbox] = useState(
    (registoEscritorio && registoEscritorio.faltou) || false,
  );

  // console.log(checkbox);

  useEffect(() => {
    setNovoRegisto({
      ...novoRegisto,
      dataInicio: new Date(),
    });
  }, []);

  useEffect(() => {
    if (registoEscritorio) {
      setHoras(registoEscritorio.qtd);
      setCheckbox(registoEscritorio.faltou);
    }
    setDisabledFaltou(registoEscritorio && !registoEscritorio.faltou);
  }, [registoEscritorio]);

  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    if (value) {
      setNovoRegisto({
        ...novoRegisto,
        funcionarioId: value && value.id,
      });
    } else {
      setNovoRegisto({
        ...novoRegisto,
        funcionarioId: 0,
      });
    }
    if (validateOnChange) validationNovoRegistoDiario({ funcionarioId: value && value.id });
  };

  // -------------------------- Handle Submit -----------------------------//

  const handleSubmit = (e) => {
    e.preventDefault();

    let ydata = [];

    if (validationNovoRegistoDiario()) {
      setLoading(true);
      const formatedDataInicio = format(novoRegisto && novoRegisto.dataInicio, 'yyyy-MM-dd');
      const atrasos = () => {
        if (novoRegisto && novoRegisto.tipoAtrasoId && novoRegisto.tipoAtrasoId.id > 0) {
          return novoRegisto.tipoAtrasoId.id;
        }
        return '';
      };
      const periodos = () => {
        if (novoRegisto && novoRegisto.tipoPeriodoId && novoRegisto.tipoPeriodoId.id > 0) {
          return novoRegisto.tipoPeriodoId.id;
        }
        return '';
      };
      ydata = [
        {
          obraId: novoRegisto.obraId,
          funcionarioId: novoRegisto.funcionarioId && novoRegisto.funcionarioId.id,
          data: formatedDataInicio,
          qtd: novoRegisto.qtd,
          tipoatrasosId: atrasos(),
          tipoperiodoId: periodos(),
          faltou: novoRegisto.faltou,
          observacoes: novoRegisto.observacoes,
        },
      ];
      console.log(ydata);
      dispatch(EditarRegistoEscritorio(ydata, registoId, obraId, show))
        .then(() => handleConsultar(e))
        .then(() => handleClose('edit', e));
      // .then(() => setLoading(false));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEditar}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEditar}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 id="transition-modal-title" className="text-underline-title-dark">
                Editar Registo
              </h3>
              <p id="transition-modal-description">Pode editar aqui um registo de horas.</p>
            </div>

            <div>
              <b>Funcionário</b>
              <Autocomplete
                name="funcionario"
                noOptionsText="Sem dados"
                size="small"
                clearText="Limpar"
                openText="Abrir"
                closeText="Fechar"
                onChange={(e, value) => handleChangeFuncionario(e, value)}
                value={novoRegisto && novoRegisto.funcionarioId}
                options={
                  funcionariosEscritorio || [
                    {
                      ativo: 0,
                      categoriaId: '0',
                      categoriaNome: '',
                      horasMensais: 0,
                      id: '0',
                      nome: '',
                      nome2: '',
                      operacional: 0,
                      tipo: 0,
                      valorHora: 0,
                      valorMensal: 0,
                    },
                  ]
                }
                getOptionLabel={(option) =>
                  `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
                }
                id="auto-complete"
                autoComplete
                includeInputInList
                filterOptions={(options, state) => {
                  return options.filter(
                    (option) =>
                      option.id > 0 &&
                      `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                  );
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // required={user.recursosId.length === 0}
                    {...(errorsNovoRegistoDiario.funcionarioId && {
                      error: true,
                      helperText: errorsNovoRegistoDiario.funcionarioId,
                    })}
                  />
                )}
              />
            </div>

            <br />
            <div>
              <RegistoForm
                novoRegisto={novoRegisto}
                setNovoRegisto={setNovoRegisto}
                classes={classes}
                horas={horas}
                setHoras={setHoras}
                useWindowDimensions={useWindowDimensions}
                // autocompletes
                valueTipoAtrasoAutoComplete={valueTipoAtrasoAutoComplete}
                setValueTipoAtrasoAutoComplete={setValueTipoAtrasoAutoComplete}
                valueTipoPeriodoAutoComplete={valueTipoPeriodoAutoComplete}
                setValueTipoPeriodoAutoComplete={setValueTipoPeriodoAutoComplete}
                tiposperiodos={tiposperiodos}
                tiposatrasos={tiposatrasos}
                validationNovoRegistoDiario={validationNovoRegistoDiario}
                errorsNovoRegistoDiario={errorsNovoRegistoDiario}
                // editar
                checkbox={checkbox}
                setCheckbox={setCheckbox}
                registoEscritorio={registoEscritorio}
                disabledFaltou={disabledFaltou}
                setDisabledFaltou={setDisabledFaltou}
              />
            </div>
            <br />
            <br />
            <div className={classes.modalBtns}>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>

              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('edit', e)}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEditar;
