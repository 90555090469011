/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, InputAdornment, IconButton, Autocomplete } from '@mui/material';
// import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import MUIDataTable from 'mui-datatables';

import GetTiposFaltas from '../../../../../../redux/actions/TiposFaltasAction/tiposFaltasAction';

import GetFuncionarioAusencias from '../../../../../../redux/actions/FuncionariosActions/ausencias/getAusenciasAction';

import { FiEdit, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';

// MODALS
// import ModalDelete from './modals/modalDelete';
// import ModalRedefinirSenha from './modals/modalRedefinirSenha';
// import ModalEditarUtilizador from './modals/modalEditarUtilizador';

const CardFaltas = ({
  funcId,
  dataInicioConsulta,
  dataFimConsulta,
  handleDataInicioConsultaChange,
  handleDataFimConsultaChange,
  funcAusencias,
  handleOpen,
  classes,
  width,
  faltaSelecionada,
  setFaltaSelecionada,
  // setDisableFaltou,
  // tiposfaltas,
  ausencia,
  setAusencia,
  validateOnChange = true,
  validationAusencia,
}) => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const handleChangeFalta = (e, value) => {
    setFaltaSelecionada(value);
    setAusencia({ ...ausencia, tipoFaltaId: value && value.id });

    if (validateOnChange) validationAusencia({ tipoFaltaId: value });
  };

  // const id = location.pathname.split('/')[4];
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const tiposFaltasDrop = useSelector((state) => state.tiposFaltas.tiposFaltas);

  const fetchTipoFalta = () => {
    dispatch(GetTiposFaltas());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchTipoFalta(), []);

  const handleConsultar = (e) => {
    e.preventDefault();
    const dataInicioFormated = format(dataInicioConsulta, 'yyyy-MM-dd');
    const dataFimFormated = format(dataFimConsulta, 'yyyy-MM-dd');

    dispatch(
      GetFuncionarioAusencias(funcId, dataInicioFormated, dataFimFormated, faltaSelecionada),
    );
  };

  const fetchAusencias = (funcId, dataInicioFormated, dataFimFormated, faltaSelecionada) => {
    dispatch(
      GetFuncionarioAusencias(funcId, dataInicioFormated, dataFimFormated, faltaSelecionada),
    );
  };

  useEffect(() => {
    const dataInicioFormated = format(dataInicioConsulta, 'yyyy-MM-dd');
    const dataFimFormated = format(dataFimConsulta, 'yyyy-MM-dd');
    fetchAusencias(funcId, dataInicioFormated, dataFimFormated, faltaSelecionada);
  }, []);

  const columns = [
    {
      name: 'descricaoTiposFaltas',
      label: 'Tipo de Falta',
      options: {
        setCellProps: () => ({ style: { width: '300px' } }),
        filter: true,
        sort: true,
      },
    },
    {
      name: 'datainicio',
      label: 'Data de Início',
      options: {
        filter: false,
        sort: true,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          const formatedCheckIn = format(new Date(tableMeta.rowData[1]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckIn}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'datafim',
      label: 'Data de Fim',
      options: {
        filter: false,
        sort: true,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          const formatedCheckIn = format(new Date(tableMeta.rowData[2]), 'dd-MM-yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedCheckIn}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'observacoes',
      label: 'Observações',
      options: {
        setCellProps: () => ({ style: { width: '500px' } }),
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'utilizadorNome',
      label: 'Utilizador',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'visibilidade',
      label: 'Visibilidade',
      options: {
        filter: false,
        sort: true,
        empty: true,
        display: false,
      },
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposfaltas">
              {tableMeta.rowData[5] !== '2' ? (
                <Button
                  onClick={(e) =>
                    // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                    handleOpen('edit', e, value, tableMeta)
                  }
                >
                  <FiEdit size={30} strokeWidth={1} color="#000" />
                </Button>
              ) : null}

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    searchText: searchText,
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'Limpar',
      },
    },
  };

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2, flexWrap: 'wrap' }}>
        <Box
          sx={{
            width: width < 1100 ? '33%' : '20%',
            mr: '2%',
            mb: width < 1100 ? 2 : 0,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <p>
              <b>Data Inicio</b>
            </p>
            <DatePicker
              style={{ width: '100%' }}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dataInicioConsulta}
              onChange={handleDataInicioConsultaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableunderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            width: width < 1100 ? '33%' : '20%',
            mb: width < 1100 ? 2 : 0,
            mr: '2%',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <p>
              <b>Data Fim</b>
            </p>
            <DatePicker
              style={{ width: '100%' }}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dataFimConsulta}
              onChange={handleDataFimConsultaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
              // minDate={dtMinDate}
              // maxDate={dtMaxDate}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            width: width < 1100 ? '33%' : '20%',
            mb: width < 1100 ? 2 : 0,
            mr: '2%',
          }}
        >
          <b>Tipo de Falta</b>
          <Autocomplete
            id="tipoFaltaId"
            name="tipoFaltaId"
            noOptionsText="Sem dados"
            onChange={(e, value, reason) => {
              if (reason == 'clear') {
                setFaltaSelecionada({
                  id: 0,
                  descricao: '',
                });
              } else {
                handleChangeFalta(e, value);
              }
            }}
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            value={faltaSelecionada}
            options={tiposFaltasDrop || [{ id: 0, descricao: '' }]}
            getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
            filterOptions={(opt, state) => {
              return opt.filter(
                (option) =>
                  option.id > 0 &&
                  `${option.descricao}`.toLowerCase().includes(state.inputValue.toLowerCase()),
              );
            }}
            isOptionEqualToValue={(option, value) => {
              // if (!value.id) return true;

              return value.id === option.id;
            }}
            autoComplete
            style={{ width: '100%' }}
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                // label="Tipo de Falta"
                placeholder="Tipo de Falta"
                // variant="outlined"
                size="small"
                // required
              />
            )}
          />
        </Box>
        {/* <Box sx={{ width: width < 1100 ? '0%' : '23%' }}></Box> */}
        <Box sx={{ width: width < 1100 ? '30%' : '8%', mr: width < 1100 ? 0 : '2%', mt: 2.5 }}>
          <Button
            size="sm"
            variant="contained"
            onClick={handleConsultar}
            fullWidth
            // sx={{ width: width < 1100 ? '100px' : '100%' }}
            // style={{
            //   margin: 'auto 0 auto auto',
            // }}
            className={classes.btnConsultar}
          >
            Filtrar
          </Button>
        </Box>
        <Box sx={{ width: width < 1100 ? '78%' : '18%', mr: '2%' }}>
          <b>Pesquisar</b>
          <TextField
            variant="outlined"
            size="small"
            name="search"
            fullWidth
            type="text"
            label="Pesquisar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <FiSearch />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ width: width < 1100 ? '20%' : '3%', mt: 2.5 }}>
          <Button
            sx={{ border: '1px solid #000', minWidth: '45px' }}
            onClick={(e) => handleOpen('add', e)}
          >
            <FiPlus size={25} strokeWidth={1.5} />
          </Button>
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Registos
        </h4>
      </Box>
      <MUIDataTable
        description="Nada para mostrar"
        data={funcAusencias}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default CardFaltas;
