import Instance from '../../../../utils/Axios';

const RelatorioMensalHoras =
  (dataInicio, dataFim, funcionario, obra, ordem) => async (dispatch) => {
    const funcionarioId = funcionario && funcionario.id;
    const obrasId = obra && obra.id;

    console.log('ordem', typeof ordem);

    await Instance()
      .get(`/relatorios/mensal`, {
        params: {
          dataInicio,
          dataFim,
          funcionario: funcionarioId,
          obra: obrasId,
          ordenacao: ordem,
        },
      })
      .then(({ data }) => {
        dispatch({ type: 'GET_RELATORIO_MENSAL_HORAS', payload: data });
      })
      .catch(({ err }) => console.log(err));
  };

export default RelatorioMensalHoras;
