import Instance from '../../../../utils/Axios';

const RemoverFuncionarioFerias = (feriasId, show) => async (dispatch) => {
  console.log(feriasId);
  await Instance()
    .delete(`/recursos/ferias/${feriasId}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_FUNCIONARIO_FERIAS', payload: data });
      show('success', 'As ferias selecionadas foram eliminadas');
    })
    .catch(({ err }) => {
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Não foi possivel remover as férias selecionadas`);
      }
    });
};

export default RemoverFuncionarioFerias;
