import Instance from '../../../../utils/Axios';

const GetFuncionarioObservacoes = (funcionarioId) => async (dispatch) => {
  const stateToken = localStorage.token;
  await Instance()
    .get(`/recursos/observacoes/${funcionarioId}`)
    .then(({ data }) => {
      if (stateToken && dispatch) {
        dispatch({ type: 'GET_FUNCIONARIO_OBSERVACOES', payload: data.yData });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default GetFuncionarioObservacoes;
