/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import ptLocale from 'date-fns/locale/pt';
import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Box,
  Autocomplete,
} from '@mui/material';
import { FiSearch, FiPlus } from 'react-icons/fi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const RegistarHorasForm = ({
  dataInicio,
  dataFim,
  funcionariosEscritorio,
  classes,
  handleDataInicioChange,
  handleDataFimChange,
  handleChangeFuncionario,
  handleConsultar,
  handleOpen,
  searchText,
  setSearchText,
}) => {
  // const handleClick = (e) => {
  //   handleOpen('add', e);
  // };
  const { width } = useWindowDimensions();

  return (
    <>
      <Col md={12} sm={12}>
        <Box sx={{ display: 'flex', mb: 2, flexWrap: 'wrap' }}>
          <Box
            sx={{
              width: width < 1100 ? '49%' : '15%',
              mr: '2%',
              mb: width < 1100 ? 2 : 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
              <b>Data Inicio</b>
              <DatePicker
                format="dd/MM/yyyy"
                okLabel="ok"
                style={{ width: '100%' }}
                cancelLabel="cancelar"
                invalidDateMessage="Data Inválida"
                maxDateMessage="Não pode exceder o atual dia"
                minDateMessage="Apenas pode ver 3 dias atrás"
                value={dataInicio}
                onChange={handleDataInicioChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // components={{
                //   OpenPickerIcon: calendarIcon,
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'dd/mm/aaaa',
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="outlined"
                    size="small"
                    // sx={{
                    //   width: '11%',
                    //   marginRight: '3.5%',
                    //   marginTop: '2px',
                    // }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              width: width < 1100 ? '49%' : '15%',
              mr: width < 1100 ? 0 : '2%',
              mb: width < 1100 ? 2 : 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
              <b>Data Fim</b>
              <DatePicker
                format="dd/MM/yyyy"
                okLabel="ok"
                style={{ width: '100%' }}
                cancelLabel="cancelar"
                invalidDateMessage="Data Inválida"
                maxDateMessage="Não pode exceder o atual dia"
                minDateMessage="Apenas pode ver 3 dias atrás"
                value={dataFim}
                onChange={handleDataFimChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // components={{
                //   OpenPickerIcon: calendarIcon,
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'dd/mm/aaaa',
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="outlined"
                    size="small"
                    // sx={{
                    //   width: '11%',
                    //   marginRight: '3.5%',
                    //   marginTop: '2px',
                    // }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              width: width < 1100 ? '100%' : '20%',
              mr: width < 1100 ? 0 : '2%',
              mb: width < 1100 ? 2 : 0,
            }}
          >
            <b>Funcionário</b>
            <Autocomplete
              name="funcionario"
              noOptionsText="Sem dados"
              size="small"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              onChange={(e, value) => handleChangeFuncionario(e, value)}
              options={funcionariosEscritorio || {}}
              getOptionLabel={(option) =>
                `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
              }
              id="auto-complete"
              autoComplete
              includeInputInList
              filterOptions={(filterOptions, state) => {
                return filterOptions.filter(
                  (option) =>
                    option.id > 0 &&
                    `${option.id} - ${option.nome} - ${option.nome2}`
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase()),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // required={user.recursosId.length === 0}
                  // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: width < 1100 ? '100px' : '7%',
              mr: width < 1100 ? 0 : '2%',
              mb: width < 1100 ? 2 : 0,
              mt: width < 1100 ? 0 : 2.5,
            }}
          >
            <Button
              onClick={handleConsultar}
              variant="contained"
              fullWidth
              className={classes.btnConsultar}
            >
              Filtrar
            </Button>
          </Box>
          <Box
            sx={{
              width: width < 1100 ? '100%' : '28%',
              mr: width < 1100 ? 0 : '2%',
              mb: width < 1100 ? 2 : 0,
            }}
          >
            <b>Pesquisar</b>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="search"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <FiSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              width: width < 1100 ? '100px' : '5%',
              mb: width < 1100 ? 2 : 0,
              mt: width < 1100 ? 0 : 2.5,
            }}
          >
            <Button
              sx={{ border: '1px solid #000', minWidth: '45px' }}
              onClick={(e) => handleOpen('add', e)}
            >
              <FiPlus size={25} strokeWidth={1.5} />
            </Button>
          </Box>
        </Box>
      </Col>
    </>
  );
};

export default RegistarHorasForm;
