import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  themeReducer,
  rtlReducer,
  cryptoTableReducer,
  customizerReducer,
  newOrderTableReducer,
  sidebarReducer,
  authReducer,
  obrasReducer,
  recursosReducer,
  temposReducer,
  dashboardReducer,
  usersReducer,
  relatoriosReducer,
  funcionariosReducer,
  tipoPeriodosReducer,
  tipoFaltasReducer,
  tipoAtrasosReducer,
  validacaoReducer,
  globalReducer,
  logsHorasReducer,
  filtersReducer,
  syncFeriasReducer,
} from '../../redux/reducers/index';
import appConfigReducer from '../../redux/reducers/appConfigReducer';

// //Added imports

// Persisted redux

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  appConfig: appConfigReducer,
  cryptoTable: cryptoTableReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  // Novos Reducers
  user: authReducer,
  obras: obrasReducer,
  recursos: recursosReducer,
  tempos: temposReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  relatorios: relatoriosReducer,
  funcionarios: funcionariosReducer,
  tiposPeriodos: tipoPeriodosReducer,
  tiposFaltas: tipoFaltasReducer,
  tiposAtrasos: tipoAtrasosReducer,
  validacao: validacaoReducer,
  global: globalReducer,
  logs: logsHorasReducer,
  filters: filtersReducer,
  ferias: syncFeriasReducer,
});

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

const persistor = persistStore(store);

export { store, persistor };
