/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// ACTIONS
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Box,
} from '@mui/material';
import { Col, Row, Container } from 'reactstrap';
import { grey } from '@mui/material/colors';
import { format, getYear } from 'date-fns';
import DatePickerAusencias from './datePicker/datePickerAusencias';

import AdicionarAusenciasFuncionarios from '../../../../../../../redux/actions/FuncionariosActions/ausencias/adicionarAusenciaAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  ausencia,
  setAusencia,
  validateOnChange = true,
  validationAusencia,
  errorsAusencia = null,
  tiposfaltas,
  funcId,
  show,
  fetchFuncionarioAusencias,
  faltaSelecionada,
  setFaltaSelecionada,
  // Loading
  loading,
  setLoading,
  disableFaltou,
  fetchDiasMarcados,
  fetchDiasDisponiveis,
  feriados,
  setOpen,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [titulo, setTitulo] = useState();
  const diasDisponiveis = useSelector((state) => state.ferias.diasDisponiveis);

  const year = getYear(new Date());

  const handleChangeFalta = (e, value) => {
    setFaltaSelecionada(value);
    setAusencia({ ...ausencia, tipoFaltaId: value && value.id });

    if (validateOnChange) validationAusencia({ tipoFaltaId: value });
  };
  const handleChangeObservacoes = (e) => {
    setAusencia({ ...ausencia, observacoes: e.target.value });
  };

  const [formatedDataInicio, setFormatedDataInicio] = useState();
  const [formatedDataFim, setFormatedDataFim] = useState();

  useEffect(() => {
    if (Object.keys(ausencia).length > 0) {
      setFormatedDataInicio(ausencia.dataInicio);
      setFormatedDataFim(ausencia.dataFim);
    }
  }, [ausencia]);

  const handleDataInicioAusenciaChange = (date) => {
    setAusencia({ ...ausencia, dataInicio: date, dataFim: date });
    if (validateOnChange) validationAusencia({ dataInicio: date, dataFim: date });
  };

  const handleDataFimAusenciaChange = (date) => {
    setAusencia({ ...ausencia, dataFim: date });
    if (validateOnChange) validationAusencia({ dataInicio: formatedDataInicio, dataFim: date });
  };

  const getWorkingDays = (startDate, endDate, holidays) => {
    // Calculate the number of milliseconds in one day
    const oneDay = 24 * 60 * 60 * 1000;

    // Calculate the number of days between the two dates
    const totalDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    // Calculate the number of weekends between the two dates
    let weekends = Math.floor(totalDays / 7) * 2;
    const startDay = startDate.getDay();
    const endDay = endDate.getDay();
    if (startDay - endDay > 0) {
      weekends += 2;
    } else if (startDay === 0 && endDay !== 6) {
      weekends++;
    } else if (endDay === 6 && startDay !== 0) {
      weekends++;
    }

    // Subtract the weekends and holidays from the total number of days
    let workingDays = totalDays - weekends;
    for (let i = 0; i < holidays.length; i++) {
      const feriado = holidays[i].data;
      const holiday = format(new Date(feriado), 'yyyy-MM-dd');
      if (
        holiday >= startDate &&
        holiday <= endDate &&
        holiday.getDay() !== 0 &&
        holiday.getDay() !== 6
      ) {
        workingDays--;
      }
    }

    return workingDays;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fDataInicio = format(new Date(formatedDataInicio), 'yyyy-MM-dd');
    const fDataFim = format(new Date(formatedDataFim), 'yyyy-MM-dd');

    if (ausencia.tipoFaltaId === 3) {
      const workDays = getWorkingDays(new Date(fDataInicio), new Date(fDataFim), feriados);

      // if (workDays <= diasDisponiveis) {
      if (validationAusencia()) {
        setLoading(true);
        const ydata = [
          {
            funcionarioId: funcId,
            datainicio: fDataInicio,
            datafim: fDataFim,
            tiposFaltasId: ausencia.tipoFaltaId,
            observacoes: ausencia.observacoes,
          },
        ];

        dispatch(AdicionarAusenciasFuncionarios(ydata, show))
          .then(() => handleClose('adicionar', e))
          .then(() => fetchDiasMarcados(funcId))
          .then(() => fetchDiasDisponiveis(funcId, year))
          .then(() => fetchFuncionarioAusencias());

        // .then(() => handleOpenAlert('add'));
      }
      // }
      else {
        setOpen(true);
      }
    } else {
      if (validationAusencia()) {
        setLoading(true);
        const ydata = [
          {
            funcionarioId: funcId,
            datainicio: fDataInicio,
            datafim: fDataFim,
            tiposFaltasId: ausencia.tipoFaltaId,
            observacoes: ausencia.observacoes,
          },
        ];

        dispatch(AdicionarAusenciasFuncionarios(ydata, show))
          .then(() => handleClose('adicionar', e))
          .then(() => fetchDiasMarcados(funcId))
          .then(() => fetchDiasDisponiveis(funcId, year))
          .then(() => fetchFuncionarioAusencias());

        // .then(() => handleOpenAlert('add'));
      }
    }
  };

  useEffect(() => {
    if (faltaSelecionada && faltaSelecionada.id == 3) {
      setAusencia({ ...ausencia, tipoFaltaId: faltaSelecionada && faltaSelecionada.id });
      setTitulo('Férias');
    } else {
      setTitulo('Ausência');
    }
  }, [faltaSelecionada]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 id="transition-modal-title" className="text-underline-title-dark">
                Adicionar {titulo}
              </h3>
              <p id="transition-modal-description">Pode adicionar aqui um tipo de falta</p>
            </div>

            <Container style={{ padding: '0' }}>
              <Row>
                <Col md={12} sm={12}>
                  <Box sx={{ mb: 2 }}>
                    <b>Tipo de Falta</b>
                    <Autocomplete
                      id="tipoFaltaId"
                      name="tipoFaltaId"
                      noOptionsText="Sem dados"
                      onChange={(e, value) => handleChangeFalta(e, value)}
                      clearText="Limpar"
                      openText="Abrir"
                      closeText="Fechar"
                      disabled={disableFaltou}
                      value={faltaSelecionada}
                      options={tiposfaltas || [{ id: 0, descricao: '' }]}
                      getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                      filterOptions={(options, state) => {
                        return options.filter(
                          (option) =>
                            option.id > 0 &&
                            `${option.descricao}`
                              .toLowerCase()
                              .includes(state.inputValue.toLowerCase()),
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        // if (!value.id) return true;

                        return value.id === option.id;
                      }}
                      autoComplete
                      style={{ width: '100%' }}
                      includeInputInList
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Tipo de Falta"
                          placeholder="Tipo de Falta"
                          // variant="outlined"
                          size="small"
                          // required
                          {...(errorsAusencia.tipoFaltaId && {
                            error: true,
                            helperText: errorsAusencia.tipoFaltaId,
                          })}
                        />
                      )}
                    />
                  </Box>
                </Col>
              </Row>
              <DatePickerAusencias
                handleDataInicioAusenciaChange={handleDataInicioAusenciaChange}
                handleDataFimAusenciaChange={handleDataFimAusenciaChange}
                formatedDataInicio={formatedDataInicio}
                formatedDataFim={formatedDataFim}
                validationAusencia={validationAusencia}
                errorsAusencia={errorsAusencia}
              />
              <b>Observações</b>
              <TextField
                id="observacoes"
                name="observacoes"
                label="Observações"
                color="primary"
                onChange={handleChangeObservacoes}
                fullWidth
                autoComplete="off"
                style={{ marginBottom: 8 }}
              />
            </Container>
            <br />
            <br />
            <div className={classes.modalBtns}>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading || faltaSelecionada == null || faltaSelecionada.id == 0}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>

              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('add', e)}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
