import Instance from '../../../utils/Axios';

const TodosOsFucionarios = () => async (dispatch) => {
  const stateToken = localStorage.token;

  await Instance()
    .get('/recursos/todosfuncionarios')
    .then(({ data }) => {
      if (stateToken && dispatch) {
        dispatch({ type: 'GET_TODOS_FUNCIONARIOS', payload: data.yData });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default TodosOsFucionarios;
