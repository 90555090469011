import { dashboardInitialState } from '../InitialState/dashboard';

const dashboardReducer = (state = dashboardInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_INFO_GRAFICOS':
      return {
        ...state,
        graficos: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
