import Instance from '../../../utils/Axios';

// New Actions for the app

const GetTipoFalta = (id) => async (dispatch) => {
  await Instance()
    .get(`/tipofaltas/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'GET_TIPOFALTA', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTipoFalta;
