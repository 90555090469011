import Instance from '../../../utils/Axios';

const RemoverTipoFalta = (id, show) => async (dispatch) => {
  await Instance()
    .delete(`/tipofaltas/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_TIPOFALTA', payload: data });
      show('success', 'Tipo de falta removido com sucesso!');
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverTipoFalta;
