/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import Logo from '../../../../../../shared/img/logo/logo-jaf.png';

import { Robfont } from '../../Roboto-normal';
import { RobBoldfont } from '../../Roboto-bold';

const PDFGenerator = (ydata, ano, mes, data, RelatorioData) => {
  let mesAnterior = mes - 1;
  const totalPagesExp = '{total_pages_count_string}';

  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  const Dados =
    RelatorioData &&
    RelatorioData.map((dataRegisto) => {
      if (
        dataRegisto &&
        dataRegisto.dataUltimoRegisto &&
        dataRegisto.dataUltimoRegisto.length > 0
      ) {
        // dataRegisto.dataUltimoRegisto = format(
        //   new Date(dataRegisto && dataRegisto.dataUltimoRegisto),
        //   'dd-MM-yyyy',
        // );
        return {
          ...dataRegisto,
          dataUltimoRegisto: format(
            new Date(dataRegisto && dataRegisto.dataUltimoRegisto),
            'dd-MM-yyyy',
          ),
        };
      }

      return { ...dataRegisto };
    });

  let y = [];
  y.push(Dados);

  y = y.flat();

  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF('vertical', 'mm', 'a4');
  const registosPorPagina = 15;
  let pageCount = Math.round(y.length / registosPorPagina);
  if (pageCount === 0) {
    pageCount = 1;
  }
  doc.setFont('Roboto-Regular');

  const headers = [
    {
      dataKey: 'nome2',
      header: 'Nome',
    },
    {
      dataKey: 'obraDescricao',
      header: 'Obra',
    },
    {
      dataKey: 'dataUltimoRegisto',
      header: 'Último Registo',
    },
  ];

  const headerContent = () => {
    doc.addImage(Logo, 'PNG', 0, 0, 42, 25);

    doc.setDrawColor(0);
    doc.setFillColor(235, 235, 235);

    // Titulo do documento
    doc.setFontSize(9);

    doc
      .text('Mapa Geral de Projeto', 105, 25, { align: 'center' })
      .setFont('Roboto-Regular', 'normal');
    // doc.text('Mapa de Horas ', 140, 25, { align: 'center' }).setFont('Roboto-Bold', 'normal');

    doc.setFontSize(8);
    // doc.text(222, 13, `Ano:`).setFont('Roboto-Bold', 'normal');
    doc.text(134, 13, `Ano:`).setFont('Roboto-Bold', 'normal');
    doc.text(140.5, 13, `${ano}`).setFont('Roboto-Regular', 'normal');
    doc.text(152, 13, `Mês:`).setFont('Roboto-Bold', 'normal');
    doc.text(159, 13, `${mes}`).setFont('Roboto-Regular', 'normal');
    doc.text(164, 13, `Data de Impressão:`).setFont('Roboto-Bold', 'normal');
    doc.text(189, 13, `${data}`).setFont('Roboto-Regular', 'normal');
  };

  doc.autoTable({
    columns: headers,
    body: y,
    startX: -10,
    startY: 35,
    theme: 'grid',
    margin: { left: 5, right: 5, top: 35 },

    // eslint-disable-next-line no-unused-vars
    addPageContent: (dataPage) => {
      headerContent();
      let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
      const totalPag = doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        footerStr = `Página ${totalPag} de ${totalPagesExp}`;
      }
      doc.setFontSize(8);
      doc.text(footerStr, 235, doc.internal.pageSize.height - 10, {
        align: 'right',
      });
    },
    headStyles: {
      fillColor: [230, 230, 230],
      lineWidth: 0.15,
      textColor: [80, 80, 80],
      font: 'Roboto-Bold',
      fontSize: 7,
    },
    styles: {
      fontSize: 6,
      margins: 0,
      // padding: 1,
      printHeaders: true,
      lineWidth: 0.15,
      font: 'Roboto-Regular',
      lineColor: [200, 200, 200],
    },
  });

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save('Vista_Geral_De_Projeto.pdf');
  doc.autoPrint();
};

export default PDFGenerator;
