import Instance from '../../../../../utils/Axios';

const GetHorasByDateInterval = (dataInicio, dataFim) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get(`/obras/registohorasescritorio/totalhoras/${dataInicio}/${dataFim}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_REGISTO_ESCRITORIO_HORAS_PERIODO', payload: data });
        }
      })
      .catch((err) => {
        console.log(err, err.response, err.request);
        if (!err.response) {
          console.log('Problema de conexão.');
          //   show('danger', `Problema de conexão com o servidor.`);
        } else if (err.response) {
          if (err.response.status === 500) {
            // show('danger', `${err.response.data.message}`);
            console.log('5xx - Erro do servidor.');
          } else {
            console.log('4xx - Problema de conexão.');
            // show('danger', `Problema de conexão com o servidor.`);
          }
        }
      });
  }
};

export default GetHorasByDateInterval;
