import Instance from '../../../../utils/Axios';

const EditarObservacaoFuncionario = (ydata, obserId, funcId, show) => async (dispatch) => {
  await Instance()
    .put(`/recursos/observacoes/${obserId}/${funcId}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_FUNCIONARIO_OBSERVACAO', payload: data });
      show('success', 'A observação selecionada, foi editada');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarObservacaoFuncionario;
