/* eslint-disable no-param-reassign */
import React from 'react';
import './tabs.css';
import { useSelector, useDispatch } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';

// ACTIONS

const TabPermissoes = () => {
  const dispatch = useDispatch();
  const permissoesStore = useSelector((state) => state.users.permissoes);

  const handleChange = (permissao, grupo) => {
    // seleciona o grupo
    const grupoSelecionado = permissoesStore.findIndex((g) => g.grupo == grupo.grupo);
    // seleciona a permissao
    const permisaoSelecionada =
      grupoSelecionado >= 0 &&
      permissoesStore[grupoSelecionado].lista.findIndex((p) => p.id == permissao.id);
    // muda o redux
    permissoesStore[grupoSelecionado].lista[permisaoSelecionada].permissao =
      !permissoesStore[grupoSelecionado].lista[permisaoSelecionada].permissao;
    dispatch({
      type: 'GET_PERMISSOES',
      payload: [...permissoesStore],
    });
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', height: '35vh' }}>
        {permissoesStore &&
          permissoesStore.map((permissao) => (
            <div className="permissoes-lista">
              <p>{permissao.grupo}</p>

              <div className="permissoes-lista-items">
                {permissao &&
                  permissao.lista.map((item) => (
                    <>
                      <div className="permissao-checkbox-tab">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.permissao}
                              onChange={(e) => handleChange(item, permissao, e)}
                              name={item.descricao}
                            />
                          }
                          label={item.descricao}
                        />
                      </div>
                    </>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default TabPermissoes;
