/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField, Autocomplete } from '@mui/material';
import CloseIcon from 'mdi-react/CloseIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import { FiCheck, FiX } from 'react-icons/fi';
// Redux
import { useDispatch, useSelector, connect } from 'react-redux';
// Actions
import NotificationSystem from 'rc-notification';

// Notification
import { ThemeProps } from '../../../../../../shared/prop-types/ReducerProps';
import { FullWideNotification } from '../../../../../../shared/components/Notification';

import RecursosDefinidos from '../../../../../../redux/actions/ObrasActions/RecursosDefinidosAction';
import AssociarRecursos from '../../../../../../redux/actions/RecursosActions/associarRecursosAction';

let notificationTC = null;

const NovoRecurso = ({
  id,
  infoObra,
  theme,
  validateOnChange = true,
  fetchInfoObra,
  classes,
  width,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    encarregado: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
    encarregadoAux: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
    chefeDepartamento: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
    chefeDepartamentoAux: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
    funcionarios: [],
  });
  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    NotificationSystem.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //
  // ---------------- Validation -------------------- //
  const [errors, setErrors] = useState({});

  const validation = (fieldValues = data) => {
    const temp = { ...errors };
    // if ('encarregado' in fieldValues)
    //   temp.encarregado =
    //     fieldValues.encarregado == null ||
    //     fieldValues.encarregado == '' ||
    //     fieldValues.encarregado.id == '0' ||
    //     Object.keys(fieldValues.encarregado).length == 0
    //       ? 'Campo Obrigatório'
    //       : '';
    // if ('encarregadoAux' in fieldValues)
    //   temp.encarregadoAux =
    //     fieldValues.encarregadoAux == null ||
    //     fieldValues.encarregadoAux == '' ||
    //     fieldValues.encarregadoAux.id == '0' ||
    //     Object.keys(fieldValues.encarregadoAux).length == 0
    //       ? 'Campo Obrigatório'
    //       : '';

    // if ('chefeDepartamento' in fieldValues)
    //   temp.chefeDepartamento =
    //     fieldValues.chefeDepartamento == null ||
    //     fieldValues.chefeDepartamento == '' ||
    //     fieldValues.chefeDepartamento.id == '0' ||
    //     Object.keys(fieldValues.chefeDepartamento).length == 0
    //       ? 'Campo Obrigatório'
    //       : '';
    // if ('chefeDepartamentoAux' in fieldValues)
    //   temp.chefeDepartamentoAux =
    //     fieldValues.chefeDepartamentoAux == null ||
    //     fieldValues.chefeDepartamentoAux == '' ||
    //     fieldValues.chefeDepartamentoAux.id == '0' ||
    //     Object.keys(fieldValues.chefeDepartamentoAux).length == 0
    //       ? 'Campo Obrigatório'
    //       : '';

    // if ('funcionarios' in fieldValues)
    //   temp.funcionarios = fieldValues.funcionarios.length != 0 ? '' : 'Campo Obrigatório';

    // setErrors({
    //   ...temp,
    // });

    if (fieldValues == data) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // ----------------------------------------------- //

  const todosOsEncarregados = useSelector((state) => state.recursos.encarregados);
  const funcionariosAssociados = useSelector((state) => state.obras.recursosDefinidos);
  const todosOsFuncionarios = useSelector((state) => state.recursos.funcionarios);

  const [loading, setLoading] = useState(false);

  const selectData = () => {
    let EncarregadoAuxAssociado = {};
    let EncarregadoAssociado = {};
    let chefeAssociado = {};
    let chefeAuxAssociado = {};
    let listObraMaoObra = [];
    if (todosOsEncarregados.length > 0 && infoObra) {
      if (infoObra.encarregado) {
        EncarregadoAssociado = todosOsEncarregados.find((enco) => enco.id === infoObra.encarregado);
      }
      if (infoObra.encarregadoAux) {
        EncarregadoAuxAssociado = todosOsEncarregados.find(
          (enco) => enco.id === infoObra.encarregadoAux,
        );
      }
      if (infoObra.chefedepartamento) {
        chefeAssociado = todosOsEncarregados.find(
          (chefeDep) => chefeDep.id === infoObra.chefedepartamento,
        );
      }
      if (infoObra.chefedepartamentoAux) {
        chefeAuxAssociado = todosOsEncarregados.find(
          (chefeDep) => chefeDep.id === infoObra.chefedepartamentoAux,
        );
      }
    }

    if (todosOsFuncionarios.length > 0 && funcionariosAssociados.length > 0) {
      listObraMaoObra = funcionariosAssociados.map((objectKey) =>
        todosOsFuncionarios.filter((x) => x.id === objectKey.recursos_id),
      );
      listObraMaoObra = listObraMaoObra.flat();
    }

    if (EncarregadoAssociado && Object.keys(EncarregadoAssociado).length === 0) {
      EncarregadoAssociado = { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 };
    }
    if (EncarregadoAuxAssociado && Object.keys(EncarregadoAuxAssociado).length === 0) {
      EncarregadoAuxAssociado = { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 };
    }
    if (chefeAuxAssociado && Object.keys(chefeAuxAssociado).length === 0) {
      chefeAuxAssociado = { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 };
    }

    setData({
      ...data,
      encarregado: EncarregadoAssociado,
      encarregadoAux: EncarregadoAuxAssociado,
      chefeDepartamento: chefeAssociado,
      chefeDepartamentoAux: chefeAuxAssociado,
      funcionarios: listObraMaoObra,
    });
  };

  const fetchRecursos = () => {
    dispatch(RecursosDefinidos(id));

    setData({
      ...data,
      encarregado: {},
      encarregadoAux: {},
      chefeDepartamento: {},
      chefeDepartamentoAux: {},
      funcionarios: [],
    });
  };

  useEffect(() => fetchRecursos(), []);
  useEffect(
    () => selectData(),
    [funcionariosAssociados, todosOsFuncionarios, todosOsEncarregados, infoObra],
  );

  // const fu = data && data.funcionarios.map((funcs) => funcs.id);
  // let oldFuncs = funcionariosAssociados.map((objectKey, index) =>
  //   todosOsFuncionarios.filter((x) => x.id === objectKey.recursos_id),
  // );
  // oldFuncs = oldFuncs.flat();

  // const old = oldFuncs.map((f) => f.id);
  const handleChangeEncs = (value) => {
    // setEncs(value);
    setData({
      ...data,
      encarregado: value,
    });
    if (validateOnChange) validation({ encarregado: value });
  };
  const handleChangeEncsAuxiliar = (e, value) => {
    setData({
      ...data,
      encarregadoAux: value,
    });
    if (validateOnChange) validation({ encarregadoAux: value });
  };
  const handleChangeChefeDepartamento = (e, value) => {
    setData({
      ...data,
      chefeDepartamento: value,
    });
    if (validateOnChange) validation({ chefeDepartamento: value });
  };
  const handleChangeChefeDepartamentoAuxiliar = (e, value) => {
    setData({
      ...data,
      chefeDepartamentoAux: value,
    });
    if (validateOnChange) validation({ chefeDepartamentoAux: value });
  };

  const handleChangeFuncs = (value) => {
    // setFuncs(value);

    setData({
      ...data,
      funcionarios: value,
    });
    // if (validateOnChange) validation({ funcionarios: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // const equalFuncs = JSON.stringify(fu) === JSON.stringify(old);

    if (validation() == true) {
      // if (equalFuncs === true && data.encarregado.id === infoObra.encarregado) {
      //   show('warning', 'Não foram introduzidos novos funcionários');
      // } else {
      setLoading(true);
      const listMaoObra = data.funcionarios.map((funcionarios) => funcionarios.id);
      const ydata = [
        {
          obra: id,
          encarregadoAux: data && data.encarregadoAux && data.encarregadoAux.id,
          chefedepartamentoAux: data && data.chefeDepartamentoAux && data.chefeDepartamentoAux.id,
          listMaoObra,
        },
      ];
      dispatch(AssociarRecursos(ydata, show))
        .then(() => {
          if (data.encarregado.id != infoObra.encarregado) {
            fetchInfoObra();
          }
          // if (!equalFuncs) {
          //   fetchRecursos();
          // }
        })
        .then(() => setLoading(false));
      // }
    }
  };

  const IconAdornment =
    infoObra &&
    (infoObra.encarregadoTemUtilizador == 0 || infoObra.chefedepartamentoTemUtilizador == 0)
      ? {
          startAdornment: (
            <FiX color="tomato" position="start" style={{ marginLeft: '-8px' }} size={25} />
          ),
        }
      : {
          startAdornment: (
            <FiCheck color="lightgreen" position="start" style={{ marginTop: '-8px' }} size={25} />
          ),
        };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: width < 1100 ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: width < 1100 ? '100%' : '48%',
              mb: width < 1100 ? 3 : 0,
            }}
          >
            {/* ------------------------- ENCARREGADO ---------------------------*/}
            <b>Encarregado da obra</b>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Encarregado da obra"
              value={infoObra && infoObra.encarregadoNome}
              style={{ width: '100%', float: 'left' }}
              disabled
              InputProps={IconAdornment}
            />
          </Box>
          {/* -------------------- ENCARREGADO AUXILIAR  ----------------------*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: width < 1100 ? '100%' : '48%',
              mb: width < 1100 ? 3 : 0,
            }}
          >
            <b>Encarregado auxiliar da obra</b>
            <Autocomplete
              id="combo-box-demo"
              name="encarregadoAux"
              size="small"
              noOptionsText="Sem dados"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              options={todosOsEncarregados}
              onInputChange={(event, value, reason) => {
                if (reason == 'clear') {
                  setData({
                    ...data,
                    encarregadoAux: { id: '0', tipo: 0, nome: '', nome2: '', temUtilizador: 0 },
                  });
                }
              }}
              filterOptions={(options, state) => {
                return options.filter(
                  (option) =>
                    option.id > 0 &&
                    `${option.id} - ${option.nome} - ${option.nome2}`
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase()),
                );
              }}
              getOptionLabel={
                (option) => {
                  if (option && option.id) {
                    if (option.nome && option.nome2) {
                      return `${option.id} - ${option.nome} - ${option.nome2} `;
                    }
                    if (option.nome) {
                      return `${option.id} - ${option.nome}`;
                    }
                    if (option.nome2) {
                      return `${option.id} - ${option.nome2}`;
                    }
                  }
                  return '';
                }
                // option.id ? `${option.id} - ${option.nome} - ${option.nome2}` : ''
              }
              value={data && data.encarregadoAux}
              isOptionEqualToValue={(option, value) => {
                return value && value.id === option.id;
              }}
              renderTags={(options) => {
                return options.map((option) => (
                  <>
                    {option.temUtilizador === 0 ? (
                      <>
                        <FiX color="tomato" size={25} />
                        <p>
                          {option.id} - {option.nome} - {option.nome2}
                        </p>
                      </>
                    ) : (
                      <>
                        <FiCheck color="lightgreen" size={25} style={{ marginTop: '-10px' }} />
                        <p>
                          {option.id} - {option.nome} - {option.nome2}
                        </p>
                      </>
                    )}
                  </>
                ));
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.temUtilizador === 0 ? (
                      <>
                        <FiX color="tomato" size={25} />
                        &nbsp;
                        <span>
                          {option.id} - {option.nome} - {option.nome2}
                        </span>
                      </>
                    ) : (
                      <>
                        <FiCheck color="lightgreen" size={25} style={{ marginTop: '-2px' }} />
                        &nbsp;
                        <span>
                          {option.id} - {option.nome} - {option.nome2}
                        </span>
                      </>
                    )}
                  </li>
                );
              }}
              style={{ width: '100%' }}
              onChange={(e, value) => handleChangeEncsAuxiliar(e, value)}
              renderInput={(params) => (
                <div style={{ position: 'relative' }}>
                  {params.inputProps.value && (
                    <span
                      style={{
                        position: 'absolute',
                        transform: 'translateY(50%)',
                        marginLeft: '5px',
                        marginTop: '4px',
                      }}
                    >
                      {data &&
                      data.encarregadoAux &&
                      Object.keys(data.encarregadoAux).length > 0 ? (
                        <>
                          {data && data.encarregadoAux.temUtilizador === 0 ? (
                            <>
                              <FiX color="tomato" size={25} />
                            </>
                          ) : (
                            <>
                              <FiCheck color="lightgreen" size={25} style={{ marginTop: '-8px' }} />
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Encarregado auxiliar da obra"
                    inputProps={{
                      ...params.inputProps,
                      style: { paddingLeft: '26px' },
                    }}
                    // required
                    // {...(errors.encarregadoAux && { error: true, helperText: errors.encarregadoAux })}
                  />
                </div>
              )}
            />
          </Box>
        </Box>
        {/* -------------------- CHEFE DE DEPARTAMENTO ----------------------*/}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: width < 1100 ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: width < 1100 ? '100%' : '48%',
              mb: width < 1100 ? 3 : 0,
            }}
          >
            <b style={{ marginTop: width < 1100 ? 0 : '20px' }}>Chefe de departamento</b>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Chefe de departamento"
              value={infoObra && infoObra.chefedepartamentoNome}
              style={{ width: '100%' }}
              disabled
              InputProps={IconAdornment}
            />
          </Box>
          {/* --------------- CHEFE DE DEPARTAMENTO AUXILIAR -----------------*/}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: width < 1100 ? '100%' : '48%',
              mb: width < 1100 ? 3 : 0,
            }}
          >
            <b style={{ marginTop: width < 1100 ? 0 : '20px' }}>Chefe de departamento auxiliar</b>
            <Autocomplete
              id="combo-box-demo"
              name="chefe-de-departamento-auxiliar"
              size="small"
              noOptionsText="Sem dados"
              clearText="Limpar"
              openText="Abrir"
              closeText="Fechar"
              options={todosOsEncarregados || []}
              onInputChange={(event, value, reason) => {
                if (reason == 'clear') {
                  setData({
                    ...data,
                    chefeDepartamentoAux: {
                      id: '0',
                      tipo: 0,
                      nome: '',
                      nome2: '',
                      temUtilizador: 0,
                    },
                  });
                }
              }}
              filterOptions={(options, state) => {
                return options.filter(
                  (option) =>
                    option.id > 0 &&
                    `${option.id} - ${option.nome} - ${option.nome2}`
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase()),
                );
              }}
              getOptionLabel={
                (option) => {
                  if (option && option.id) {
                    if (option.nome && option.nome2) {
                      return `${option.id} - ${option.nome} - ${option.nome2} `;
                    }
                    if (option.nome) {
                      return `${option.id} - ${option.nome}`;
                    }
                    if (option.nome2) {
                      return `${option.id} - ${option.nome2}`;
                    }
                  }
                  return '';
                }
                // option.id ? `${option.id} - ${option.nome} - ${option.nome2} ` : ''
              }
              value={data && data.chefeDepartamentoAux}
              isOptionEqualToValue={(option, value) => {
                return value && value.id === option.id;
              }}
              renderTags={(options) => {
                return options.map((option) => (
                  <>
                    {option.temUtilizador === 0 ? (
                      <>
                        <FiX color="tomato" size={25} />
                        <p>
                          {option.id} - {option.nome} - {option.nome2}
                        </p>
                      </>
                    ) : (
                      <>
                        <FiCheck color="lightgreen" size={25} style={{ marginTop: '-8px' }} />
                        <p>
                          {option.id} - {option.nome} - {option.nome2}
                        </p>
                      </>
                    )}
                  </>
                ));
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.temUtilizador === 0 ? (
                      <>
                        <FiX color="tomato" size={25} />
                        &nbsp;
                        <span>
                          {option.id} - {option.nome} - {option.nome2}
                        </span>
                      </>
                    ) : (
                      <>
                        <FiCheck color="lightgreen" size={25} style={{ marginTop: '-2px' }} />
                        &nbsp;
                        <span>
                          {option.id} - {option.nome} - {option.nome2}
                        </span>
                      </>
                    )}
                  </li>
                );
              }}
              style={{ width: '100%' }}
              onChange={(e, value) => handleChangeChefeDepartamentoAuxiliar(e, value)}
              renderInput={(params) => (
                <div style={{ position: 'relative' }}>
                  {params.inputProps.value && (
                    <span
                      style={{
                        position: 'absolute',
                        transform: 'translateY(50%)',
                        marginLeft: '5px',
                        marginTop: '4px',
                      }}
                    >
                      {data &&
                      data.chefeDepartamentoAux &&
                      Object.keys(data.chefeDepartamentoAux).length > 0 ? (
                        <>
                          {data && data.chefeDepartamentoAux.temUtilizador === 0 ? (
                            <FiX color="tomato" size={25} />
                          ) : (
                            <FiCheck
                              color="lightgreen"
                              size={25}
                              style={{ marginTop: '-8px', marginRight: '8px' }}
                            />
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Chefe de departamento auxiliar"
                    inputProps={{
                      ...params.inputProps,
                      style: { paddingLeft: '26px' },
                    }}
                    // required
                    {...(errors.chefeDepartamentoAux && {
                      error: true,
                      helperText: errors.chefeDepartamentoAux,
                    })}
                  />
                </div>
              )}
            />
          </Box>
        </Box>
        <Box sx={{ mt: width < 1100 ? 0 : 3 }}>
          <b>Funcionários</b>
          <Autocomplete
            name="funcionarios"
            size="small"
            noOptionsText="Sem dados"
            clearText="Limpar"
            openText="Abrir"
            closeText="Fechar"
            limitTags={10}
            multiple
            id="Funcionarios"
            onChange={(e, value) => handleChangeFuncs(value)}
            options={todosOsFuncionarios}
            getOptionLabel={(option) =>
              `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
            }
            getOptionSelected={(option, value) => value.id === option.id}
            filterSelectedOptions
            value={data && data.funcionarios}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Adicionar Funcionários"
                // {...(errors.funcionarios && { error: true, helperText: errors.funcionarios })}
              />
            )}
          />
        </Box>
        <br />

        <br />

        <Button
          onClick={(e) => {
            onSubmit(e);
          }}
          className={loading ? classes.btnGuardarLoading : classes.btnGuardar}
          disabled={!!loading}
          size="small"
          variant="contained"
          style={{ float: 'right' }}
        >
          {/* {!loading && <IoMdSave style={{ color: 'white', marginRight: '10px' }} />} */}
          {loading && (
            <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
          )}
          Guardar
        </Button>
      </form>
    </>
  );
};

NovoRecurso.propTypes = {
  theme: ThemeProps.isRequired,
};

export default connect((state) => ({
  theme: state.theme,
}))(NovoRecurso);
