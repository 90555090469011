import Instance from '../../../utils/Axios';

const TodasAsObrasRelatorio = () => async (dispatch) => {
  await Instance()
    .get('/relatorios/obras')
    .then(({ data }) => {
      dispatch({ type: 'GET_TODAS_OBRAS_RELATORIO', payload: data.yData });
    })
    .catch((err) => console.log(err));
};

export default TodasAsObrasRelatorio;
