import Instance from '../../../utils/Axios';

const User = (id, navigate) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get(`/users/${id}`)
      .then(({ data }) => {
        if (stateToken) {
          console.log('action UserAction', data);
          if (data && data.yData.length == 0) {
            navigate('/app/administracao/utilizadores');
          } else {
            dispatch({ type: 'GET_USER', payload: data.yData[0] });
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }
};

export default User;
