import Instance from '../../../utils/Axios';

const RemoverTipoAtraso = (id, show) => async (dispatch) => {
  await Instance()
    .delete(`/tipoatrasos/${id}`)
    .then(({ data }) => {
      dispatch({ type: 'DELETE_TIPOATRASO', payload: data });
      show('success', 'Tipo de atraso removido com sucesso!');
    })
    .catch(({ err }) => console.log(err));
};

export default RemoverTipoAtraso;
