/* eslint-disable object-shorthand */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';

import './tiposperiodos.css';
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';

import { Button, Box, TextField, InputAdornment, IconButton } from '@mui/material';

import MUIDataTable from 'mui-datatables';

// COMPONENTS

import ModalEdit from './components/modalEdit';
import ModalDelete from './components/modalDelete';
import ModalAdicionar from './components/modalAdicionar';
import BreadTiposPeriodos from '../../../App/Breadcrumbs/Administracao/TiposPeriodos/BreadTiposPeriodos';
import FooterLinks from '../../../Account/Footer';

// ACTIONS
import GetTiposPeriodos from '../../../../redux/actions/TiposPeriodosAction/tiposPeriodosAction';
import GetTipoPeriodo from '../../../../redux/actions/TiposPeriodosAction/getTipoPeriodoAction';

// NOTIFICATION
import NotificationSystem from 'rc-notification';
import { FullWideNotification } from '../../../../shared/components/Notification';
import { FiEdit, FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';

let notificationTC = null;

const TiposPeriodos = (theme) => {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState({
    descricao: '',
  });
  const [tipoPeriodoId, setTipoPeriodoId] = useState();
  const [tipoPeriodoNome, setTipoPeriodoNome] = useState();
  const [errorsTipoPeriodo, setErrorsTipoPeriodo] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const resetTipoPeriodo = () => {
    setTipoPeriodo({
      descricao: '',
    });
  };

  const tiposPeriodos = useSelector((state) => state.tiposPeriodos.tiposPeriodos);

  const fetchTiposPeriodos = () => {
    dispatch(GetTiposPeriodos());
    dispatch({ type: 'CLEAR_DATA_TIPOPERIODO' });
  };

  const tipoPeriodoInfo = useSelector((state) => state.tiposPeriodos.tipoPeriodo);

  const fetchTipoPeriodo = (id, e) => {
    e.preventDefault();
    return dispatch(GetTipoPeriodo(id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchTiposPeriodos(), []);

  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setErrorsTipoPeriodo({});
    setLoading(false);
    if (btn == 'edit') {
      setTipoPeriodoId(id);
      setTipoPeriodoNome(tableMeta.rowData[0]);
      fetchTipoPeriodo(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setTipoPeriodoId(id);
      setTipoPeriodoNome(tableMeta.rowData[0]);
    } else {
      setOpenAdicionar(true);
      resetTipoPeriodo();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setErrorsTipoPeriodo({});
    if (btn == 'edit') {
      setOpenEdit(false);
      // dispatch({ type: 'CLEAR_DATA_TIPOPERIODO' });
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);
      setErrorsTipoPeriodo({});
    }
  };

  const columns = [
    {
      name: 'descricao',
      label: 'Descrição',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposperiodos">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit size={30} strokeWidth={1} color="#000" />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  // -------------------- Validacao tipos de periodos ------------------------//

  const validationTipoPeriodo = (fieldValues = tipoPeriodo) => {
    const temp = { ...errorsTipoPeriodo };
    if ('descricao' in fieldValues)
      temp.descricao = fieldValues.descricao ? '' : 'Campo Obrigatório';

    setErrorsTipoPeriodo({
      ...temp,
    });
    if (fieldValues == tipoPeriodo) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    NotificationSystem.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadTiposPeriodos />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Tipos de Períodos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <>
              <ModalAdicionar
                openAdicionar={openAdicionar}
                handleClose={handleClose}
                tipoPeriodo={tipoPeriodo}
                setTipoPeriodo={setTipoPeriodo}
                validationTipoPeriodo={validationTipoPeriodo}
                errorsTipoPeriodo={errorsTipoPeriodo}
                fetchTiposPeriodos={fetchTiposPeriodos}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              {/* <MuiThemeProvider theme={myTheme}> */}
              <ModalEdit
                openEdit={openEdit}
                handleClose={handleClose}
                tipoPeriodo={tipoPeriodo}
                setTipoPeriodo={setTipoPeriodo}
                validationTipoPeriodo={validationTipoPeriodo}
                errorsTipoPeriodo={errorsTipoPeriodo}
                fetchTiposPeriodos={fetchTiposPeriodos}
                tipoPeriodoInfo={tipoPeriodoInfo}
                tipoPeriodoId={tipoPeriodoId}
                tipoPeriodoNome={tipoPeriodoNome}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <ModalDelete
                openDelete={openDelete}
                handleClose={handleClose}
                tipoPeriodoId={tipoPeriodoId}
                fetchTiposPeriodos={fetchTiposPeriodos}
                tipoPeriodo={tipoPeriodo}
                tipoPeriodoNome={tipoPeriodoNome}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <CardBody>
                <Col md={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Box>
                      <b>Pesquisar</b>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="search"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <FiSearch />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        sx={{ border: '1px solid #000', minWidth: '45px', mt: 2.5 }}
                        onClick={(e) => handleOpen('add', e)}
                      >
                        <FiPlus size={25} strokeWidth={1.5} />
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                    <h4
                      style={{
                        width: '100%',
                      }}
                      className="text-underline-title"
                    >
                      Tipos de Períodos
                    </h4>
                  </Box>

                  <MUIDataTable
                    description="Nada para mostrar"
                    data={tiposPeriodos}
                    columns={columns}
                    options={options}
                  />
                </Col>
              </CardBody>
              {/* </MuiThemeProvider> */}
            </>
          </Col>
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default TiposPeriodos;
