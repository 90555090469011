import Instance from '../../../utils/Axios';

const TodosUsers = () => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get('/users')
      .then(({ data }) => {
        if (stateToken && dispatch) {
          dispatch({ type: 'GET_TODOS_USERS', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default TodosUsers;
