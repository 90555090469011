/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import './validacaoCD.css';
// PACKAGES
import { startOfMonth, format, getDay, subDays } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, CardBody, Col, Row, Input } from 'reactstrap';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
  Box,
  InputAdornment,
  IconButton,
  useTheme,
  TextField,
  Typography,
  Collapse,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  FiSearch,
  FiCheckSquare,
  FiXSquare,
  FiInfo,
  FiCheck,
  FiTrash2,
  FiTrash,
  FiEdit,
  FiEdit2,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MUIDataTable from 'mui-datatables';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';

// Breadcrumbs
import BreadValidacaoCD from '../../../App/Breadcrumbs/ValidacaoCD/BreadValidacaoCD';

// COMPONENTS
import FormDataCD from './components/formDatasCD';
import FooterLinks from '../../../Account/Footer';
import ModalDelete from './components/modalDelete';
import ModalValidate from './components/modalValidate';

// ACTIONS
import GetObrasValidacao from '../../../../redux/actions/ValidacaoActions/getObrasValidacao';
import GetFuncionariosValidacao from '../../../../redux/actions/ValidacaoActions/getFuncionariosValidacao';
import EnviarValidacaoIndividualCD from '../../../../redux/actions/ValidacaoActions/validacaoCD/enviarValidacaoIndividualCD';
import GetValidacaoIndividualCD from '../../../../redux/actions/ValidacaoActions/validacaoCD/getValidacaoIndividualCD';

// eslint-disable-next-line max-len
import GetValidacaoChefeDepartamento from '../../../../redux/actions/ValidacaoActions/validacaoCD/validacaoChefeDepartamento';
import EnviarValidacaoCD from '../../../../redux/actions/ValidacaoActions/validacaoCD/enviarValidacoesCD';
import DadosFormulárioValidacao from '../../../../redux/actions/ValidacaoActions/filtroValidacoes/dadosFormularioAction';
import SideDrawer from './components/drawer';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.grey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.grey,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnValidar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnObservacoes: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnObservacoesPreenchido: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },
}));

const customStyles = (theme) => ({
  BusinessAnalystRow: {
    // '& td': { backgroundColor: '#4287f5' },
    '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  LinhaValidada: {
    '& td': { backgroundColor: 'rgb(255, 153, 145, 0.1)' },
    // '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  GreyLine: {
    '& td': { backgroundColor: theme.palette.grey[200] },
  },
  NameCell: {
    fontWeight: 900,
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ValidacaoCD = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [dataInicio, setDataInicio] = useState();
  const [dataFim, setDataFim] = useState();

  const [historico, setHistorico] = React.useState({
    historico: false,
  });
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [validarBtn, setValidarBtn] = useState(false);

  const dispatch = useDispatch();

  const obrasValidacao = useSelector((state) => state.validacao.obrasValidacao);
  const obraSelected = useSelector((state) => state.filters.obraValidacao);
  const funcionariosValidacao = useSelector((state) => state.validacao.funcionariosValidacao);
  const funcionarioSelected = useSelector((state) => state.filters.funcionarioValidacao);
  const [obra, setObra] = useState(obraSelected);
  const [funcionario, setFuncionario] = useState(funcionarioSelected);
  const dados = useSelector((state) => state.validacao.validacaoCD);

  const handleChangeHoras = (horas, data) => {
    // console.log(data[7], horas, data[9], data[13]);
    const yData = [
      {
        id: data[7],
        qtd: horas,
        validadoCD: data[9],
        observacoesCD: data[13],
      },
    ];

    dispatch(EnviarValidacaoIndividualCD(yData));
  };

  // Dados Formulário
  const dadosFormValidacao = useSelector((state) => state.validacao.dadosFormularioValidacao);

  const fetchObras = () => {
    dispatch(GetObrasValidacao());
  };
  const fetchFuncionarios = () => {
    dispatch(GetFuncionariosValidacao());
  };
  const clearTable = () => {
    dispatch({ type: 'GET_VALIDACAOCHEFEDEPARTAMENTO', payload: [] });
    setLinhasSelecionadas([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchObras(), []);
  useEffect(() => fetchFuncionarios(), []);
  useEffect(() => clearTable(), []);
  // Define a data a primeira vez que se entrar na página

  const user = useSelector((state) => state.user.role);

  useEffect(() => {
    if (dadosFormValidacao && (dadosFormValidacao.dataInicio || dadosFormValidacao.dataFim)) {
      // if (user != 6) {
      // console.log('Datas da store');
      setDataInicio(dadosFormValidacao.dataInicio);
      setDataFim(dadosFormValidacao.dataFim);
      // }
    } else if (user == 6) {
      console.log('é um encarregado');

      let date;
      const today = new Date();
      if (getDay(today) == 6) {
        date = subDays(today, 3);
      } else if (getDay(today) == 0) {
        date = subDays(today, 4);
      } else if (getDay(today) == 1) {
        date = subDays(today, 4);
      } else if (getDay(today) == 2) {
        date = subDays(today, 4);
      } else {
        date = subDays(today, 2);
      }

      setDataInicio(date);
      setDataFim(new Date());
    } else {
      // console.log('Novas datas');
      setDataInicio(startOfMonth(new Date()));
      setDataFim(new Date());
      dispatch(DadosFormulárioValidacao(startOfMonth(new Date()), new Date()));
    }
  }, []);

  // Define as datas no state do redux
  // useEffect(() => dispatch(DadosFormulárioValidacao(dataInicio, dataFim)), []);

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch(DadosFormulárioValidacao(date, dataFim));
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch(DadosFormulárioValidacao(dataInicio, date));
  };

  const handleDataInicioChangeCD = (date) => {
    setDataInicio(date);
  };
  const handleDataFimChangeCD = (date) => {
    setDataFim(date);
  };

  const handleChangeObra = (e, value) => {
    e.preventDefault();

    setObra(value);
  };
  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    setFuncionario(value);
  };
  const handleChangeHistorico = (event) => {
    setHistorico({ ...historico, [event.target.name]: event.target.checked });
  };

  // console.log(dados);

  // ------------------------------------------------------------------------//

  // ------------------ EXPANDABLE ROW ------------------ //
  const obraId = obra && obra.id;
  const funcionarioId = funcionario && funcionario.id;
  const historicoBool = () => {
    return historico.historico == true ? 1 : 0;
  };
  const infoValIndiv = useSelector((state) => state.validacao.validacaoIndividualCD);

  const handleChangeObservacoes = (yData) => {
    const data = [];
    data.push(yData);
    const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
    const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');

    dispatch(EnviarValidacaoIndividualCD(data)).then(() =>
      dispatch(
        GetValidacaoChefeDepartamento(
          selectedDataInicio,
          selectedDataFim,
          historicoBool(),
          obraId,
          funcionarioId,
        ),
      ),
    );
  };
  // ---------------------------------------------------- //

  // ----------------------------- MODAL OPEN -------------------------------//
  // eslint-disable-next-line no-unused-vars
  const [openObservacoes, setOpenObservacoes] = useState(false);
  // const [obs, setObs] = useState({
  //   tableIndex: '',
  //   observacoes: '',
  //   id: '',
  // });

  // const handleOpen = (btn, e, id, tableMeta) => {
  //   e.preventDefault();

  //   if (btn == 'observacoes') {
  //     setObs({
  //       ...obs,
  //       tableIndex: tableMeta.rowIndex,
  //       observacoes: tableMeta.rowData[8],
  //       id,
  //     });
  //     setOpenObservacoes(true);
  //   }
  // };

  // const handleClose = (btn, e) => {
  //   e.preventDefault();

  //   if (btn == 'observacoes') {
  //     setOpenObservacoes(false);
  //   }
  // };
  // ------------------------------------------------------------------------//

  // ------------------ MODAL DELETE -------------------- //

  const [openDelete, setOpenDelete] = useState(false);
  const [vid, setVid] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setVid(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
    setVid(null);
  };

  // ---------------------------------------------------- //

  // ------------------ MODAL VALIDATE -------------------- //

  const [openValidate, setOpenValidate] = useState(false);

  const handleOpenValidate = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenValidate(true);
    setVid(id);
  };

  const handleCloseValidate = (e) => {
    e.preventDefault();
    setOpenValidate(false);
    setVid(null);
  };

  // ---------------------------------------------------- //

  const columns = [
    {
      name: 'descricaoObra',
      label: 'Obra',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              {`${tableMeta.rowData[8]} - ${tableMeta.rowData[0]}`}
            </div>
          );
        },
      },
    },
    {
      name: 'recursos_nome2',
      label: 'Funcionário',
    },

    {
      name: 'data',
      label: 'Data',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          const dataFormat = format(new Date(tableMeta.rowData[2]), 'dd/MM/yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              <p>{dataFormat}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'qtd',
      label: 'Horas',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TextField
              type="number"
              size="small"
              variant="outlined"
              inputProps={{ min: 0, style: { textAlign: 'center', marginTop: 13 } }}
              name="qtd"
              value={value}
              onChange={(e) => {
                updateValue(e.target.value);
                handleChangeHoras(e.target.value, tableMeta.rowData);
              }}
              color="primary"
              sx={{
                width: '85px',
                mt: -1.5,
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          );
        },
      },
    },
    {
      name: 'faltou',
      label: 'Presença',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              {tableMeta.rowData[4] == 0 ? (
                <div
                  style={{
                    width: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiCheckSquare size={30} strokeWidth={1} color="#43B935" />
                  {/* <span style={{ marginLeft: '6px' }}>
                    <b>Sim</b>
                  </span> */}
                </div>
              ) : (
                <div
                  style={{
                    width: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiXSquare size={30} strokeWidth={1} color="#EE4231" />
                  {/* <span style={{ marginLeft: '6px' }}>
                    <b>Não</b>
                  </span> */}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'descricaoTipoPeriodo',
      label: 'Periodo',
    },
    {
      name: 'descricaoTipoAtraso',
      label: 'Motivo Atraso',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'obras_id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    // {
    //   name: '',
    //   label: 'Informações',
    //   options: {
    //     filter: true,
    //     // sort: false,
    //     empty: true,

    //     // eslint-disable-next-line no-unused-vars
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div
    //           className="table-btns-categorias"
    //           style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
    //         >
    //           <Link to={`/app/validacao/chefedepartamento/${tableMeta.rowData[7]}`}>
    //             <Button>
    //               <FiInfo size={30} color="#000" strokeWidth={1} />
    //             </Button>
    //           </Link>
    //         </div>
    //       );
    //     },
    //   },
    // },

    {
      name: 'validadoCD',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'validadoRH',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'validadoAD',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'center',
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[7];

          return (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  className="table-btns-categorias"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* <Link to={`/app/validacao/chefedepartamento/${tableMeta.rowData[7]}`}>
                    <LightTooltip title="Editar" placement="top">
                      <IconButton>
                        <FiEdit size={30} strokeWidth={1} color="#000" />
                      </IconButton>
                    </LightTooltip>
                  </Link> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LightTooltip title="Validar" placement="top">
                    <IconButton
                      onClick={(e) => {
                        setLinhasSelecionadas([{ id: o }]);
                        handleOpenValidate(e, o);
                      }}
                    >
                      <FiCheck size={30} strokeWidth={1} color="#000" />
                    </IconButton>
                  </LightTooltip>
                  {/* <span style={{ marginLeft: '6px' }}>
                    <b>Não</b>
                  </span> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LightTooltip title="Eliminar" placement="top">
                    <IconButton onClick={(e) => handleOpen(e, o)}>
                      <FiTrash2 size={30} strokeWidth={1} color="#000" />
                    </IconButton>
                  </LightTooltip>
                  {/* <span style={{ marginLeft: '6px' }}>
                    <b>Não</b>
                  </span> */}
                </div>
              </div>
            </>
          );
        },
      },
    },
    {
      name: 'observacoesCD',
      label: ' ',
      options: {
        display: false,
      },
    },
  ];

  const tablePage = useSelector((state) => state.global.valCDTablePage);
  const searchTextValue = useSelector((state) => state.global.valCDTableSearch);
  const [searchText, setSearchText] = useState(searchTextValue);

  const options = {
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'multiple',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    page: tablePage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        dispatch({ type: 'VAL_CD_TABLE_PAGINATION', payload: tableState.page });
      }
      if (action === 'propsUpdate') {
        dispatch({
          type: 'VAL_CD_TABLE_SEARCH',
          payload: tableState.searchText,
        });
      }
    },
    // customRowRender: (data) => {
    //   console.log(data);
    //   return (
    //     <>
    //       <TableRow onClick={toggleExpander}>
    //         <TableCell>{data[0]}</TableCell>
    //         <TableCell>{data[1]}</TableCell>
    //         <TableCell>{data[2]}</TableCell>
    //         <TableCell>{data[3]}</TableCell>
    //         <TableCell>{data[4]}</TableCell>
    //         <TableCell>{data[5]}</TableCell>
    //         <TableCell>{data[6]}</TableCell>
    //         <TableCell>{data[12]}</TableCell>
    //       </TableRow>
    //       {expanded && (
    //         <TableRow>
    //           <Box sx={{ display: 'flex', m: 1, justifyContent: 'flex-start' }}>
    //             <Box sx={{ display: 'flex', width: '100%' }}>
    //               <b>Obs. Chefe depart.</b>
    //               <TextField
    //                 name="observacoes"
    //                 variant="outlined"
    //                 sx={{ width: '100%', mt: -1 }}
    //                 size="small"
    //                 inputProps={{
    //                   style: { marginTop: 13 },
    //                   onBlur: (e) => {
    //                     handleChangeObservacoes(e.target.value, data);
    //                   },
    //                 }}
    //                 defaultValue={data[13]}
    //                 onBlur={handleChangeObservacoes}
    //                 disabled={infoValIndiv.validadoRH || infoValIndiv.validadoAD}
    //               />
    //             </Box>
    //           </Box>
    //         </TableRow>
    //       )}
    //     </>
    //   );
    // },
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;

      let yData = {
        id: rowData[7],
        qtd: rowData[3].props.value,
        validadoCD: rowData[9],
        observacoesCD: rowData[13],
      };

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Box sx={{ display: 'flex', m: 1, justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', width: '30%' }}>
                <b>Obs. Chefe depart.</b>
                <TextField
                  name="observacoes"
                  variant="outlined"
                  sx={{ width: '100%', borderCollapse: 'collapse' }}
                  size="small"
                  inputProps={{
                    onBlur: (e) => {
                      yData = {
                        ...yData,
                        observacoesCD: e.target.value,
                      };
                    },
                  }}
                  defaultValue={rowData[13]}
                  // onBlur={handleChangeObservacoes}
                  disabled={infoValIndiv.validadoRH || infoValIndiv.validadoAD}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
                <Button
                  className={loading ? classes.btnGuardarLoading : classes.btnGuardar}
                  onClick={() => handleChangeObservacoes(yData)}
                  size="small"
                  variant="contained"
                  style={{ float: 'right' }}
                  disabled={!!loading}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                  )}
                  Guardar
                </Button>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    },
    setRowProps: (row) => {
      const proprities = props;
      return {
        className: clsx({
          [proprities.classes.LinhaValidada]: row[10] == 1 || row[11] == 1 || row[12] == 1,
          [proprities.classes.BusinessAnalystRow]: row[1].length > 0,
        }),
      };
    },
    // selectableRowsOnClick: true,
    isRowSelectable: (dataIndex) => {
      // prevents selection of row with title "Attorney
      // console.log(dataIndex);

      return (
        dados[dataIndex].validadoCD == 0 &&
        dados[dataIndex].validadoRH == 0 &&
        dados[dataIndex].validadoAD == 0
      );
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected.length >= 0) {
        // const aux = rowsSelected.map((index) => dados[index].id);
        const linhaInfo = rowsSelected.map((index) => dados[index]);

        setLinhasSelecionadas(linhaInfo);
      }
    },

    // customToolbarSelect: () => <div style={{ padding: '24px' }} />,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
      },
    },
  };

  const handleConsultar = () => {
    const selectedDataInicio = dataInicio && format(new Date(dataInicio), 'yyyy-MM-dd');
    const selectedDataFim = dataFim && format(new Date(dataFim), 'yyyy-MM-dd');
    dispatch(
      GetValidacaoChefeDepartamento(
        selectedDataInicio,
        selectedDataFim,
        historicoBool(),
        obraId,
        funcionarioId,
      ),
    );
  };
  const handleSubmit = () => {
    // e.preventDefault();

    let yData = linhasSelecionadas.map((info) => {
      const infoLinha = {
        id: info.id,
        // observacoesCD: info.observacoesCD,
      };
      return Object.values(infoLinha);
    });

    yData = yData.flat();

    console.log(yData);

    dispatch(EnviarValidacaoCD(yData, setValidarBtn)).then(() => setValidarBtn(true));
  };

  // Faz o fetch dos dados quando abre a página
  useEffect(() => setValidarBtn(true), []);

  useEffect(() => {
    if (validarBtn) {
      const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
      const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
      dispatch(
        GetValidacaoChefeDepartamento(
          selectedDataInicio,
          selectedDataFim,
          historicoBool(),
          obraId,
          funcionarioId,
        ),
      );
      clearTable();
      setValidarBtn(false);
    }
  }, [validarBtn]);

  return (
    <>
      <Container>
        {/* <SideDrawer
          state={sideState}
          toggleDrawer={toggleDrawer}
          dimensions={dimensions}
          drawerWidth={drawerWidth}
          id={horasId}
        /> */}
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          vid={vid}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
          setValidarBtn={setValidarBtn}
        />
        <ModalValidate
          openValidate={openValidate}
          handleCloseValidate={handleCloseValidate}
          vid={vid}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
          setValidarBtn={setValidarBtn}
          linhasSelecionadas={linhasSelecionadas}
        />
        <Row>
          <Col md={12}>
            <BreadValidacaoCD />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Validação Chefe de Departamento
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Col md={12} sm={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: width < 1100 ? 'column' : 'row',
                    }}
                  >
                    {/* <Container> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                      {user == 6 ? (
                        <Box sx={{ width: '15%', mr: '2%' }}>
                          <FormDataCD
                            dataInicio={dataInicio}
                            dataFim={dataFim}
                            handleDataInicioChangeCD={handleDataInicioChangeCD}
                            handleDataFimChangeCD={handleDataFimChangeCD}
                          />
                        </Box>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: width < 1100 ? '100%' : '30%',
                              mr: '2%',
                            }}
                          >
                            <Box
                              sx={{
                                mr: '2%',
                                mb: width < 1100 ? 2 : 0,
                              }}
                            >
                              <b>Data Inicio</b>
                              <DatePicker
                                style={{ width: '100%' }}
                                margin="normal"
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                okLabel="ok"
                                cancelLabel="cancelar"
                                invalidDateMessage="Data Inválida"
                                value={dataInicio}
                                onChange={handleDataInicioChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'dd/mm/aaaa',
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              />
                            </Box>
                            <Box
                              sx={{
                                mb: width < 1100 ? 2 : 0,
                                float: 'right',
                              }}
                            >
                              <b>Data Fim</b>
                              <DatePicker
                                style={{ width: '100%' }}
                                margin="normal"
                                id="date-picker-dialog"
                                format="dd/MM/yyyy"
                                okLabel="ok"
                                cancelLabel="cancelar"
                                invalidDateMessage="Data Inválida"
                                value={dataFim}
                                onChange={handleDataFimChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      placeholder: 'dd/mm/aaaa',
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                    }}
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                                // minDate={dtMinDate}
                                // maxDate={dtMaxDate}
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                      <Box
                        sx={{
                          width: width < 1100 ? '100%' : '20%',
                          mr: '2%',
                          mb: width < 1100 ? 2 : 0,
                        }}
                      >
                        <b>Obra</b>
                        <Autocomplete
                          name="obra"
                          noOptionsText="Sem dados"
                          clearText="Limpar"
                          size="small"
                          openText="Abrir"
                          sx={{ mt: -2 }}
                          closeText="Fechar"
                          value={obraSelected}
                          options={obrasValidacao || []}
                          getOptionLabel={(option) =>
                            `${option.recursos_id || option.id} - ${option.descricao} `
                          }
                          isOptionEqualToValue={(option, value) => {
                            // if (!value.id) return true;
                            return value.id === option.id;
                          }}
                          id="auto-complete"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              // required={user.recursosId.length === 0}
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                          onChange={(e, value) => {
                            handleChangeObra(e, value);
                            dispatch({
                              type: 'OBRA_VALIDACAO',
                              payload: value,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: width < 1100 ? '100%' : '20%',
                          mr: '2%',
                          mb: width < 1100 ? 2 : 0,
                        }}
                      >
                        <b>Funcionário</b>
                        <Autocomplete
                          name="funcionario"
                          noOptionsText="Sem dados"
                          clearText="Limpar"
                          size="small"
                          openText="Abrir"
                          closeText="Fechar"
                          sx={{ mt: -2 }}
                          value={funcionarioSelected}
                          options={funcionariosValidacao}
                          getOptionLabel={(option) =>
                            `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
                          }
                          isOptionEqualToValue={(option, value) => {
                            // if (!value.id) return true;
                            return value.id === option.id;
                          }}
                          id="auto-complete"
                          // autoComplete
                          // includeInputInList
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              // required={user.recursosId.length === 0}
                              // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                            />
                          )}
                          onChange={(e, value) => {
                            handleChangeFuncionario(e, value);
                            dispatch({
                              type: 'FUNCIONARIO_VALIDACAO',
                              payload: value,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: width < 1100 ? '100px' : '7%',
                          mr: '2%',
                          mb: width < 1100 ? 2 : 0,
                          mt: width < 1100 ? 0 : 2.5,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleConsultar}
                          className={classes.btnConsultar}
                          sx={{ width: '100%' }}
                        >
                          Filtrar
                        </Button>
                      </Box>
                      <Box sx={{ width: width < 1100 ? '100%' : '23%', mb: width < 1100 ? 2 : 0 }}>
                        <b>Pesquisar</b>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="search"
                          type="text"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <FiSearch />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                  <Row style={{ marginTop: '10px' }}>
                    <Col>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={historico.historico}
                              onChange={handleChangeHistorico}
                              name="historico"
                            />
                          }
                          label="Histórico"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* </Container> */}
                </Col>
                <Row>
                  <Col>
                    <Col md={12} sm={12}>
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Consulta
                        </h4>
                      </Box>
                    </Col>
                    <Col md={12} sm={12}>
                      <MUIDataTable
                        description="Nada para mostrar"
                        data={dados}
                        columns={columns}
                        options={options}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px', textAlign: 'right' }}>
                  <Col>
                    {/* {console.log('linhasSelecionadas', linhasSelecionadas)} */}
                    {linhasSelecionadas.length > 0 ? (
                      <Button
                        size="sm"
                        className={classes.btnValidar}
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Validar{' '}
                      </Button>
                    ) : (
                      <Button size="sm" className={classes.btnValidar} variant="contained" disabled>
                        Validar{' '}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* </MuiThemeProvider> */}
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default withStyles(customStyles)(ValidacaoCD);
