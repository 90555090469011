import Instance from '../../../../../utils/Axios';

const RegistoEscritorio = (registoId) => async (dispatch) => {
  console.log(registoId);
  await Instance()
    .get(`/obras/registohorasescritorio/horas/${registoId}`)
    .then(({ data }) => {
      console.log(data.yData[0]);
      // dispatch({ type: 'GET_REGISTO_ESCRITORIO', payload: {} });
      dispatch({ type: 'GET_REGISTO_ESCRITORIO', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default RegistoEscritorio;
