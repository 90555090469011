/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  UncontrolledTooltip,
  CardBody,
  Card,
} from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CardFuncionario from './components/CardFuncionario';
import BreadFuncionario from '../../../../App/Breadcrumbs/Administracao/Funcionarios/BreadFuncionario';
import { makeStyles } from '@mui/styles';
import { createTheme, Button, Box, Tab, Snackbar, Alert } from '@mui/material';

import { format, differenceInDays, getYear, endOfYear, endOfMonth } from 'date-fns';
import { red, green, yellow } from '@mui/material/colors';

// COMPONENTS
import CardFaltas from './components/CardFaltas';
import CardHoras from './components/horas/index';
import ObsFuncionarios from './components/observacoes';
import FooterLinks from '../../../../Account/Footer';

// ausencias
import ModalEdit from './components/ausencias/modalEdit';
import ModalDelete from './components/ausencias/modalDelete';
import ModalAdicionar from './components/ausencias/modalAdicionar';

// import BreadTiposFaltas from '../../../App/Breadcrumbs/Administracao/TiposFaltas/BreadTiposFaltas';<

import TiposFaltas from '../../../../../redux/actions/TiposFaltasAction/tiposFaltasDropdownAction';

// NOTIFICATION
import NotificationSystem from 'rc-notification';
import { FullWideNotification } from '../../../../../shared/components/Notification';

import GetFuncionario from '../../../../../redux/actions/FuncionariosActions/getFuncionarioAction';
import GetFuncionarioAusencias from '../../../../../redux/actions/FuncionariosActions/ausencias/getAusenciasAction';
import GetFuncionarioAusencia from '../../../../../redux/actions/FuncionariosActions/ausencias/getAusenciaAction';
import GetFuncionarioObservacoes from '../../../../../redux/actions/FuncionariosActions/observacoes/getObservacoesAction';

import { startOfYear } from 'date-fns/esm';
import CardFerias from './components/ferias/index';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import GetDiasMarcados from '../../../../../redux/actions/FuncionariosActions/syncFerias/getDiasMarcadosAction';
import GetDiasDisponiveis from '../../../../../redux/actions/FuncionariosActions/syncFerias/getDiasDisponiveisAction';
import GetFeriados from '../../../../../redux/actions/FuncionariosActions/syncFerias/getAllferiadosAction';
import GetAllDiasFeriasRecursos from '../../../../../redux/actions/FuncionariosActions/syncFerias/getAllDiasFeriasRecursoAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnEditar: {
    color: theme.palette.getContrastText(yellow[600]),
    backgroundColor: yellow[600],
    '&:hover': {
      backgroundColor: yellow[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnEditar1: {
    color: theme.palette.getContrastText(yellow[600]),
    // backgroundColor: yellow[600],
    border: `1px solid #fdd835`,
    '&:hover': {
      // backgroundColor: yellow[700],
      border: '1px solid yellow[700]',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: yellow[600],
      },
    },
  },
  btnAdicionar: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[500],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: green[400],
      },
    },
  },
  btnApagar: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: red[500],
      },
    },
  },
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  tab: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  miniCard: {
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.15)',
    minHeight: '60px',
    marginTop: '20px',
    borderRadius: '5px !important',
  },
  miniCard_date: {
    margin: '20px 0px 0px 20px !important',
    fontSize: '14px',
  },
  miniCard_obs: {
    margin: '20px 10px 10px 0px !important',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    minHeight: '30px',
  },
  miniCard_container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['roboto', 'sans-serif'].join(','),
  },
});

let notificationTC = null;

// ---------------------------- Window dimensions -------------------------//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
// ---------------------------------------------------------------------//

const DetalhesFuncionario = (theme) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const funcId = location.pathname.split('/')[4];

  // const classes = useStyles();

  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  // --------------------- Consulta ----------------------//
  const [dataInicioConsulta, setDataInicioConsulta] = useState(startOfYear(new Date()));
  const [dataFimConsulta, setDataFimConsulta] = useState(endOfMonth(new Date()));

  const handleDataInicioConsultaChange = (date) => {
    setDataInicioConsulta(date);
  };

  const handleDataFimConsultaChange = (date) => {
    setDataFimConsulta(date);
  };

  // ----------------------------------------------------------//
  // ----------------------- Tabs ----------------------------- //

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // --------------------------------------------------------- //

  // ---------------------- Observações ---------------------- //

  const fetchFuncionarioObservacoes = () => {
    const id = location.pathname.split('/')[4];
    dispatch(GetFuncionarioObservacoes(id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchFuncionarioObservacoes(), []);

  const observacoes = useSelector((state) => state.funcionarios.observacoes);

  // --------------------------------------------------------- //
  // ----------------------- Ausencias -----------------------//
  const feriados = useSelector((state) => state.ferias.feriados);
  const [ausencia, setAusencia] = useState({
    tipoFaltaId: '',
    dataInicio: format(new Date(), 'yyyy-MM-dd'),
    dataFim: format(new Date(), 'yyyy-MM-dd'),
    observacoes: '',
  });
  const [disableFaltou, setDisableFaltou] = useState(false);
  const [faltaSelecionada, setFaltaSelecionada] = useState({ id: 0, descricao: '' });
  const [ausenciaId, setAusenciaId] = useState();
  // eslint-disable-next-line prefer-const
  let ausenciaInfo = useSelector((state) => state.funcionarios.ausencia);
  const recursoId = useSelector((state) => state.funcionarios.funcionario.id);
  const funcAusencias = useSelector((state) => state.funcionarios.ausencias);
  const tiposfaltas = useSelector((state) => state.tiposFaltas.dropdownTiposFaltas);
  const tiposFaltasDrop = useSelector((state) => state.tiposFaltas.tiposFaltas);

  const resetAusencia = () => {
    setAusencia({
      tipoFaltaId: '',
      dataInicio: format(new Date(), 'yyyy-MM-dd'),
      dataFim: format(new Date(), 'yyyy-MM-dd'),
      observacoes: '',
    });
    // setValueTipoFaltaAutoComplete({
    //   id: 0,
    //   descricao: '',
    // });
    setFaltaSelecionada({
      id: 0,
      descricao: '',
    });
  };

  // ----------------------------------------------------------//

  const year = getYear(new Date());

  const fetchFeriados = () => {
    dispatch(GetFeriados());
  };
  const fetchFuncionario = () => {
    dispatch(GetFuncionario(funcId, navigate));
  };
  const fetchTiposFaltas = () => {
    dispatch(TiposFaltas());
  };
  const fetchFuncionarioAusencias = () => {
    const dataInicioConsultaFormated = format(dataInicioConsulta, 'yyyy-MM-dd');
    const dataFimConsultaFormated = format(dataFimConsulta, 'yyyy-MM-dd');
    dispatch(
      GetFuncionarioAusencias(
        funcId,
        dataInicioConsultaFormated,
        dataFimConsultaFormated,
        faltaSelecionada,
      ),
    );
  };
  const fetchFuncionarioAusencia = (id) => {
    dispatch(GetFuncionarioAusencia(id));
  };
  const fetchDiasMarcados = (recursoId) => {
    dispatch(GetDiasMarcados(recursoId));
  };

  const fetchDiasDisponiveis = (recursoId, yearChosen) => {
    dispatch(GetDiasDisponiveis(recursoId, yearChosen));
  };

  const fetchDiasTotaisFerias = (recursoId, yearChosen) => {
    dispatch(GetAllDiasFeriasRecursos(recursoId, yearChosen));
  };

  useEffect(() => fetchFuncionario(), []);
  useEffect(() => fetchFeriados(), []);
  useEffect(() => fetchTiposFaltas(), []);
  useEffect(() => fetchFuncionarioAusencias(), []);
  useEffect(() => {
    fetchDiasMarcados(recursoId);
    // fetchDiasDisponiveis(recursoId, year);
    fetchDiasTotaisFerias(recursoId, year);
  }, [recursoId]);

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    NotificationSystem.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ------------------------ Snackbar----------------------------  //

  const [open, setOpen] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // ------------------------------------------------------------- //

  // -------------------------- Validacao Ausencia --------------------//

  const [errorsAusencia, setErrorsAusencia] = useState({});

  const validationAusencia = (fieldValues = ausencia) => {
    const temp = { ...errorsAusencia };
    const intervalo = differenceInDays(
      new Date(fieldValues.dataFim),
      new Date(fieldValues.dataInicio),
    );
    if ('tipoFaltaId' in fieldValues)
      temp.tipoFaltaId = fieldValues.tipoFaltaId ? '' : 'Campo Obrigatório';

    if ('dataInicio' in fieldValues)
      temp.dataInicio = fieldValues.dataInicio ? '' : 'Campo Obrigatório';
    if ('dataFim' in fieldValues) temp.dataFim = fieldValues.dataFim ? '' : 'Campo Obrigatório';

    if ('dataInicio' in fieldValues && 'dataFim' in fieldValues && intervalo < 0) {
      temp.dataFim = 'Data de inicio tem de ser menor que Data de fim';
    } else {
      temp.dataFim = '';
    }

    setErrorsAusencia({
      ...temp,
    });
    if (fieldValues == ausencia) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  // -----------------------------------------------------------------------//

  // ------------- Ferias ---------------//

  // const fetchFuncionarioFerias = () => {
  //   const id = location.pathname.split('/')[4];
  //   dispatch(GetFuncionarioFerias(id));
  // };

  // useEffect(() => fetchFuncionarioFerias(), []);

  // const ferias = useSelector((state) => state.funcionarios.ferias);

  // ----------------------------------//

  // ---------Modal Handlers--------------- //

  const handleOpen = (btn, e, id) => {
    e.preventDefault();
    // setErrorsTipoFalta({});
    setLoading(false);
    setErrorsAusencia({});
    if (btn == 'edit') {
      // setAusenciaId(id);
      fetchFuncionarioAusencia(id);
      resetAusencia();
      setOpenEdit(true);

      // fetchFuncionarioAusencia().then(() => setOpenEdit(true));
      // setTipoFaltaId(id);
      // setTipoFaltaNome(tableMeta.rowData[0]);
      // fetchTipoFalta(id, e).then(() => setOpenEdit(true));
    } else if (btn == 'delete') {
      setOpenDelete(true);
      setAusenciaId(id);
      resetAusencia();

      // setTipoFaltaNome(tableMeta.rowData[0]);
    } else if (btn == 'add') {
      setDisableFaltou(false);
      setOpenAdicionar(true);
      dispatch({ type: 'CLEAR_AUSENCIA', payload: {} });
      resetAusencia();
    } else {
      setDisableFaltou(true);
      setOpenAdicionar(true);
      setFaltaSelecionada({
        id: 3,
        descricao: 'Férias',
      });
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    setFaltaSelecionada({
      id: 0,
      descricao: '',
    });
    // setErrorsTipoFalta({});
    if (btn == 'edit') {
      setOpenEdit(false);
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else {
      setOpenAdicionar(false);

      // setErrorsTipoFalta({});
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            {/* AUSENCIAS */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ mt: 6 }}
              open={open}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                Funcionário não tem dias de férias suficientes!
              </Alert>
            </Snackbar>
            <ModalAdicionar
              openAdicionar={openAdicionar}
              handleClose={handleClose}
              ausencia={ausencia}
              setAusencia={setAusencia}
              validationAusencia={validationAusencia}
              errorsAusencia={errorsAusencia}
              fetchFuncionarioAusencias={fetchFuncionarioAusencias}
              tiposfaltas={tiposfaltas}
              funcId={funcId}
              show={show}
              faltaSelecionada={faltaSelecionada}
              setFaltaSelecionada={setFaltaSelecionada}
              // Loading
              loading={loading}
              setLoading={setLoading}
              disableFaltou={disableFaltou}
              fetchDiasMarcados={fetchDiasMarcados}
              fetchDiasDisponiveis={fetchDiasDisponiveis}
              feriados={feriados}
              setOpen={setOpen}
            />
            <ModalDelete
              openDelete={openDelete}
              handleClose={handleClose}
              fetchFuncionarioAusencias={fetchFuncionarioAusencias}
              funcId={funcId}
              ausenciaId={ausenciaId}
              show={show}
              // Loading
              loading={loading}
              setLoading={setLoading}
            />
            <ModalEdit
              openEdit={openEdit}
              handleClose={handleClose}
              ausencia={ausencia}
              setAusencia={setAusencia}
              validationAusencia={validationAusencia}
              errorsAusencia={errorsAusencia}
              fetchFuncionarioAusencias={fetchFuncionarioAusencias}
              tiposfaltas={tiposfaltas}
              funcId={funcId}
              show={show}
              ausenciaInfo={ausenciaInfo}
              faltaSelecionada={faltaSelecionada}
              setFaltaSelecionada={setFaltaSelecionada}
              // Loading
              loading={loading}
              setLoading={setLoading}
              tiposFaltasDrop={tiposFaltasDrop}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <BreadFuncionario />
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/administracao/funcionarios" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button
                    id="TooltipBottom"
                    outline
                    className={classes.btnVoltar}
                    color="secondary"
                    size="sm"
                  >
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip
                    // dir={dir}
                    placement="bottom"
                    target="TooltipBottom"
                  >
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Funcionário
              </h4>
            </h3>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Col md={12} sm={12}>
              <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                  <Box>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                    >
                      <Tab label="Informações" value="1" className={classes.tab} />
                      <Tab label="Horas" value="2" className={classes.tab} />
                      <Tab label="Faltas/Férias" value="3" className={classes.tab} />
                      <Tab label="Observações" value="4" className={classes.tab} />
                      {/* <Tab label="Agenda" value="5" className={classes.tab} /> */}
                      {/* <Tab label="Férias" value="6" className={classes.tab} /> */}
                      {/* <Tab label="Fardamento" value="7" className={classes.tab} /> */}
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <CardFuncionario
                      fetchFuncionario={fetchFuncionario}
                      show={show}
                      defaultMaterialTheme={defaultMaterialTheme}
                      useWindowDimensions={useWindowDimensions}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      width={width}
                      faltaSelecionada={faltaSelecionada}
                      setFaltaSelecionada={setFaltaSelecionada}
                      setDisableFaltou={setDisableFaltou}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <CardHoras
                      classes={classes}
                      defaultMaterialTheme={defaultMaterialTheme}
                      // ferias={ferias}
                      funcId={funcId}
                      // fetchFuncionarioFerias={fetchFuncionarioFerias}
                      show={show}
                      // Loading
                      loading={loading}
                      setLoading={setLoading}
                      width={width}
                    />
                  </TabPanel>
                  <TabPanel value="3">
                    <CardFaltas
                      fetchFuncionario={fetchFuncionario}
                      show={show}
                      funcId={funcId}
                      dataInicioConsulta={dataInicioConsulta}
                      dataFimConsulta={dataFimConsulta}
                      handleDataInicioConsultaChange={handleDataInicioConsultaChange}
                      handleDataFimConsultaChange={handleDataFimConsultaChange}
                      funcAusencias={funcAusencias}
                      handleOpen={handleOpen}
                      handleClose={handleClose}
                      classes={classes}
                      // Loading
                      loading={loading}
                      setLoading={setLoading}
                      width={width}
                      faltaSelecionada={faltaSelecionada}
                      setFaltaSelecionada={setFaltaSelecionada}
                      tiposfaltas={tiposfaltas}
                      ausencia={ausencia}
                      setAusencia={setAusencia}
                      validationAusencia={validationAusencia}
                    />
                  </TabPanel>
                  <TabPanel value="4">
                    <ObsFuncionarios
                      classes={classes}
                      observacoes={observacoes}
                      funcId={funcId}
                      fetchFuncionarioObservacoes={fetchFuncionarioObservacoes}
                      show={show}
                      // Loading
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  <TabPanel value="5">Agenda</TabPanel>
                  <TabPanel value="6">
                    <CardFerias
                      classes={classes}
                      defaultMaterialTheme={defaultMaterialTheme}
                      // ferias={ferias}
                      funcId={funcId}
                      // fetchFuncionarioFerias={fetchFuncionarioFerias}
                      show={show}
                      // Loading
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  <TabPanel value="7">Fardamento</TabPanel>
                </TabContext>
              </Box>
            </Col>
          </CardBody>
        </Card>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default DetalhesFuncionario;
