import Instance from '../../../utils/Axios';

const ExcluirFuncionario = (obra, funcionario) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .delete(`/obras/registartempos/associarRecurso/${obra}/${funcionario}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'EXCLUIR_FUNCIONARIO', payload: data });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default ExcluirFuncionario;
