export const globalInitialState = {
  // FILTERS
  estadosProjeto: [],
  estadosSubProjeto: [],

  // TABLE PAGINATION
  criarEquipasTablePage: 0,
  regHorasOperacionaisTablePage: 0,
  regHorasEscritorioTablePage: 0,
  obrasTablePage: 0,
  valCDTablePage: 0,
  valRHTablePage: 0,
  valADTablePage: 0,
  utilizadoresTablePage: 0,
  funcionariosTablePage: 0,

  // SEARCH
  criarEquipasTableSearch: '',
  regHorasOperacionaisTableSearch: '',
  regHorasEscritorioTableSearch: '',
  obrasTableSearch: '',
  valCDTableSearch: '',
  valRHTableSearch: '',
  valADTableSearch: '',
  utilizadoresTableSearch: '',
  funcionariosTableSearch: '',
};
