import { tipoAtrasoInitialState } from '../InitialState/tipoAtrasos';

const tiposAtrasosReducer = (state = tipoAtrasoInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TIPOATRASOS':
      return {
        ...state,
        tiposAtrasos: payload,
      };
    case 'GET_TIPOATRASOS_DROPDOWN':
      return {
        ...state,
        dropdownTiposAtrasos: payload,
      };
    case 'GET_TIPOATRASO':
      return {
        ...state,
        tipoAtraso: payload,
      };
    case 'POST_TIPOATRASO':
      return {
        ...state,
        novoTipoAtraso: payload,
      };
    case 'PUT_TIPOATRASO':
      return {
        ...state,
        tipoAtrasoEditado: payload,
      };
    case 'DELETE_TIPOATRASO':
      return {
        ...state,
        tipoAtrasoRemovido: payload,
      };
    case 'CLEAR_DATA_TIPOATRASO':
      return {
        ...state,
        tipoAtraso: [],
      };

    default:
      return state;
  }
};

export default tiposAtrasosReducer;
