import Instance from '../../../../utils/Axios';

const GetFuncionarioHoras = (funcId, datainicio, datafim) => async (dispatch) => {
  const stateToken = localStorage.token;
  console.log(funcId, datainicio, datafim);
  await Instance()
    .get(`/recursos/horas/${funcId}/${datainicio}/${datafim}`)
    .then(({ data }) => {
      console.log(data);
      if (stateToken && dispatch) {
        dispatch({ type: 'GET_FUNCIONARIO_HORAS', payload: data.yData });
      }
    })
    .catch(({ err }) => console.log(err));
};

export default GetFuncionarioHoras;
