const DadosFormulárioValidacao = (dataInicio, dataFim) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    dispatch({
      type: 'DADOS_FORMULARIO_VALIDACAO',
      payload: { dataInicio: new Date(dataInicio), dataFim: new Date(dataFim) },
    });
  }
};

export default DadosFormulárioValidacao;
