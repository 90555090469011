import React from 'react';
import { Box, Button } from '@mui/material';

const HorasButtons = ({ recurso, classes, handleData, width }) => {
  return (
    <Box sx={{ ml: 1 }}>
      {recurso.qtd <= 0 ? null : (
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtd - 1);
          }}
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          -
        </Button>
      )}
      {recurso.qtd >= 24 ? (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          disabled
        >
          +
        </Button>
      ) : (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtd + 1);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +
        </Button>
      )}
      {recurso.qtd > 20 ? (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          disabled
        >
          +4h
        </Button>
      ) : (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtd + 4);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +4h
        </Button>
      )}
      {recurso.qtd > 16 ? (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          disabled
        >
          +8h
        </Button>
      ) : (
        <Button
          className={width < 770 ? classes.buttonsMobile : classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtd + 8);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +8h
        </Button>
      )}
    </Box>
  );
};

export default HorasButtons;
