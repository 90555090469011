import Instance from '../../../utils/Axios';

const CoresObras = () => async (dispatch) => {
  // const stateToken = localStorage.token;

  await Instance()
    .get(`/obras/cores`)
    .then(({ data }) => {
      dispatch({ type: 'GET_CORES_OBRAS', payload: data.yData });
      //   show('success', 'Cor');
    })
    .catch(({ err }) => {
      if (err) {
        // show('warning', 'A cor selecionada já está a ser utilizada');
        console.log(err.status);
      }
    });
};

// Meter aqui o put para enviar o HE

export default CoresObras;
