import Instance from '../../../utils/Axios';

const EditarTipoFalta = (ydata, id, show) => async (dispatch) => {
  await Instance()
    .put(`/tipofaltas/${id}`, { ydata })
    .then(({ data }) => {
      dispatch({ type: 'PUT_TIPOFALTA', payload: data });
      show('success', ' Tipo de falta editado com sucesso!');
    })
    .catch((err) => {
      // eslint-disable-next-line eqeqeq
      if (err.response.status != 200) {
        // show('warning', 'Preencha todos os campos');
      }
    });
};

export default EditarTipoFalta;
