/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Col, Container, Row, ButtonToolbar, UncontrolledTooltip } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { Button } from '@mui/material';

import BreadHoras from '../../../../App/Breadcrumbs/RegistarHoras/Operacionais/BreadRegistarHoras';

import CardTempos from './components/CardTempos';
import FooterLinks from '../../../../Account/Footer';

import HistoricoObra from '../../../../../redux/actions/TemposActions/HistoricoObraAction';
import TiposFaltas from '../../../../../redux/actions/TiposFaltasAction/tiposFaltasDropdownAction';
import TiposPeriodos from '../../../../../redux/actions/TiposPeriodosAction/tiposPeriodosDropdownAction';
import TiposAtrasos from '../../../../../redux/actions/TiposAtrasosAction/tiposAtrasosDropdownAction';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnAdicionar: {
    color: '#FFFFFF',
    backgroundColor: '#E21450',
    '&:hover': {
      backgroundColor: '#44c9a3',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#44c9a3',
      },
    },
    width: '200px',
    height: '40px',
  },

  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },
  btnVoltar: {
    height: '30px',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
}));

const Horas = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const fetchHistorico = () => {
    const id = location.pathname.split('/')[4];
    dispatch(HistoricoObra(id));
  };

  useEffect(() => {
    fetchHistorico();

    dispatch(TiposFaltas());
    dispatch(TiposPeriodos());
    dispatch(TiposAtrasos());
  }, []);
  const historico = useSelector((state) => state.tempos.historicoObras);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadHoras />
            <h3 className="page-title" style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to="/app/registarHoras/operacionais" style={{ textDecoration: 'none' }}>
                <ButtonToolbar
                  className="btn-toolbar--center"
                  style={{
                    marginRight: '10px',
                  }}
                >
                  <Button
                    id="TooltipBottom"
                    outline
                    className={classes.btnVoltar}
                    color="secondary"
                    size="sm"
                  >
                    <span
                      className="lnr lnr-chevron-left"
                      style={{ fontSize: 10, fontWeight: 900 }}
                    />
                  </Button>

                  <UncontrolledTooltip placement="bottom" target="TooltipBottom">
                    Voltar
                  </UncontrolledTooltip>
                </ButtonToolbar>
              </Link>
              <h4
                style={{
                  fontSize: '22px',
                  width: '100%',
                }}
                className="text-underline"
              >
                Registar Horas Operacionais
              </h4>
            </h3>
          </Col>
        </Row>
        <Col md={12}>
          <Row>
            <CardTempos historico={historico} fetchHistorico={fetchHistorico} classes={classes} />
          </Row>
        </Col>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default Horas;
