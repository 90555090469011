/* eslint-disable no-unused-expressions */
import Instance from '../../../../utils/Axios';

const GetFuncionarioAusencias =
  (funcionarioId, datainicio, datafim, faltaSelecionada) => async (dispatch) => {
    const stateToken = localStorage.token;
    let faltaId = faltaSelecionada.id;
    if (faltaId == 0) {
      faltaId = null;
    }
    await Instance()
      .get(`/recursos/ausencias/${funcionarioId}/${datainicio}/${datafim}`, { params: { faltaId } })
      .then(({ data }) => {
        if (stateToken && dispatch) {
          dispatch({ type: 'GET_FUNCIONARIO_AUSENCIAS', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  };

export default GetFuncionarioAusencias;
