/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { Button, TextField, InputAdornment, IconButton, Box } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';

import TabelaRelatorio from './TabelaRelatorio';
import RelatorioObra from '../../../../../../redux/actions/RelatoriosActions/RelatorioColaboradoresAtivos/RelatorioColaboradoresAtivosAction';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const CardRelatorio = ({ classes }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [btnObterDados, setBtnObterDados] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setBtnObterDados(false);
    dispatch({ type: 'GET_RELATORIO_COLABORADORES_ATIVOS', payload: [] });
  };

  const handleSend = (e) => {
    const dataRegisto = format(selectedDate, 'yyyy-MM-dd');

    e.preventDefault();
    dispatch(RelatorioObra(dataRegisto));
    setBtnObterDados(true);
  };

  useEffect(() => setBtnObterDados(false), []);
  // useEffect(() => handleSend(), []);
  useEffect(() => dispatch({ type: 'GET_RELATORIO_COLABORADORES_ATIVOS', payload: [] }), []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Col md={12} sm={12}>
                  <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                    <h4
                      style={{
                        width: '100%',
                      }}
                      className="text-underline-title"
                    >
                      Colaboradores Ativos
                    </h4>
                  </Box>
                  {/* <Container> */}
                  <Box sx={{ display: 'flex', flexDirection: width < 1100 ? 'column' : 'row' }}>
                    <Box
                      sx={{
                        width: width < 1100 ? '100%' : '20%',
                        mr: width < 1100 ? 0 : '2%',
                        mb: width < 1100 ? 2 : 0,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                        <b>Data Inicio</b>
                        <DatePicker
                          style={{ width: '100%' }}
                          margin="normal"
                          id="date-picker-dialog"
                          format="dd/MM/yyyy"
                          okLabel="ok"
                          cancelLabel="cancelar"
                          invalidDateMessage="Data Inválida"
                          value={selectedDate}
                          onChange={handleDateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'dd/mm/aaaa',
                              }}
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    {/* </Container> */}
                    <Box
                      sx={{
                        width: width < 1100 ? '100px' : '30%',
                        mr: width < 1100 ? 0 : '2%',
                        mb: width < 1100 ? 2 : 0,
                        mt: width < 1100 ? 0 : 2.5,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={(e) => handleSend(e)}
                        className={classes.btnOrange}
                      >
                        Filtrar
                      </Button>
                    </Box>
                    <Box sx={{ width: width < 1100 ? '0%' : '20%' }}></Box>
                    <Box
                      sx={{
                        width: width < 1100 ? '100%' : '30%',
                        mb: 2,
                      }}
                    >
                      <b>Pesquisar</b>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="search"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <FiSearch />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Col>
                <TabelaRelatorio
                  fdate={selectedDate}
                  btnObterDados={btnObterDados}
                  setBtnObterDados={setBtnObterDados}
                  classes={classes}
                  searchText={searchText}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CardRelatorio;
