export const validacaoInitialState = {
  validacaoCD: [],
  validacaoRH: [],
  validacaoAD: [],

  // CD
  obrasValidacao: {},
  funcionariosValidacao: [],
  enviarValidacaoCD: [],

  validacaoIndividualCD: {},
  enviarValidacaoIndividualCD: [],

  // RH
  validacaoIndividualRH: {},
  enviarValidacaoRH: [],
  enviarValidacaoIndividualRH: [],

  // AD
  validacaoIndividualAD: {},
  enviarValidacaoAD: [],
  enviarValidacaoIndividualAD: [],
  todasObrasValAd: [],
  todosFuncionariosValAd: [],

  // DELETE
  registoHorasDeleted: [],

  // DADOS FORMULÁRIO
  dadosFormularioValidacao: {
    dataInicio: '',
    dataFim: '',
  },
};
