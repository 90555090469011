import Instance from '../../../utils/Axios';

const GetLogsHoras = (dataInicio, obraId, funcionarioId) => async (dispatch) => {
  const stateToken = localStorage.token;
  console.log({
    data: dataInicio,
    obraId,
    funcionarioId,
  });
  if (stateToken) {
    await Instance()
      .get('/logshoras', {
        params: {
          data: dataInicio,
          obraId,
          funcionarioId,
        },
      })
      .then(({ data }) => {
        console.log(data);
        dispatch({ type: 'GET_LOGS_HORAS', payload: data.yData });
      })
      .catch((err) => console.log(err));
  }
};

export default GetLogsHoras;
