import React from 'react';
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

// ACTIONS

import RemoverFuncionarioObservacao from '../../../../../../../redux/actions/FuncionariosActions/observacoes/removerObservacaoAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalDelete = ({
  openDelete,
  handleClose,
  fetchFuncionarioObservacoes,
  show,
  obserId,
  // handleOpenAlert,
  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleRemover = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(RemoverFuncionarioObservacao(obserId, show))
      .then(() => handleClose('delete', e))
      .then(() => fetchFuncionarioObservacoes());
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        onClose={(e) => handleClose('delete', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 id="transition-modal-title" className="text-underline-title-dark">
                Remover de observação
              </h3>
              <p id="transition-modal-description">
                Tem a certeza que quer remover esta observação?
              </p>
            </div>

            <br />
            <br />
            <div className={classes.modalBtns}>
              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('delete', e)}
              >
                Cancelar
              </Button>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading}
                variant="contained"
                onClick={handleRemover}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalDelete;
