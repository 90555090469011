import React, { useState } from 'react';
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import Form from './Form';

// ACTIONS

import AdicionarFuncionario from '../../../../../../../redux/actions/TemposActions/AdicionarFuncionarioAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',

    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },

  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const AdicionarRecursoModal = ({
  handleClose,
  toggle,
  openAdd,
  // rtl,
  idObra,
  recurso,
  setRecurso,
  fetchFuncNaoAss,

  recursosAssociados,
  recursosAssociadosOld,

  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //* ********************************************************** */
  const [errors, setErrors] = useState({});

  // --------------------Validation---------------- //

  const validation = (fieldValue = recurso) => {
    const temp = { ...errors };

    if ('recursosId' in fieldValue)
      temp.recursosId = fieldValue.recursosId.length != 0 ? '' : 'Campo Obrigatório';
    setErrors({
      ...temp,
    });
    if (fieldValue) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  // ---------------------------------------------- //

  const handleSubmit = (e) => {
    e.preventDefault();

    const funcId = recurso && recurso.recursosId && recurso.recursosId.map((v) => v.id);

    const ydata = [
      {
        obra: idObra,
        funcionario: funcId,
      },
    ];

    if (validation() == true) {
      setLoading(true);
      recurso.recursosId.map((rec) =>
        recursosAssociados.push({
          ausencia: null,
          descricaoFalta: null,
          existeAusencia: false,
          existeHorasNoutraObra: false,
          faltou: false,
          nome: rec.nome,
          nome2: rec.nome2,
          notificacaoHorasNoutraObra: '',
          obras_id: idObra,
          observacoes: null,
          qtd: 0,
          recursos_id: rec.id,
          tipoatrasos_id: null,
          tipofaltas_id: null,
          tipoperiodos_id: null,
          validadoAD: false,
          validadoCD: false,
          validadoRH: false,
        }),
      );

      // eslint-disable-next-line func-names
      recursosAssociados.sort(function (a, b) {
        if (a.recursos_id > b.recursos_id) {
          return 1;
        }
        if (a.recursos_id < b.recursos_id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      dispatch(AdicionarFuncionario(ydata))
        .then(() => fetchFuncNaoAss())
        .then(() => handleClose('add', e));
    }
  };
  //* ********************************************************** */

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        onClose={(e) => handleClose('add', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 className="text-underline-title-dark">Adicionar funcionário</h3>
              <p id="transition-modal-description">
                Preencher o formulário para adicionar um Recurso á obra.
              </p>
            </div>
            <Form
              recurso={recurso}
              setRecurso={setRecurso}
              onSubmit={handleSubmit}
              toggle={toggle}
              errors={errors}
              validation={validation}
              recursosAssociadosOld={recursosAssociadosOld}
            />
            <br />
            <br />
            <div className={classes.modalBtns}>
              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('add', e)}
              >
                Cancelar
              </Button>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AdicionarRecursoModal;
