import Instance from '../../../utils/Axios';

const RegistarTempos = (ydata, navigate, show) => async (dispatch) => {
  await Instance()
    .post('/obras/registartempos', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_TEMPOS', payload: data });
      show('success', 'Horas registadas!');
    })
    .catch((err) => {
      console.log(err);
    });
};

export default RegistarTempos;
