/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { withStyles } from '@mui/styles';

// Redux

// Actions

// Components

const GreenCheckbox = withStyles({
  root: {
    color: '#E21450',
    '&$checked': {
      color: '#E21450',
    },
    background: 'transparent !important',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RoleAdministrador = ({ infoValIndiv, tiposfaltas, setValueTipoFaltaAutoComplete, width }) => {
  const [horas, setHoras] = useState(infoValIndiv.qtd);

  const [checkbox, setCheckbox] = useState((infoValIndiv && infoValIndiv.validadoAD) || false);

  const [valueObservacoesTextField, setValueObservacoesTextField] = useState('');

  useEffect(() => {
    setHoras(infoValIndiv.qtd);
    setCheckbox(infoValIndiv.validadoAD);
    const tipoFaltaSelected = tiposfaltas.find((falta) => falta.id === infoValIndiv.tipofaltas_id);

    if (tipoFaltaSelected && infoValIndiv.faltou) {
      setValueTipoFaltaAutoComplete(tipoFaltaSelected);
    } else {
      setValueTipoFaltaAutoComplete(null);
    }

    setValueObservacoesTextField(infoValIndiv.observacoesAD || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoValIndiv]);

  const handleChangeObservacoes = (e) => {
    setValueObservacoesTextField(e.target.value);

    infoValIndiv.observacoesAD = e.target.value;
    // dispatch(GuardarTempos(recursosAssociados));
    console.log(infoValIndiv);
  };

  const handleValidado = (val) => {
    setCheckbox(val);

    infoValIndiv.validadoAD = val;

    console.log(infoValIndiv);
  };

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #000', my: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Administrador
        </h4>
      </Box>

      <Box sx={{ display: 'flex', my: 2 }}>
        <Box sx={{ width: width < 1100 ? '0%' : '20%' }}></Box>
        <Box sx={{ width: width < 1100 ? '28%' : '10%', mr: 2, mt: 2.5, height: '40px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={() => {
                  handleValidado(!checkbox);
                }}
                name="validado"
                // disabled
              />
            }
            label="Validado"
            // style={{ width: '20%' }}
          />
        </Box>
        <Box sx={{ width: '70%' }}>
          <b>Obs. Administrador</b>
          <TextField
            name="observacoes"
            variant="outlined"
            style={{ width: '100%' }}
            size="small"
            value={valueObservacoesTextField || ''}
            onChange={handleChangeObservacoes}
          />
        </Box>
      </Box>
    </>
  );
};

export default RoleAdministrador;
