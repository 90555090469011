import React, { useState, useEffect } from 'react';
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Modal, Backdrop, Fade, Button, CircularProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import Form from '../Form';

// ACTIONS
import TodosUsers from '../../../../../../../redux/actions/UsersActions/TodosOsUsersAction';
import EditarUser from '../../../../../../../redux/actions/UsersActions/EditarUserAction';
import FuncionariosUtilizadores from '../../../../../../../redux/actions/UsersActions/funcionariosAction';

// import RemoverMenu from '../../../../utils/redux/actions/menus/removerMenuAction';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalEditarUtilizador = ({
  openEdit,
  handleClose,
  info,
  validateOnChange = true,
  user,
  setUser,
  // resetForm,
  toggle,
  recursos,
  show,
  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //* ********************************************************** */
  const [errors, setErrors] = useState({});

  const [oldUser, setOldUser] = useState([
    {
      nome: '',
      // email: '',
      username: '',
      perfisId: '',
      recursosId: '',
      recursosIdAutoCompleteChange: null,
    },
  ]);

  useEffect(() => setErrors({}), [toggle]);

  useEffect(() => {
    const funcionarioSelecionado = recursos.find((func) => func.id === info.recursos_id);

    setOldUser([
      {
        nome: info.nome,
        // email: info.username,
        perfisId: info.perfis_id,
        recursosId: funcionarioSelecionado ? funcionarioSelecionado.id : '',
        username: info.username,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEdit, toggle]);

  // --------------------Validation---------------- //
  const validation = (fieldValues = user) => {
    const temp = { ...errors };
    if ('nome' in fieldValues) temp.nome = fieldValues.nome ? '' : 'Campo Obrigatório';
    if ('username' in fieldValues) temp.username = fieldValues.username ? '' : 'Campo Obrigatório';
    // Confirmação do email notepad ++
    if ('recursosId' in fieldValues)
      temp.recursosId = fieldValues.recursosId.length != 0 ? '' : 'Campo Obrigatório';
    if ('perfisId' in fieldValues)
      temp.perfisId = fieldValues.perfisId.length != 0 ? '' : 'Campo Obrigatório';
    setErrors({
      ...temp,
    });

    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  // ---------------------------------------------- //

  const handleFields = (e) => {
    e.preventDefault();
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validation({ [e.target.name]: e.target.value });
  };

  const fetchUsers = async () => {
    await dispatch(TodosUsers());
  };

  const fetchFuncionariosUtilizadores = () => {
    dispatch(FuncionariosUtilizadores(info && info.id));
  };

  const utilizador = useSelector((state) => state.users.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    const ydata = [
      {
        nome: user.nome,
        // email: user.email,
        perfisId: user.perfisId,
        recursosId: user.recursosId,
        username: user.username,
        permissoes: utilizador.permissoes
          .map((p) => p.lista)
          .flat()
          .map((permissao) => {
            if (permissao.permissao) {
              return permissao.id;
            }
            return null;
          })
          .filter((permissao) => {
            return permissao != null;
          }),
      },
    ];

    if (validation() == true) {
      setLoading(true);
      dispatch(
        EditarUser(
          info.id,
          ydata,
          oldUser,
          show,
          handleClose,
          e,
          // toggle
        ),
      )
        .then(() => fetchUsers())
        // .then(() => toggle())
        .then(() => fetchFuncionariosUtilizadores())
        .then(() => setOldUser(ydata));
    }
  };
  //* ********************************************************** */

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        onClose={(e) => handleClose('edit', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 id="transition-modal-title" className="text-underline-title-dark">
                Editar Utilizador
              </h3>
              <p id="transition-modal-description">
                Preencher o formulário para editar o perfil de <b>{info.nome}</b>
              </p>
            </div>
            <Form
              user={user}
              setUser={setUser}
              onSubmit={handleSubmit}
              toggle={toggle}
              errors={errors}
              validation={validation}
              handleFields={handleFields}
            />
            <br />
            <br />
            <div className={classes.modalBtns}>
              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('edit', e)}
              >
                Cancelar
              </Button>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalEditarUtilizador;
