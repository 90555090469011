/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, CardBody } from 'reactstrap';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './utilizadores.css';

import MUIDataTable from 'mui-datatables';
import { FiEdit, FiPlus, FiSearch } from 'react-icons/fi';

// COMPONENTS

import ModalAdicionar from './components/modalAdicionar';
import FooterLinks from '../../../Account/Footer';

// Breadcrumbs
import BreadUtilizadores from '../../../App/Breadcrumbs/Administracao/Utilizadores/BreadUtilizadores';

// ACTIONS

import FuncionariosUtilizadores from '../../../../redux/actions/UsersActions/funcionariosAction';
import Perfis from '../../../../redux/actions/UsersActions/PerfisAction';
import TodosUsers from '../../../../redux/actions/UsersActions/TodosOsUsersAction';
import GetPermissoes from '../../../../redux/actions/UsersActions/GetPermissoesAction';

const ListaUtilizadores = () => {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [openAdicionar, setOpenAdicionar] = useState(false);

  // --------------- Add User Modal------------------//
  const [user, setUser] = useState({
    nome: '',
    username: '',
    password: '',
    confirmarPassword: '',
    perfisId: '',
    recursosId: '',
  });

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setUser({
      nome: '',
      username: '',
      password: '',
      confirmarPassword: '',
      perfisId: '',
      recursosId: '',
    });
  };
  const toggle = () => {
    setOpenAdicionar((prevState) => !prevState);
    setUser({
      nome: '',
      username: '',
      password: '',
      confirmarPassword: '',
      perfisId: '',
      recursosId: '',
    });
    resetForm();
  };
  // -----------------------------------------------//
  const dispatch = useDispatch();

  const fetchUsers = () => {
    dispatch(TodosUsers());
  };

  const fetchRecursos = () => {
    dispatch(FuncionariosUtilizadores());
  };

  const fetchPerfis = () => {
    dispatch(Perfis());
  };
  const fetchPermissoes = () => {
    dispatch(GetPermissoes());
  };

  // const newUser = useSelector((state) => state.users.novoUser);
  useEffect(() => fetchRecursos(), []);
  useEffect(() => fetchPerfis(), []);
  useEffect(() => fetchUsers(), []);

  const users = useSelector((state) => state.users.todosUsers);

  // eslint-disable-next-line no-unused-vars
  const handleOpen = (btn, e, id, tableMeta) => {
    e.preventDefault();
    setLoading(false);
    if (btn == 'adicionar') {
      resetForm();
      setOpenAdicionar(true);
      fetchPermissoes();
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();
    if (btn == 'adicionar') {
      setOpenAdicionar(false);
      dispatch({
        type: 'GET_PERMISSOES',
        payload: [],
      });
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'nome',
      label: 'Utilizador',
    },
    {
      name: 'descricaoPerfil',
      label: 'Perfil',
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => {
          return (
            <div className="table-btns-utilizadores">
              <Link to={`/app/administracao/utilizadores/${value}`}>
                <Button>
                  <FiEdit size={30} strokeWidth={1} color="#000" />
                </Button>
              </Link>
            </div>
          );
        },
      },
    },
  ];

  const tablePage = useSelector((state) => state.global.utilizadoresTableSearch);
  const searchTextValue = useSelector((state) => state.global.utilizadoresTableSearch);
  const [searchText, setSearchText] = useState(searchTextValue);

  const options = {
    filter: false,
    filterType: 'dropdown',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    // customToolbar: () => {
    //   return <CustomToolbar handleOpen={handleOpen} />;
    // },
    page: tablePage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        dispatch({ type: 'UTILIZADORES_TABLE_PAGINATION', payload: tableState.page });
      }
      if (action === 'propsUpdate') {
        dispatch({
          type: 'UTILIZADORES_TABLE_SEARCH',
          payload: tableState.searchText,
        });
      }
    },
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  return (
    <>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        setOpenAdicionar={setOpenAdicionar}
        handleClose={handleClose}
        fetchUsers={fetchUsers}
        user={user}
        setUser={setUser}
        resetForm={resetForm}
        toggle={toggle}
        // Loading,
        loading={loading}
        setLoading={setLoading}
      />
      <Container>
        <Row>
          <Col md={12}>
            <BreadUtilizadores />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Utilizadores
            </h4>
          </Col>
        </Row>
        <CardBody>
          <Col md={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <Box>
                <b>Pesquisar</b>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <FiSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ ml: 2 }}>
                <Button
                  sx={{ border: '1px solid #000', minWidth: '45px', mt: 2.5 }}
                  onClick={(e) => handleOpen('adicionar', e)}
                >
                  <FiPlus size={25} strokeWidth={1.5} />
                </Button>
              </Box>
            </Box>
            <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Lista de Utilizadores
              </h4>
            </Box>
          </Col>
          <Col md={12}>
            <MUIDataTable
              description="Nada para mostrar"
              data={users}
              columns={columns}
              options={options}
            />
          </Col>
        </CardBody>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};

export default ListaUtilizadores;
