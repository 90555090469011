import Instance from '../../../utils/Axios';

const InfoObraTempo = (id, navigate) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get(`/obras/registartempos/${id}`)
      .then(({ data }) => {
        if (stateToken) {
          if (data && data.yData.length == 0) {
            navigate('/app/registarHoras/operacionais');
          } else {
            dispatch({ type: 'GET_INFO_OBRA', payload: data.yData[0] });
          }
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default InfoObraTempo;
