import Instance from '../../../../utils/Axios';

const AdicionarObservacaoFuncionarios = (ydata, show) => async (dispatch) => {
  await Instance()
    .post('/recursos/observacoes/ ', { ydata })
    .then(({ data }) => {
      dispatch({ type: 'POST_OBSERVACOES_FUNCIONARIOS', payload: data });
      show('success', 'Foi criada uma nova observação');
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        if (err.response.status === 500) {
          show('danger', `${err.response.data.message}`);
        }
      } else {
        show('warning', `Não poderam ser adicionadas novas observações`);
      }
    });
};

export default AdicionarObservacaoFuncionarios;
