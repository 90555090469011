import Instance from '../../../utils/Axios';

const RecursosAssociados = (id, date) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get(`/obras/registartempos/${id}/recursos/${date}`)
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_RECURSOS_ASSOCIADOS', payload: data.yData });
          dispatch({ type: 'STORE_OLD_STATE', payload: JSON.stringify(data.yData) });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default RecursosAssociados;
