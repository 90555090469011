import React from 'react';
// ACTIONS
// PACKAGES
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  CircularProgress,
  ThemeProvider,
  Box,
} from '@mui/material';
import { Col, Row, Container } from 'reactstrap';
import { grey } from '@mui/material/colors';
import { format } from 'date-fns';

import AdicionarObservacaoFuncionarios from '../../../../../../../redux/actions/FuncionariosActions/observacoes/adicionarObsAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  validateOnChange = true,
  errorsObservacao = null,
  funcId,
  show,
  defaultMaterialTheme,
  observacao,
  setObservacao,
  fetchFuncionarioObservacoes,
  validationObservacao,
  // Loading
  loading,
  setLoading,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // console.log(observacoes);

  const handleChangeObservacoes = (e) => {
    setObservacao({ ...observacao, obs: e.target.value });

    if (validateOnChange) validationObservacao({ obs: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = format(new Date(), 'yyyy-MM-dd');

    if (validationObservacao()) {
      setLoading(true);
      const ydata = [
        {
          funcionarioId: funcId,
          data,
          observacoes: observacao.obs,
        },
      ];

      console.log(ydata);

      dispatch(AdicionarObservacaoFuncionarios(ydata, show))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchFuncionarioObservacoes());

      // .then(() => handleOpenAlert('add'));
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('add', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <Fade in={openAdicionar}>
            <Box sx={style}>
              <div className="header-modal">
                <h3 id="transition-modal-title" className="text-underline-title-dark">
                  Adicionar Observação
                </h3>
                <p id="transition-modal-description">Pode adicionar aqui uma observação</p>
              </div>

              <Container style={{ padding: '0' }}>
                <Row>
                  <Col md={12} sm={12}>
                    <b>Observações</b>
                    <TextField
                      id="observacoes"
                      name="observacoes"
                      size="small"
                      color="primary"
                      onChange={handleChangeObservacoes}
                      fullWidth
                      autoComplete="off"
                      style={{ marginBottom: 8 }}
                      {...(errorsObservacao.obs && {
                        error: true,
                        helperText: errorsObservacao.obs,
                      })}
                    />
                  </Col>
                </Row>
              </Container>
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button
                  className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                  disabled={!!loading}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                  )}
                  Confirmar
                </Button>

                <Button
                  className={classes.btnCancelar}
                  variant="contained"
                  onClick={(e) => handleClose('add', e)}
                >
                  Cancelar
                </Button>
              </div>
            </Box>
          </Fade>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
