export const usersInitialState = {
  todosUsers: [],
  user: {},
  novoUser: [],
  userApagado: {},
  perfis: [],
  userEditado: {},
  recuperarSenha: [],
  dadosDefSenha: [],
  definirSenha: [],
  funcionarios: [],
  permissoes: [],
};
