/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { startOfMonth, format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import { Link } from 'react-router-dom';

import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
  InputAdornment,
  IconButton,
  Box,
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import {
  FiCheckSquare,
  FiXSquare,
  FiInfo,
  FiSearch,
  FiTrash2,
  FiCheck,
  FiEdit2,
  FiEdit,
} from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
// TABELA
import MUIDataTable from 'mui-datatables';

import FooterLinks from '../../../Account/Footer';
// Breadcrumbs
import BreadValidacaoAD from '../../../App/Breadcrumbs/ValidacaoAD/BreadValidacaoAD';

// ACTIONS

import GetTodasAsObrasValAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/Filtros/GetTodasAsObrasValAD';

import GetTodosFuncionariosValAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/Filtros/GetTodosFuncionariosValAD';
// eslint-disable-next-line max-len
import GetValidacaoAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/validacaoAdministracao';
import EnviarValidacaoIndividualAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/enviarValidacaoIndividualAD';
import EnviarValidacaoAD from '../../../../redux/actions/ValidacaoActions/validacaoAdmin/enviarValidacoesAD';
import TiposFaltas from '../../../../redux/actions/TiposFaltasAction/tiposFaltasDropdownAction';
import TiposPeriodos from '../../../../redux/actions/TiposPeriodosAction/tiposPeriodosDropdownAction';
import TiposAtrasos from '../../../../redux/actions/TiposAtrasosAction/tiposAtrasosDropdownAction';

import DadosFormulárioValidacao from '../../../../redux/actions/ValidacaoActions/filtroValidacoes/dadosFormularioAction';
import ModalDelete from './components/modalDelete';
import ModalValidate from './components/modalValidate';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.grey,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.grey,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  btnConsultar: {
    color: '#E21450',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  btnValidar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnObservacoes: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnObservacoesPreenchido: {
    color: '#b3f1ff',
    backgroundColor: '#b3f1ff',
    '&:hover': {
      backgroundColor: '#aae4f2',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#b3f1ff',
      },
    },
  },
  btnVoltar: {
    height: '30px',
    border: '1px solid #e6e6e6',
    color: '#8f8f8f',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  btnGuardar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnGuardarLoading: {
    color: 'white',
    backgroundColor: grey[300],
    width: '120px',
    height: '40px',
  },
}));
// const defaultMaterialTheme = createTheme({
//   palette: {
//     primary: { main: '#E21450' },
//   },
//   typography: {
//     fontFamily: ['roboto', 'sans-serif'].join(','),
//   },
//   overrides: {
//     MuiTableRow: {
//       root: {
//         '&.Mui-selected:hover': { backgroundColor: '#CCC' },

//         '& td[class*="MUIDataTableSelectCell-fixedLeft-"]': {
//           zIndex: '90 !important',
//         },
//       },
//     },
//     MuiTableHead: {
//       root: {
//         '& th[class*="MUIDataTableSelectCell-headerCell-"]': {
//           zIndex: '90 !important',
//         },
//         '& th[class*="MUIDataTableHeadCell-fixedHeader-"]': {
//           zIndex: '90 !important',
//         },
//       },
//     },
//     MUIDataTableFilter: {
//       root: { width: '500px' },
//     },
//   },
// });

const customStyles = (theme) => ({
  HoverRow: {
    // '& td': { backgroundColor: '#FFF' },
    '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  LinhaValidada: {
    '& td': { backgroundColor: 'rgb(255, 153, 145, 0.1)' },
    // '& tr.Mui-selected:hover': { backgroundColor: '#CCC' },
  },
  GreyLine: {
    '& td': { backgroundColor: theme.palette.grey[200] },
  },
  NameCell: {
    fontWeight: 900,
  },
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ValidacaoAD = (props) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [dataInicio, setDataInicio] = useState(startOfMonth(new Date()));
  const [dataFim, setDataFim] = useState(new Date());
  const [historico, setHistorico] = React.useState({
    historico: false,
  });
  const [validarBtn, setValidarBtn] = useState(false);

  const dispatch = useDispatch();

  const obrasValidacao = useSelector((state) => state.validacao.todasObrasValAd);
  const obraSelected = useSelector((state) => state.filters.obraValidacao);
  const funcionariosValidacao = useSelector((state) => state.validacao.todosFuncionariosValAd);
  const funcionarioSelected = useSelector((state) => state.filters.funcionarioValidacao);
  const [obra, setObra] = useState(obraSelected);
  const [funcionario, setFuncionario] = useState(funcionarioSelected);
  // STORE DATA
  const dados = useSelector((state) => state.validacao.validacaoAD);

  const handleChangeHoras = (horas, data) => {
    const yData = [
      {
        id: data[7],
        qtd: horas,
        tipoatrasosId: data[15],
        tipoperiodosId: data[16],
        tipofaltasId: data[17],
        faltou: data[4],
        observacoesRH: data[14],
        validadoRH: data[10],
        validadoAD: data[11],
        observacoesAD: data[13],
      },
    ];

    dispatch(EnviarValidacaoIndividualAD(yData));
  };
  // Dados Formulário
  const dadosFormValidacao = useSelector((state) => state.validacao.dadosFormularioValidacao);

  useEffect(() => {
    dispatch(TiposFaltas());
    dispatch(TiposPeriodos());
    dispatch(TiposAtrasos());
  }, []);

  // console.log(dados);
  const fetchObras = () => {
    dispatch(GetTodasAsObrasValAD());
  };
  const fetchFuncionarios = () => {
    dispatch(GetTodosFuncionariosValAD());
  };
  const clearTable = () => {
    dispatch({ type: 'GET_VALIDACAO_AD', payload: [] });
    setLinhasSelecionadas([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchObras(), []);
  useEffect(() => fetchFuncionarios(), []);
  useEffect(() => clearTable(), []);
  useEffect(() => {
    if (dadosFormValidacao && (dadosFormValidacao.dataInicio || dadosFormValidacao.dataFim)) {
      console.log('Datas da store');
      setDataInicio(dadosFormValidacao.dataInicio);
      setDataFim(dadosFormValidacao.dataFim);
    } else {
      console.log('Novas datas');
      setDataInicio(startOfMonth(new Date()));
      setDataFim(new Date());
      dispatch(DadosFormulárioValidacao(startOfMonth(new Date()), new Date()));
    }
  }, []);

  const handleDataInicioChange = (date) => {
    setDataInicio(date);
    dispatch(DadosFormulárioValidacao(date, dataFim));
  };

  const handleDataFimChange = (date) => {
    setDataFim(date);
    dispatch(DadosFormulárioValidacao(dataInicio, date));
  };

  const handleChangeObra = (e, value) => {
    e.preventDefault();

    setObra(value);
  };
  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    setFuncionario(value);
  };
  const handleChangeHistorico = (event) => {
    setHistorico({ ...historico, [event.target.name]: event.target.checked });
  };

  // ------------------ MODAL DELETE -------------------- //

  const [openDelete, setOpenDelete] = useState(false);
  const [vid, setVid] = useState();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenDelete(true);
    setVid(id);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenDelete(false);
    setVid(null);
  };

  // ---------------------------------------------------- //

  // ------------------ MODAL VALIDATE -------------------- //

  const [openValidate, setOpenValidate] = useState(false);

  const handleOpenValidate = (e, id) => {
    e.preventDefault();

    setLoading(false);

    setOpenValidate(true);
    setVid(id);
  };

  const handleCloseValidate = (e) => {
    e.preventDefault();
    setOpenValidate(false);
    setVid(null);
  };

  // ---------------------------------------------------- //

  // ------------------------ Snackbar----------------------------  //

  const [open, setOpen] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // ------------------------------------------------------------- //

  // ------------------ EXPANDABLE ROW ------------------ //
  const tiposfaltas = useSelector((state) => state.tiposFaltas.dropdownTiposFaltas);
  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);
  const infoValIndiv = useSelector((state) => state.validacao.validacaoIndividualAD);
  const obraId = obra && obra.id;
  const funcionarioId = funcionario && funcionario.id;
  const historicoBool = () => {
    return historico.historico == true ? 1 : 0;
  };
  const [checkbox, setCheckbox] = useState({});

  const handleSubmitExRow = (yData) => {
    const data = [];
    data.push(yData);
    const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
    const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');

    dispatch(EnviarValidacaoIndividualAD(data)).then(() => {
      dispatch(
        GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
      );
    });
  };

  // const handleChangeObservacoes = (value, yData) => {
  //   let data = yData;
  //   const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
  //   const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
  //   data = [
  //     {
  //       ...data,
  //       observacoesAD: value,
  //     },
  //   ];

  //   dispatch(EnviarValidacaoIndividualAD(data)).then(() =>
  //     dispatch(
  //       GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
  //     ),
  //   );
  // };

  // const handleChangeFalta = (value, yData, rowData) => {
  //   let data = yData;
  //   const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
  //   const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
  //   if (value.id == 3 && rowData[20] <= 0) {
  //     setOpen(true);
  //   } else {
  //     if (value) {
  //       data = [
  //         {
  //           ...data,
  //           tipofaltasId: value.id,
  //         },
  //       ];
  //     } else {
  //       data = [{ ...data, tipofaltasId: null }];
  //     }
  //     dispatch(EnviarValidacaoIndividualAD(data)).then(() =>
  //       dispatch(
  //         GetValidacaoAD(
  //           selectedDataInicio,
  //           selectedDataFim,
  //           historicoBool(),
  //           obraId,
  //           funcionarioId,
  //         ),
  //       ),
  //     );
  //   }
  // };

  // const handleFaltas = (yData, falta, index) => {
  //   let data = yData;
  //   const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
  //   const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
  //   setCheckbox({ ...checkbox, [index]: falta });

  //   data = [
  //     {
  //       ...data,
  //       faltou: falta,
  //     },
  //   ];

  //   dispatch(EnviarValidacaoIndividualAD(data)).then(() =>
  //     dispatch(
  //       GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
  //     ),
  //   );
  // };

  // const handleChangeAtrasos = (value, yData) => {
  //   let data = yData;
  //   const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
  //   const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
  //   if (value) {
  //     data = [
  //       {
  //         ...yData,
  //         tipoatrasosId: value.id,
  //       },
  //     ];
  //   } else {
  //     data = [
  //       {
  //         ...yData,
  //         tipoatrasosId: null,
  //       },
  //     ];
  //   }
  //   dispatch(EnviarValidacaoIndividualAD(data)).then(() =>
  //     dispatch(
  //       GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
  //     ),
  //   );
  // };

  // const handleChangePeriodo = (value, yData) => {
  //   let data = yData;
  //   const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
  //   const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
  //   if (value) {
  //     data = [
  //       {
  //         ...data,
  //         tipoperiodosId: value.id,
  //       },
  //     ];
  //   } else {
  //     data = [
  //       {
  //         ...data,
  //         tipoperiodosId: null,
  //       },
  //     ];
  //   }
  //   dispatch(EnviarValidacaoIndividualAD(data)).then(() =>
  //     dispatch(
  //       GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
  //     ),
  //   );
  // };
  // ---------------------------------------------------- //

  const columns = [
    {
      name: 'descricaoObra',
      label: 'Obra',
      options: {
        filter: true,
        // sort: false,
        empty: true,

        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-categorias">
              {`${tableMeta.rowData[8]} - ${tableMeta.rowData[0]}`}
            </div>
          );
        },
      },
    },
    {
      name: 'recursos_nome2',
      label: 'Funcionário',
      options: {
        filter: false,
      },
    },

    {
      name: 'data',
      label: 'Data',
      options: {
        filter: false,
        // sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataFormat = format(new Date(tableMeta.rowData[2]), 'dd/MM/yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              <p>{dataFormat}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'qtd',
      label: 'Horas',
      options: {
        filter: false,
        // sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TextField
              type="number"
              size="small"
              variant="outlined"
              inputProps={{ min: 0, style: { textAlign: 'center', marginTop: 13 } }}
              name="qtd"
              value={value}
              onChange={(e) => {
                updateValue(e.target.value);
                handleChangeHoras(e.target.value, tableMeta.rowData);
              }}
              color="primary"
              sx={{
                width: '85px',
                mt: -1.5,
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          );
        },
      },
    },
    {
      name: 'faltou',
      label: 'Presença',
      options: {
        filter: false,
        // sort: false,
        empty: true,

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              {tableMeta.rowData[4] == 0 ? (
                <div
                  style={{
                    width: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiCheckSquare size={30} strokeWidth={1} color="#43B935" />
                </div>
              ) : (
                <div
                  style={{
                    width: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiXSquare size={30} strokeWidth={1} color="#EE4231" />
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'descricaoTipoPeriodo',
      label: 'Periodo',
      options: {
        filter: false,
      },
    },
    {
      name: 'descricaoTipoAtraso',
      label: 'Motivo Atraso',
      options: {
        filter: false,
      },
    },

    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'obras_id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'validadoCD',
      label: 'Val. CD',
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        filterList: '',
        setCellProps: () => ({
          align: 'center',
        }),

        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              {tableMeta.rowData[9] == 1 ? (
                <div
                  style={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiCheckSquare size={30} strokeWidth={1} color="#43B935" />
                </div>
              ) : (
                <div
                  style={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiXSquare size={30} strokeWidth={1} color="#EE4231" />
                </div>
              )}
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] === 1) {
              return 'Validado CD';
            }
            if (v[0] === 0) {
              return 'Não Validado CD';
            }
            return v[0];
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          // eslint-disable-next-line no-unused-vars
          logic: (location, filters, row) => {
            if (filters.length) {
              if (filters[0] !== 'Tudo') return !filters.includes(location);
            }

            return false;
          },

          display: (filterList, onChange, index, column) => {
            const filterDados = [
              {
                id: null,
                nome: '',
                value: '',
              },
              {
                id: 1,
                nome: 'Tudo',
                value: 'Tudo',
              },
              {
                id: 2,
                nome: 'Validado',
                value: 1,
              },
              {
                id: 3,
                nome: 'Não validado',
                value: 0,
              },
            ];
            let val = filterDados.filter((x) => x.value === filterList[index][0])[0];
            if (!val) {
              val = {
                id: null,
                nome: '',
                value: '',
              };
            }
            return (
              <>
                <Autocomplete
                  name="Validação CD"
                  noOptionsText="Sem dados"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  value={val || ''}
                  onChange={(event, value) => {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      filterList[index][0] = value.value;
                      onChange(filterList[index], index, column);
                    }
                  }}
                  options={filterDados}
                  getOptionLabel={(option) => (option.id ? `${option.nome}` : '')}
                  getOptionSelected={(option, value) => {
                    return value && value.id === option.id;
                  }}
                  filterOptions={(options, state) => {
                    return options.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.nome}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  id="auto-complete"
                  autoComplete
                  includeInputInList
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Validação CD" margin="normal" />
                  )}
                />
              </>
            );
          },
        },
      },
    },
    {
      name: 'validadoRH',
      label: 'Val. RH',
      options: {
        filter: true,
        sort: true,
        empty: true,
        // display: false,
        setCellProps: () => ({
          align: 'right',
        }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
            >
              {tableMeta.rowData[10] == 1 ? (
                <div
                  style={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiCheckSquare size={30} strokeWidth={1} color="#43B935" />
                </div>
              ) : (
                <div
                  style={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FiXSquare size={30} strokeWidth={1} color="#EE4231" />
                </div>
              )}
            </div>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => {
            if (v[0] === 1) {
              return 'Validado RH';
            }
            if (v[0] === 0) {
              return 'Não Validado RH';
            }
            return v[0];
          },
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          // eslint-disable-next-line no-unused-vars
          logic: (location, filters, row) => {
            if (filters.length) {
              if (filters[0] !== 'Tudo') return !filters.includes(location);
            }

            return false;
          },

          display: (filterList, onChange, index, column) => {
            const filterDados = [
              {
                id: null,
                nome: '',
                value: '',
              },
              {
                id: 1,
                nome: 'Tudo',
                value: 'Tudo',
              },
              {
                id: 2,
                nome: 'Validado',
                value: 1,
              },
              {
                id: 3,
                nome: 'Não validado',
                value: 0,
              },
            ];
            let val = filterDados.filter((x) => x.value === filterList[index][0])[0];
            if (!val) {
              val = {
                id: null,
                nome: '',
                value: '',
              };
            }
            return (
              <>
                <Autocomplete
                  name="Validação CD"
                  noOptionsText="Sem dados"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  value={val || ''}
                  onChange={(event, value) => {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      filterList[index][0] = value.value;
                      onChange(filterList[index], index, column);
                    }
                  }}
                  options={filterDados}
                  getOptionLabel={(option) => (option.id ? `${option.nome}` : '')}
                  getOptionSelected={(option, value) => {
                    return value && value.id === option.id;
                  }}
                  filterOptions={(options, state) => {
                    return options.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.nome}`.toLowerCase().includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  id="auto-complete"
                  autoComplete
                  includeInputInList
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Validação RH" margin="normal" />
                  )}
                />
              </>
            );
          },
        },
      },
    },
    {
      name: 'validadoAD',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: false,
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    // {
    //   name: '',
    //   label: 'Informações',
    //   options: {
    //     filter: false,
    //     // sort: false,
    //     empty: true,

    //     // eslint-disable-next-line no-unused-vars
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div
    //           className="table-btns-categorias"
    //           style={{ display: 'flex', justifyContent: 'flex-start', width: '100px' }}
    //         >
    //           <Link to={`/app/validacao/administracao/${tableMeta.rowData[7]}`}>
    //             <Button>
    //               <FiInfo size={30} color="#000" strokeWidth={1} />
    //             </Button>
    //           </Link>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'center',
          margin: 0,
          padding: 0,
        }),
        customBodyRender: (value, tableMeta) => {
          const o = tableMeta.rowData[7];

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div
                className="table-btns-categorias"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* <Link to={`/app/validacao/administracao/${tableMeta.rowData[7]}`}>
                  <LightTooltip title="Editar" placement="top">
                    <IconButton>
                      <FiEdit size={30} strokeWidth={1} color="#000" />
                    </IconButton>
                  </LightTooltip>
                </Link> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LightTooltip title="Validar" placement="top">
                  <IconButton
                    onClick={(e) => {
                      setLinhasSelecionadas([{ id: o }]);
                      handleOpenValidate(e, o);
                    }}
                  >
                    <FiCheck size={30} strokeWidth={1} color="#000" />
                  </IconButton>
                </LightTooltip>
                {/* <span style={{ marginLeft: '6px' }}>
                    <b>Não</b>
                  </span> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LightTooltip title="Eliminar" placement="top">
                  <IconButton onClick={(e) => handleOpen(e, o)}>
                    <FiTrash2 size={30} strokeWidth={1} color="#000" />
                  </IconButton>
                </LightTooltip>
                {/* <span style={{ marginLeft: '6px' }}>
                    <b>Não</b>
                  </span> */}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'observacoesAD',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'observacoesRH',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'tipoatrasos_id',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'tipoperiodos_id',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'tipofaltas_id',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'descricaoTipoFalta',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'faltou',
      label: ' ',
      options: {
        display: false,
      },
    },
    {
      name: 'recursos_dias_folga_totais_dias',
      label: ' ',
      options: {
        display: false,
      },
    },
  ];

  const tablePage = useSelector((state) => state.global.valADTablePage);
  const searchTextValue = useSelector((state) => state.global.valADTableSearch);
  const [searchText, setSearchText] = useState(searchTextValue);

  const options = {
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'multiple',
    search: false,
    searchText: searchText,
    print: false,
    download: false,
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 150, 200],
    page: tablePage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        dispatch({ type: 'VAL_AD_TABLE_PAGINATION', payload: tableState.page });
      }
      if (action === 'propsUpdate') {
        dispatch({
          type: 'VAL_AD_TABLE_SEARCH',
          payload: tableState.searchText,
        });
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;

      const index = rowMeta.dataIndex;
      const isChecked = checkbox[index];

      let yData = {
        id: rowData[7],
        qtd: rowData[3].props.value,
        tipoatrasosId: rowData[15],
        tipoperiodosId: rowData[16],
        tipofaltasId: rowData[17],
        observacoesAD: rowData[13],
        faltou: isChecked,
      };

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Box sx={{ display: 'flex', m: 1, justifyContent: 'flex-end', alignContent: 'center' }}>
              <Box
                sx={{
                  width: '10%',
                  height: '40px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={() => {
                        setCheckbox({ ...checkbox, [index]: !isChecked });
                        yData = {
                          ...yData,
                          faltou: !isChecked,
                        };
                      }}
                      name="falta"
                    />
                  }
                  label="Falta"
                  // style={{ width: '20%' }}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '20%', mr: 2 }}>
                <b>Tipo de Falta</b>
                <Autocomplete
                  id="tipoFaltaId"
                  name="tipoFaltaId"
                  noOptionsText="Sem dados"
                  onChange={(e, value, reason) => {
                    if (reason == 'clear') {
                      yData = {
                        ...yData,
                        tipofaltasId: null,
                      };
                    } else {
                      yData = {
                        ...yData,
                        tipofaltasId: value.id,
                      };
                    }
                  }}
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  disabled={!isChecked}
                  defaultValue={{ id: rowData[17], descricao: rowData[18] }}
                  options={tiposfaltas || [{ id: 0, descricao: '' }]}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                  filterOptions={(opt, state) => {
                    return opt.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.descricao}`
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  // getOptionSelected={(option, value) => {
                  //   // if (!value.id) return true;

                  //   return value.id === option.id;
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return value.id === option.id;
                  }}
                  autoComplete
                  includeInputInList
                  size="small"
                  sx={{ width: '100%', borderCollapse: 'collapse' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"

                      // required
                      // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                    />
                  )}
                />
              </Box>

              <Box sx={{ display: 'flex', width: '20%', mr: 2 }}>
                <b>Tipo de Periodo</b>
                <Autocomplete
                  id="tipoPeriodoId"
                  name="tipoPeriodoId"
                  noOptionsText="Sem dados"
                  onChange={(e, value, reason) => {
                    if (reason == 'clear') {
                      yData = {
                        ...yData,
                        tipoperiodosId: null,
                      };
                    } else {
                      yData = {
                        ...yData,
                        tipoperiodosId: value.id,
                      };
                    }
                  }}
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  defaultValue={{ id: rowData[15], descricao: rowData[5] }}
                  options={tiposperiodos || [{ id: 0, descricao: '' }]}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                  filterOptions={(opt, state) => {
                    return opt.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.descricao}`
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  // getOptionSelected={(option, value) => {
                  //   // if (!value.id) return true;

                  //   return value.id === option.id;
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return value.id === option.id;
                  }}
                  autoComplete
                  includeInputInList
                  size="small"
                  sx={{ width: '100%', borderCollapse: 'collapse' }}
                  // disabled={infoValIndiv.validadoAD}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"

                      // required
                      // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '20%', mr: 2 }}>
                <b>Tipo de Atraso</b>
                <Autocomplete
                  id="tipoAtrasoId"
                  name="tipoAtrasoId"
                  noOptionsText="Sem dados"
                  onChange={(e, value, reason) => {
                    if (reason == 'clear') {
                      yData = {
                        ...yData,
                        tipoatrasosId: null,
                      };
                    } else {
                      yData = {
                        ...yData,
                        tipoatrasosId: value.id,
                      };
                    }
                  }}
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  defaultValue={{ id: rowData[14], descricao: rowData[6] }}
                  options={tiposatrasos || [{ id: 0, descricao: '' }]}
                  getOptionLabel={(option) => (option.descricao ? `${option.descricao} ` : '')}
                  filterOptions={(opt, state) => {
                    return opt.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.descricao}`
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  // getOptionSelected={(option, value) => {
                  //   // if (!value.id) return true

                  //   return value.id === option.id;
                  // }}
                  isOptionEqualToValue={(option, value) => {
                    return value.id === option.id;
                  }}
                  autoComplete
                  includeInputInList
                  size="small"
                  sx={{ width: '100%', borderCollapse: 'collapse' }}
                  disabled={infoValIndiv.validadoAD}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"

                      // required
                      // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '40%', mr: 2 }}>
                <b>Obs. Admin</b>
                <TextField
                  name="observacoes"
                  variant="outlined"
                  sx={{ width: '100%', borderCollapse: 'collapse' }}
                  size="small"
                  inputProps={{
                    onBlur: (e) => {
                      yData = {
                        ...yData,
                        observacoesAD: e.target.value,
                      };
                    },
                  }}
                  defaultValue={rowData[13]}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
                <Button
                  className={loading ? classes.btnGuardarLoading : classes.btnGuardar}
                  onClick={() => handleSubmitExRow(yData)}
                  size="small"
                  variant="contained"
                  style={{ float: 'right' }}
                  disabled={!!loading}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                  )}
                  Guardar
                </Button>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line no-unused-vars
    setRowProps: (row, dataIndex, rowIndex) => {
      const proprities = props;
      return {
        className: clsx({
          [proprities.classes.LinhaValidada]: row[11] == 1,
          [proprities.classes.HoverRow]: row[1].length > 0,
        }),
      };
    },

    // selectableRowsOnClick: true,
    // eslint-disable-next-line no-unused-vars
    isRowSelectable: (dataIndex, selectedRows, data) => {
      // prevents selection of row with title "Attorney

      return (
        dados[dataIndex].existeAusencia == 1 ||
        dados[dataIndex].faltou == 0 ||
        (dados[dataIndex].tipofaltas_id != null && dados[dataIndex].validadoAD == 0)
      );
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected.length >= 0) {
        // const aux = rowsSelected.map((index) => dados[index].id);
        const linhaInfo = rowsSelected.map((index) => dados[index]);

        setLinhasSelecionadas(linhaInfo);
      }
    },

    // eslint-disable-next-line no-unused-vars
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //   <div style={{ padding: '24px' }} />
    // ),
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
      },
    },
  };

  // ------------------------- CONSULTAR E SUBMIT --------------------------//

  const handleConsultar = (e) => {
    e.preventDefault();
    const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
    const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
    dispatch(
      GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
    );
  };
  const handleSubmit = () => {
    // e.preventDefault();

    let yData = linhasSelecionadas.map((info) => {
      const infoLinha = {
        id: info.id,
      };
      return Object.values(infoLinha);
    });

    yData = yData.flat();

    dispatch(EnviarValidacaoAD(yData, setValidarBtn)).then(() => setValidarBtn(true));
  };
  // Faz o fetch dos dados quando abre a página
  useEffect(() => setValidarBtn(true), []);

  useEffect(() => {
    const initialCheckboxState = {};
    dados.forEach((row, index) => {
      initialCheckboxState[index] = row.faltou;
    });
    setCheckbox(initialCheckboxState);
  }, [dados]);

  useEffect(() => {
    if (validarBtn) {
      const selectedDataInicio = format(new Date(dataInicio), 'yyyy-MM-dd');
      const selectedDataFim = format(new Date(dataFim), 'yyyy-MM-dd');
      dispatch(
        GetValidacaoAD(selectedDataInicio, selectedDataFim, historicoBool(), obraId, funcionarioId),
      );
      clearTable();
      setValidarBtn(false);
    }
  }, [validarBtn]);

  return (
    <>
      <Container>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ mt: 6 }}
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
            Funcionário já não possui dias de férias!
          </Alert>
        </Snackbar>
        <ModalDelete
          openDelete={openDelete}
          handleClose={handleClose}
          vid={vid}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
          setValidarBtn={setValidarBtn}
        />
        <ModalValidate
          openValidate={openValidate}
          handleCloseValidate={handleCloseValidate}
          vid={vid}
          // show={show}
          // Loading
          loading={loading}
          setLoading={setLoading}
          setValidarBtn={setValidarBtn}
          linhasSelecionadas={linhasSelecionadas}
          setOpen={setOpen}
        />
        <Row>
          <Col md={12}>
            <BreadValidacaoAD />
            <h4
              style={{
                fontSize: '22px',
                width: '100%',
                marginTop: 20,
                marginBottom: 20,
              }}
              className="text-underline"
            >
              Validação Administração
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Col md={12} sm={12}>
                  {/* <Container> */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: width < 1100 ? 'column' : 'row',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: width < 1100 ? '100%' : '30%',
                        mr: '2%',
                      }}
                    >
                      <Box
                        sx={{
                          mr: '2%',
                          mb: width < 1100 ? 2 : 0,
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                          <b>Data Inicio</b>
                          <DatePicker
                            style={{ width: '100%' }}
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            value={dataInicio}
                            onChange={handleDataInicioChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'dd/mm/aaaa',
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box sx={{ mb: width < 1100 ? 2 : 0, float: 'right' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                          <b>Data Fim</b>
                          <DatePicker
                            style={{ width: '100%' }}
                            margin="normal"
                            id="date-picker-dialog"
                            format="dd/MM/yyyy"
                            okLabel="ok"
                            cancelLabel="cancelar"
                            invalidDateMessage="Data Inválida"
                            value={dataFim}
                            onChange={handleDataFimChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'dd/mm/aaaa',
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: width < 1100 ? '100%' : '20%',
                        mr: '2%',
                        mb: width < 1100 ? 2 : 0,
                      }}
                    >
                      <b>Obra</b>
                      <Autocomplete
                        name="obra"
                        noOptionsText="Sem dados"
                        clearText="Limpar"
                        size="small"
                        openText="Abrir"
                        sx={{ mt: -2 }}
                        closeText="Fechar"
                        value={obraSelected}
                        options={obrasValidacao || []}
                        getOptionLabel={(option) =>
                          `${option.recursos_id || option.id} - ${option.descricao} `
                        }
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        id="auto-complete"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            // required={user.recursosId.length === 0}
                            // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                          />
                        )}
                        onChange={(e, value) => {
                          handleChangeObra(e, value);
                          dispatch({
                            type: 'OBRA_VALIDACAO',
                            payload: value,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: width < 1100 ? '100%' : '20%',
                        mr: '2%',
                        mb: width < 1100 ? 2 : 0,
                      }}
                    >
                      <b>Funcionário</b>
                      <Autocomplete
                        name="funcionario"
                        noOptionsText="Sem dados"
                        clearText="Limpar"
                        size="small"
                        openText="Abrir"
                        closeText="Fechar"
                        sx={{ mt: -2 }}
                        value={funcionarioSelected}
                        options={funcionariosValidacao}
                        getOptionLabel={(option) =>
                          `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
                        }
                        isOptionEqualToValue={(option, value) => {
                          // if (!value.id) return true;
                          return value.id === option.id;
                        }}
                        id="auto-complete"
                        // autoComplete
                        // includeInputInList
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            // required={user.recursosId.length === 0}
                            // {...(errors.recursosId && { error: true, helperText: errors.recursosId })}
                          />
                        )}
                        onChange={(e, value) => {
                          handleChangeFuncionario(e, value);
                          dispatch({
                            type: 'FUNCIONARIO_VALIDACAO',
                            payload: value,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: width < 1100 ? '100px' : '7%',
                        mr: '2%',
                        mb: width < 1100 ? 2 : 0,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleConsultar}
                        sx={{ width: '100%', mt: width < 1100 ? 0 : 2.5 }}
                        className={classes.btnConsultar}
                      >
                        Filtrar
                      </Button>
                    </Box>
                    <Box sx={{ width: width < 1100 ? '100%' : '23%', mb: width < 1100 ? 2 : 0 }}>
                      <b>Pesquisar</b>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="search"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <FiSearch />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Row style={{ marginTop: '10px' }}>
                    <Col>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={historico.historico}
                              onChange={handleChangeHistorico}
                              name="historico"
                            />
                          }
                          label="Histórico"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* </Container> */}
                </Col>

                {/* <Container> */}
                <Col md={12} sm={12}>
                  <Row>
                    <Col>
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Consulta
                        </h4>
                      </Box>
                      <MUIDataTable
                        description="Nada para mostrar"
                        data={dados}
                        columns={columns}
                        options={options}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '10px', textAlign: 'right' }}>
                    <Col>
                      {/* {console.log('linhasSelecionadas', linhasSelecionadas)} */}
                      {linhasSelecionadas.length > 0 ? (
                        <Button
                          size="sm"
                          className={classes.btnValidar}
                          variant="contained"
                          onClick={handleSubmit}
                        >
                          Validar{' '}
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          className={classes.btnValidar}
                          variant="contained"
                          disabled
                        >
                          Validar{' '}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
                {/* </Container> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="bottom">
        <div style={{ padding: '20px 40px' }}>
          <FooterLinks />
        </div>
      </div>
    </>
  );
};
export default withStyles(customStyles)(ValidacaoAD);
