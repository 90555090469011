/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import User from '../../../../../../redux/actions/UsersActions/UserAction';
import ApagarUser from '../../../../../../redux/actions/UsersActions/ApagarUserAction';

// MODALS
import ModalDelete from './modals/modalDelete';
import ModalRedefinirSenha from './modals/modalRedefinirSenha';
import ModalEditarUtilizador from './modals/modalEditarUtilizador';

const CardUtilizador = ({ fetchUtilizador, show, classes }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split('/')[4];
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSenha, setOpenSenha] = useState(false);

  const ydata = useSelector((state) => state.users.user);

  // ------ User -> Card Edit user --------//
  const [user, setUser] = useState({
    nome: '',
    // email: '',
    username: '',
    perfisId: '',
    recursosId: '',
    recursosIdAutoCompleteChange: null,
  });

  const recursos = useSelector((state) => state.users.funcionarios);

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setUser({
      nome: '',
      // email: info.username,
      username: '',
      perfisId: '',
      recursosId: '',
      recursosIdAutoCompleteChange: '',
    });
  };
  const toggle = () => {
    resetForm();

    const funcionarioSelecionado = recursos.find((func) => func.id === ydata.recursos_id);

    setUser({
      nome: ydata.nome,
      // email: ydata.username,
      username: ydata.username,
      perfisId: ydata.perfis_id,
      recursosId: funcionarioSelecionado ? funcionarioSelecionado.id : '',
      recursosIdAutoCompleteChange: funcionarioSelecionado,
    });
  };
  // -------------------------------------//

  const fetchInfoUser = () => {
    dispatch(User(id, navigate));
  };

  useEffect(() => fetchInfoUser(), []);
  useEffect(() => {
    // r = recursos && recursos.find((func) => func.id === ydata.recursos_id);
  }, [ydata]);

  const handleDelete = () => {
    dispatch(ApagarUser(id)).then(() => navigate('/app/administracao/utilizadores/'));
  };

  const handleOpen = (btn, e) => {
    e.preventDefault();
    setLoading(false);
    if (btn == 'edit') {
      setOpenEdit(true);
      toggle();
    } else if (btn == 'delete') {
      setOpenDelete(true);
    } else if (btn == 'senha') {
      setOpenSenha(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn == 'edit') {
      setOpenEdit(false);
    } else if (btn == 'delete') {
      setOpenDelete(false);
    } else if (btn == 'senha') {
      setOpenSenha(false);
    }
  };

  return (
    <>
      {ydata === undefined ? (
        fetchInfoUser()
      ) : (
        <>
          <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
            <h4
              style={{
                width: '100%',
              }}
              className="text-underline-title"
            >
              Utilizador
            </h4>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
              <b>Nome Completo:</b>
              <TextField variant="outlined" value={ydata.nome} size="small" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '23%', mr: '2%' }}>
              <b>Utilizador:</b>
              <TextField variant="outlined" value={ydata.username} size="small" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '24%', mr: '1%' }}>
              <b>Perfil:</b>
              <TextField variant="outlined" value={ydata.perfis_descricao} size="small" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '24%', ml: '1%' }}>
              <b>Funcionário associado:</b>
              <TextField variant="outlined" value={ydata.recurso_descricao} size="small" />
            </Box>
          </Box>

          <Box sx={{ my: 3 }}>
            <Box sx={{ display: 'flex' }}>
              <Button
                onClick={(e) => {
                  handleOpen('delete', e);
                }}
                className={classes.btnGlobal}
                sx={{ mr: 2 }}
              >
                Apagar Utilizador
              </Button>
              <Button
                onClick={(e) => {
                  handleOpen('senha', e);
                }}
                className={classes.btnGlobal}
                sx={{ mr: 2 }}
              >
                Redefinir senha
              </Button>
              <Button
                onClick={(e) => {
                  handleOpen('edit', e);
                }}
                className={classes.btnGlobal}
                sx={{ mr: 2 }}
              >
                Editar Utilizador
              </Button>
              <ModalDelete
                openDelete={openDelete}
                handleClose={handleClose}
                handleDelete={handleDelete}
                info={ydata}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <ModalRedefinirSenha
                openSenha={openSenha}
                handleClose={handleClose}
                handleDelete={handleDelete}
                info={ydata}
                fetchUtilizador={fetchUtilizador}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
              <ModalEditarUtilizador
                openEdit={openEdit}
                handleClose={handleClose}
                handleDelete={handleDelete}
                info={ydata}
                fetchUtilizador={fetchUtilizador}
                user={user}
                setUser={setUser}
                resetForm={resetForm}
                recursos={recursos}
                toggle={toggle}
                show={show}
                // Loading
                loading={loading}
                setLoading={setLoading}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CardUtilizador;
