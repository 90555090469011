/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// ACTIONS
// PACKAGES
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Box,
} from '@mui/material';

import { format } from 'date-fns';
import RegistoDiario from './registoDiario';
import RegistoPeriodo from './registoPeriodo';
import AdicionarRegistoEscritorio from '../../../../../../../../redux/actions/TemposActions/Escritorio/Adicionar/AdicionarRegistoEscritorioAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const ModalAdicionar = ({
  openAdicionar,
  handleClose,
  validateOnChange = true,
  funcionariosEscritorio,
  novoRegisto,
  setNovoRegisto,
  useWindowDimensions,
  validationNovoRegistoDiario,
  validationNovoRegistoPeriodo,
  errorsNovoRegistoDiario,
  errorsNovoRegistoPeriodo,
  show,
  classes,
  horas,
  setHoras,
  handleConsultar,
  valueTipoAtrasoAutoComplete,
  setValueTipoAtrasoAutoComplete,
  valueTipoPeriodoAutoComplete,
  setValueTipoPeriodoAutoComplete,
  // loading button
  loading,
  setLoading,
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const tiposperiodos = useSelector((state) => state.tiposPeriodos.dropdownTiposPeriodos);
  const tiposatrasos = useSelector((state) => state.tiposAtrasos.dropdownTiposAtrasos);

  useEffect(() => {
    setNovoRegisto({
      ...novoRegisto,
      dataInicioDiario: new Date(),
    });
  }, []);

  useEffect(() => {
    setHoras(novoRegisto.qtd);
    const tipoAtrasoSelected = tiposatrasos.find(
      (atraso) => atraso.id === novoRegisto.tipoatrasos_id,
    );
    const tipoPeriodoSelected = tiposperiodos.find(
      (periodo) => periodo.id === novoRegisto.tipoperiodos_id,
    );

    if (tipoAtrasoSelected) {
      setValueTipoAtrasoAutoComplete(tipoAtrasoSelected);
    }
    if (tipoPeriodoSelected) {
      setValueTipoPeriodoAutoComplete(tipoPeriodoSelected);
    }
  }, [novoRegisto]);

  const handleChangeTipo = (value) => {
    const tipo = value === 'diario' ? 1 : 2;
    setNovoRegisto({
      ...novoRegisto,
      tipo,
    });
  };

  const handleChangeFuncionario = (e, value) => {
    e.preventDefault();

    if (value) {
      setNovoRegisto({
        ...novoRegisto,
        funcionarioId: value && value.id,
        // qtdPeriodo: value && value.horasMensais,
      });
    } else {
      setNovoRegisto({
        ...novoRegisto,
        funcionarioId: 0,
      });
    }

    if (novoRegisto && novoRegisto.tipo === 1) {
      if (validateOnChange) validationNovoRegistoDiario({ funcionarioId: value && value.id });
    } else if (novoRegisto && novoRegisto.tipo === 2) {
      if (validateOnChange) validationNovoRegistoPeriodo({ funcionarioId: value && value.id });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let ydata = [];
    if (novoRegisto && novoRegisto.tipo === 1) {
      if (validationNovoRegistoDiario()) {
        setLoading(true);
        const formatedDataInicioDiario = format(
          novoRegisto && novoRegisto.dataInicioDiario,
          'yyyy-MM-dd',
        );
        ydata = [
          {
            tipo: novoRegisto.tipo,
            obraId: novoRegisto.obraId,
            funcionarioId: novoRegisto.funcionarioId,
            data: formatedDataInicioDiario,
            qtd: novoRegisto.qtdDiario,
            tipoAtrasosId: novoRegisto.tipoAtrasoId.id,
            faltou: novoRegisto.faltou,
            tipoPeriodosId: novoRegisto.tipoPeriodoId.id,
            observacoes: novoRegisto.observacoes,
          },
        ];
        console.log('objeto enviado!', ydata);
        console.log('novoRegisto', novoRegisto);
        dispatch(AdicionarRegistoEscritorio(ydata, show))
          .then(() => handleConsultar(e))
          .then(() => handleClose('adicionar', e));
      } else {
        console.log('XXX Diário');
      }
    }
    if (novoRegisto && novoRegisto.tipo === 2) {
      if (validationNovoRegistoPeriodo()) {
        setLoading(true);
        const formatedDataInicioPeriodo =
          novoRegisto && format(novoRegisto.dataInicioPeriodo, 'yyyy-MM-dd');
        const formatedDataFim = novoRegisto && format(novoRegisto.dataFim, 'yyyy-MM-dd');
        ydata = [
          {
            tipo: novoRegisto.tipo,
            obraId: novoRegisto.obraId,
            funcionarioId: novoRegisto.funcionarioId,
            dataInicio: formatedDataInicioPeriodo,
            dataFim: formatedDataFim,
            qtd: novoRegisto.qtdPeriodo,
            tipoAtrasosId: novoRegisto.tipoAtrasoId,
            faltou: novoRegisto.faltou,
            tipoPeriodosId: novoRegisto.tipoPeriodoId,
            observacoes: novoRegisto.observacoes,
          },
        ];
        console.log(ydata);
        dispatch(AdicionarRegistoEscritorio(ydata, show))
          .then(() => handleConsultar(e))
          .then(() => handleClose('adicionar', e));
      } else {
        console.log('XXX Periodo');
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <Box sx={style}>
            <Col md={12}>
              <div className="header-modal">
                <h3 id="transition-modal-title" className="text-underline-title-dark">
                  Adicionar Novo Registo
                </h3>
                <p id="transition-modal-description">
                  Pode adicionar aqui um novo registo de horas.
                </p>
              </div>

              <div>
                <b>Funcionário</b>
                <Autocomplete
                  name="funcionario"
                  noOptionsText="Sem dados"
                  size="small"
                  clearText="Limpar"
                  openText="Abrir"
                  closeText="Fechar"
                  onChange={(e, value) => handleChangeFuncionario(e, value)}
                  options={
                    funcionariosEscritorio || [
                      {
                        ativo: 0,
                        categoriaId: '0',
                        categoriaNome: '',
                        horasMensais: 0,
                        id: '0',
                        nome: '',
                        nome2: '',
                        operacional: 0,
                        tipo: 0,
                        valorHora: 0,
                        valorMensal: 0,
                      },
                    ]
                  }
                  getOptionLabel={(option) =>
                    `${option.recursos_id || option.id} - ${option.nome} - ${option.nome2}`
                  }
                  id="auto-complete"
                  autoComplete
                  includeInputInList
                  filterOptions={(options, state) => {
                    return options.filter(
                      (option) =>
                        option.id > 0 &&
                        `${option.id} - ${option.nome} - ${option.nome2}`
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase()),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // required={user.recursosId.length === 0}
                      {...((errorsNovoRegistoDiario.funcionarioId ||
                        errorsNovoRegistoPeriodo.funcionarioId) && {
                        error: true,
                        helperText:
                          errorsNovoRegistoDiario.funcionarioId ||
                          errorsNovoRegistoPeriodo.funcionarioId,
                      })}
                    />
                  )}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button
                  className={novoRegisto.tipo === 1 ? classes.btnTipo : classes.btnTipoSelecionado}
                  onClick={() => handleChangeTipo('diario')}
                >
                  Diário
                </Button>

                <Button
                  className={novoRegisto.tipo === 2 ? classes.btnTipo : classes.btnTipoSelecionado}
                  onClick={() => handleChangeTipo('periodo')}
                >
                  Periodo
                </Button>
              </div>
              <br />
              <div>
                {novoRegisto && novoRegisto.tipo === 1 && (
                  <RegistoDiario
                    novoRegisto={novoRegisto}
                    setNovoRegisto={setNovoRegisto}
                    classes={classes}
                    horas={horas}
                    setHoras={setHoras}
                    useWindowDimensions={useWindowDimensions}
                    // autocompletes
                    valueTipoAtrasoAutoComplete={valueTipoAtrasoAutoComplete}
                    setValueTipoAtrasoAutoComplete={setValueTipoAtrasoAutoComplete}
                    valueTipoPeriodoAutoComplete={valueTipoPeriodoAutoComplete}
                    setValueTipoPeriodoAutoComplete={setValueTipoPeriodoAutoComplete}
                    tiposperiodos={tiposperiodos}
                    tiposatrasos={tiposatrasos}
                    validationNovoRegistoDiario={validationNovoRegistoDiario}
                    errorsNovoRegistoDiario={errorsNovoRegistoDiario}
                  />
                )}
                {novoRegisto && novoRegisto.tipo === 2 && (
                  <RegistoPeriodo
                    novoRegisto={novoRegisto}
                    setNovoRegisto={setNovoRegisto}
                    classes={classes}
                    horas={horas}
                    setHoras={setHoras}
                    // autocompletes
                    valueTipoAtrasoAutoComplete={valueTipoAtrasoAutoComplete}
                    setValueTipoAtrasoAutoComplete={setValueTipoAtrasoAutoComplete}
                    valueTipoPeriodoAutoComplete={valueTipoPeriodoAutoComplete}
                    setValueTipoPeriodoAutoComplete={setValueTipoPeriodoAutoComplete}
                    tiposperiodos={tiposperiodos}
                    tiposatrasos={tiposatrasos}
                    validationNovoRegistoPeriodo={validationNovoRegistoPeriodo}
                    errorsNovoRegistoPeriodo={errorsNovoRegistoPeriodo}
                    useWindowDimensions={useWindowDimensions}
                  />
                )}
              </div>
              <br />
              <br />
              <div className={classes.modalBtns}>
                <Button
                  className={classes.btnCancelar}
                  variant="contained"
                  onClick={(e) => handleClose('add', e)}
                >
                  Cancelar
                </Button>
                <Button
                  className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                  disabled={!!loading}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {loading && (
                    <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                  )}
                  Confirmar
                </Button>
              </div>
            </Col>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalAdicionar;
