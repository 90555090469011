import Instance from '../../../utils/Axios';

const TodosOsFuncionariosRelatorio = () => async (dispatch) => {
  await Instance()
    .get('/relatorios/funcionarios')
    .then(({ data }) => {
      dispatch({ type: 'GET_TODOS_FUNCIONARIOS_RELATORIO', payload: data.yData });
    })
    .catch((err) => console.log(err));
};

export default TodosOsFuncionariosRelatorio;
