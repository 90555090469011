import Instance from '../../../../../utils/Axios';

const GetTodosFuncionariosValAD = () => async (dispatch) => {
  await Instance()
    .get(`/obras/validacao/funcionariosAdminDropdown`)
    .then(({ data }) => {
      dispatch({ type: 'GET_FUNCIONARIOS_VALIDACAO_AD', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTodosFuncionariosValAD;
