import { usersInitialState } from '../InitialState/users';

const usersReducer = (state = usersInitialState, { type, payload }) => {
  switch (type) {
    case 'GET_TODOS_USERS':
      return {
        ...state,
        todosUsers: payload,
      };
    case 'GET_USER':
      return {
        ...state,
        user: payload,
      };
    case 'POST_USER':
      return {
        ...state,
        novoUser: payload,
      };
    case 'PUT_USER':
      return {
        ...state,
        user: payload,
      };
    case 'DELETE_USER':
      return {
        ...state,
        userApagado: payload,
      };
    case 'GET_PERFIS':
      return {
        ...state,
        perfis: payload,
      };
    case 'GET_FUNCIONARIOS_UTILIZADORES':
      return {
        ...state,
        funcionarios: payload,
      };
    case 'RECUPERAR_SENHA':
      return {
        ...state,
        recuperarSenha: payload,
      };
    case 'GET_DEF_SENHA':
      return {
        ...state,
        dadosDefSenha: payload,
      };
    case 'DEFINIR_SENHA':
      return {
        ...state,
        definirSenha: payload,
      };

    // Permissões

    case 'GET_PERMISSOES':
      return {
        ...state,
        permissoes: payload,
      };

    default:
      return state;
  }
};

export default usersReducer;
