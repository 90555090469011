/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

import MUIDataTable from 'mui-datatables';

// Actions
import GetFuncionarioHoras from '../../../../../../../redux/actions/FuncionariosActions/horas/getFuncionarioHorasAction';
import { FiEdit, FiSearch, FiTrash2 } from 'react-icons/fi';

// MODALS
// import ModalDelete from './modals/modalDelete';
// import ModalRedefinirSenha from './modals/modalRedefinirSenha';
// import ModalEditarUtilizador from './modals/modalEditarUtilizador';

const CardHoras = ({
  funcId,
  dataInicioConsulta,
  dataFimConsulta,
  handleDataInicioConsultaChange,
  handleDataFimConsultaChange,
  handleOpen,
  classes,

  horas,
  width,
}) => {
  const dispatch = useDispatch();
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  // ====================== Search Text ====================== //

  const [searchText, setSearchText] = useState();

  // ========================================================= //

  const handleConsultar = (e) => {
    e.preventDefault();
    const dataInicioFormated = format(dataInicioConsulta, 'yyyy-MM-dd');
    const dataFimFormated = format(dataFimConsulta, 'yyyy-MM-dd');

    dispatch(GetFuncionarioHoras(funcId, dataInicioFormated, dataFimFormated));
  };

  const columns = [
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'obrasId',
      label: 'Obras Id',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'obrasDescricao',
      label: 'Descrição',
      options: {
        filter: false,
        sort: true,
        empty: true,
      },
    },
    {
      name: 'data',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          const formatedDate = format(new Date(tableMeta.rowData[3]), 'dd/MM/yyyy');

          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{formatedDate}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'horas',
      label: 'Horas',
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value) => {
          return (
            <div
              className="table-btns-categorias"
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <p>{value} h</p>
            </div>
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="table-btns-tiposfaltas">
              <Button
                onClick={(e) =>
                  // window.alert(`Clicked "Edit" for row ${tableMeta.rowIndex}`)
                  handleOpen('edit', e, value, tableMeta)
                }
              >
                <FiEdit size={30} strokeWidth={1} color="#000" />
              </Button>

              <Button
                onClick={(e) => {
                  handleOpen('delete', e, value, tableMeta);
                }}
              >
                <FiTrash2 size={30} strokeWidth={1} color="#000" />
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    print: false,
    search: false,
    searchText: searchText,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    elevation: 0,

    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        columnHeaderTooltip: (column) => `${column.label}`,
      },
      toolbar: {
        search: 'Pesquisar',
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
      filter: {
        all: 'Tudo',
        title: 'FILTROS',
        reset: 'Limpar',
      },
    },
  };

  return (
    <>
      {/* <Card>
            <CardBody> */}
      <Box sx={{ display: 'flex', mb: 2, flexWrap: 'wrap' }}>
        <Box
          sx={{
            width: width < 1100 ? '33%' : '20%',
            mr: '2%',
            mb: width < 1100 ? 2 : 0,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <p>
              <b>Data Inicial</b>
            </p>

            <DatePicker
              style={{ width: '100%' }}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dataInicioConsulta}
              onChange={handleDataInicioConsultaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            width: width < 1100 ? '33%' : '20%',
            mb: width < 1100 ? 2 : 0,
            mr: '2%',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
            <p>
              <b>Data Final</b>
            </p>
            <DatePicker
              style={{ width: '100%' }}
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              okLabel="ok"
              cancelLabel="cancelar"
              invalidDateMessage="Data Inválida"
              value={dataFimConsulta}
              onChange={handleDataFimConsultaChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'dd/mm/aaaa',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  variant="outlined"
                  size="small"
                />
              )}
              // minDate={dtMinDate}
              // maxDate={dtMaxDate}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ width: width < 1100 ? '0%' : '18%' }}></Box>
        <Box sx={{ width: width < 1100 ? '30%' : '8%', mr: width < 1100 ? 0 : '2%', mt: 2.5 }}>
          <Button
            size="sm"
            variant="contained"
            onClick={handleConsultar}
            fullWidth
            // style={{
            //   margin: 'auto 0 auto auto',
            // }}
            className={classes.btnConsultar}
          >
            Filtrar
          </Button>
        </Box>
        <Box sx={{ width: width < 1100 ? '100%' : '28%' }}>
          <b>Pesquisar</b>
          <TextField
            variant="outlined"
            size="small"
            name="search"
            fullWidth
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <FiSearch />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
        <h4
          style={{
            width: '100%',
          }}
          className="text-underline-title"
        >
          Registo de Horas
        </h4>
      </Box>
      <MUIDataTable
        title=""
        description="Nada para mostrar"
        data={horas}
        columns={columns}
        options={options}
      />
      {/* </CardBody>
          </Card> */}
    </>
  );
};

export default CardHoras;
