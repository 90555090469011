import Instance from '../../../../utils/Axios';

const GetDiasMarcados = (recursoId) => async (dispatch) => {
  const stateToken = localStorage.token;
  if (recursoId) {
    await Instance()
      .get(`/syncdiasferias/all_dias_ferias_marcados_recurso/${recursoId}`)
      .then(({ data }) => {
        if (stateToken && dispatch) {
          dispatch({
            type: 'GET_DIAS_MARCADOS',
            payload: data.yData.all_dias_ferias_marcados_recurso,
          });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default GetDiasMarcados;
