/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLocation,
  //  useHistory
} from 'react-router-dom';
import { ColorPicker } from 'mui-color';
import { Button, Checkbox, FormGroup, FormControlLabel, Box, Collapse } from '@mui/material';
import NotificationSystem from 'rc-notification';
import { styled } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RecursosForm from './RecursosForm';
import { FullWideNotification } from '../../../../../../shared/components/Notification';

// Card com a info da obra

// import InfoObra from '../../../../../../redux/actions/ObrasActions/InfoObraAction';
import CoresObras from '../../../../../../redux/actions/ObrasActions/CoresObrasAction';
import corObraAction from '../../../../../../redux/actions/ObrasActions/CorObraAction';

// Enviar Validacao apenas pelo admin
import EnviarValidacaoSoAdmin from '../../../../../../redux/actions/ObrasActions/validacaoSoAdmin/EnviarValidacaoAdmin';

let notificationTC = null;

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Recurso = ({ fetchRecursos, theme, classes }) => {
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const location = useLocation();
  const dispatch = useDispatch();
  // const history = useHistory();
  const id = location.pathname.split('/')[3];
  const ydata = useSelector((state) => state.recursos.infoObra);
  const coresObras = useSelector((state) => state.obras.cores);

  const user = useSelector((state) => state.user);
  const [corObra, setCorObra] = useState();
  const [oldCorObra, setOldCorObra] = useState();
  const [oldValidacao, setOldValidacao] = useState(null);

  const [checkboxes, setCheckboxes] = React.useState({
    validacaoAdmin: false,
  });

  const [loadingCor, setLoadingCor] = useState(false);
  const [loadingValRestrita, setLoadingValRestrita] = useState(false);

  // const fetchInfoObra = () => {
  //   dispatch(InfoObra(id, history));
  // };
  const fetchCoresObras = () => {
    dispatch(CoresObras());
  };

  const handleChangeCorObra = (value) => {
    setCorObra(value);
  };

  useEffect(() => setCorObra(ydata && ydata.cor), [ydata]);
  useEffect(() => setOldCorObra(ydata && ydata.cor), [ydata]);
  useEffect(() => {
    if (ydata && ydata.validacaoSoAdmin != undefined && oldValidacao == null) {
      setOldValidacao(ydata && ydata.validacaoSoAdmin);
    }
  }, [checkboxes]);
  useEffect(() => fetchCoresObras(), []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setCheckboxes({
      ...checkboxes,
      validacaoAdmin: ydata && ydata.validacaoSoAdmin,
    });
  }, [ydata]);

  useEffect(() => fetchRecursos(), []);

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    NotificationSystem.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  // ---------- Change Restrição ------------------- //

  const handleChange = (event) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });

    // eslint-disable-next-line no-param-reassign
    ydata.validacaoSoAdmin = event.target.checked;

    dispatch({ type: 'GET_RECURSOS', payload: { ...ydata } });
  };

  // ----------------------------------------------- //

  const handleSubmitCorObra = (e) => {
    e.preventDefault();

    const color = () => {
      return `#${corObra.hex}`;
    };

    if (!coresObras.includes(color())) {
      setLoadingCor(true);
      dispatch(corObraAction(id, color().toString(), oldCorObra, show))
        // .then(() => fetchInfoObra())
        .then(() => fetchRecursos())
        .then(() => setLoadingCor(false));
    } else {
      show('warning', 'A cor selecionada pertence a outra obra');
      setCorObra(corObra);
    }
  };
  const handleSubmitValAdmin = (e) => {
    e.preventDefault();
    setLoadingValRestrita(true);
    const yData = [
      {
        validacaoSoAdmin: checkboxes && checkboxes.validacaoAdmin == true ? 1 : 0,
      },
    ];

    dispatch(EnviarValidacaoSoAdmin(id, yData, checkboxes, oldValidacao, show))
      .then(() => fetchRecursos())
      .then(() => setOldValidacao(ydata && ydata.validacaoSoAdmin))
      .then(() => setLoadingValRestrita(false));
  };

  return (
    <>
      <Card>
        <CardBody>
          {ydata && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4 className="text-underline-title-dark">
                  {ydata.id} - {ydata.descricao}
                </h4>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: width < 1250 ? 'column' : 'row',
                    mt: 3,
                  }}
                >
                  <Box sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}>
                    <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                      <h4
                        style={{
                          width: '100%',
                        }}
                        className="text-underline-title"
                      >
                        Informação da Obra
                      </h4>
                    </Box>
                    {ydata.obraMorada1 ? (
                      <p>
                        <b>Morada:</b> {ydata.obraMorada1}
                      </p>
                    ) : null}
                    {ydata.obraCodigoPostal ? (
                      <p>
                        <b>Cód. Postal:</b> {ydata.obraCodigoPostal}
                      </p>
                    ) : null}
                    {ydata.obraDistrito ? (
                      <p>
                        <b>Distrito:</b> {ydata.obraDistrito}
                      </p>
                    ) : null}
                  </Box>
                  <Box sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}>
                    <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                      <h4
                        style={{
                          width: '100%',
                        }}
                        className="text-underline-title"
                      >
                        Informação do Cliente
                      </h4>
                    </Box>
                    {ydata.clienteNome ? (
                      <p>
                        <b>Cliente:</b> {ydata.clienteNome}
                      </p>
                    ) : null}
                    {ydata.clienteMorada1 ? (
                      <p>
                        <b>Morada:</b> {ydata.clienteMorada1}
                      </p>
                    ) : null}
                    {ydata.clienteCodigoPostal ? (
                      <p>
                        <b>Cód.Postal:</b> {ydata.clienteCodigoPostal}
                      </p>
                    ) : null}
                    {ydata.clienteCidade ? (
                      <p>
                        <b>Distrito:</b> {ydata.clienteCidade}
                      </p>
                    ) : null}
                  </Box>
                  <Box sx={{ width: width < 1250 ? null : '25%', mx: 2, mb: width < 1250 ? 3 : 0 }}>
                    <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                      <h4
                        style={{
                          width: '100%',
                        }}
                        className="text-underline-title"
                      >
                        Cor da Obra
                      </h4>
                    </Box>
                    <p />
                    <div style={{ display: 'flex' }}>
                      <ColorPicker
                        name="color"
                        value={corObra}
                        defaultValue={corObra}
                        hideTextfield
                        onChange={(value) => handleChangeCorObra(value)}
                      />
                      <Button
                        size="small"
                        // color="primary"
                        onClick={handleSubmitCorObra}
                        disabled={corObra === oldCorObra || !!loadingCor}
                        className={classes.btnMudarCor}
                        // className={loadingCor ? classes.btnMudarCorLoading : classes.btnMudarCor}
                      >
                        Mudar Cor
                      </Button>
                    </div>
                  </Box>
                  {user && user.permissoes.includes('OBRAS_VALIDACAO_ADMINISTRACAO') ? (
                    <Box
                      sx={{
                        width: width < 1250 ? null : '25%',
                        mx: 2,
                        mb: width < 1250 ? 3 : 0,
                      }}
                    >
                      <Box sx={{ borderBottom: '1px solid #000', mb: 3 }}>
                        <h4
                          style={{
                            width: '100%',
                          }}
                          className="text-underline-title"
                        >
                          Validação Restrita
                        </h4>
                      </Box>
                      <div style={{ display: 'flex', marginTop: '8px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkboxes && checkboxes.validacaoAdmin}
                                onChange={handleChange}
                                name="validacaoAdmin"
                              />
                            }
                            label="Apenas Administrador"
                          />
                        </FormGroup>

                        <Button
                          variant="contained"
                          // color="primary"
                          onClick={handleSubmitValAdmin}
                          disabled={
                            (checkboxes && checkboxes.validacaoAdmin == oldValidacao) ||
                            loadingValRestrita
                          }
                          className={classes.btnGuardar}
                          // className={
                          //   loadingValRestrita ? classes.btnMudarCorLoading : classes.btnMudarCor
                          // }
                        >
                          {/* {loadingValRestrita && (
                        <CircularProgress
                          size={14}
                          style={{ color: '#9d9d9d', marginRight: '10px' }}
                        />
                      )} */}
                          Guardar
                        </Button>
                      </div>
                    </Box>
                  ) : null}
                  {/* <CardInfoObra ydata={ydata} fetchRecursos={fetchRecursos} classes={classes} /> */}
                </Box>
              </Collapse>
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Box sx={{ margin: 2 }}>
            <Box sx={{ borderBottom: '1px solid #000', mb: 5 }}>
              <h4
                style={{
                  width: '100%',
                }}
                className="text-underline-title"
              >
                Funcionários
              </h4>
            </Box>

            {ydata && Object.keys(ydata).length > 0 ? (
              <RecursosForm id={id} infoObra={ydata} classes={classes} width={width} />
            ) : null}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default Recurso;
