/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import {
  Modal,
  Backdrop,
  Fade,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
// import { Button, Modal } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { grey } from '@mui/material/colors';
import { FullWideNotification } from '../../../../../shared/components/Notification';
import Form from './Tabs/Form';
import TabPermissoes from './Tabs/Permissoes';

import AdicionarUser from '../../../../../redux/actions/UsersActions/AdicionarUserAction';

let notificationTC = null;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 2,
  boxShadow: 3,
  p: 4,
  pt: 3,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 6, 5),
    maxWidth: '600px',
    width: '600px',
    margin: 15,
    minHeight: '70vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '80vh',
  },
  btnConfirmar: {
    color: 'white',
    backgroundColor: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
  },
  btnConfirmarLoading: {
    width: '48%',
    height: '50px',
    backgroundColor: grey[300],
  },
  btnCancelar: {
    color: '#E21450',
    width: '100px',
    height: '40px',
    fontWeight: 'bold',
    // borderRadius: '15px',
    backgroundColor: 'white',
    border: `1px solid #707070`,
    display: 'flex',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: '#ECECEC',
      border: '1px solid #707070',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: '#F7F7F7 ',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    height: '80px',
    width: '100%',
    justifyContent: 'space-evenly',
  },
}));

const ModalAdicionar = ({
  openAdicionar,
  theme,
  validateOnChange = true,
  handleClose,
  fetchUsers,
  user,
  setUser,
  resetForm,
  toggle,
  // Loading
  loading,
  setLoading,
}) => {
  const [errors, setErrors] = useState({});

  const permissoes = useSelector((state) => state.users.permissoes);

  const dispatch = useDispatch();
  const classes = useStyles();

  // ---------- Notification Sytem ------------------- //

  useEffect(() => {
    NotificationSystem.newInstance({ style: { top: 65, zIndex: 9999 } }, (n) => {
      notificationTC = n;
    });
  }, [dispatch]);

  const showNotification = ({ notification, position }, direction) => {
    const notificationDefaultProps = {
      content: notification(theme),
      duration: 2,
      closable: true,
      className: `${position} ${direction}-support`,
    };

    notificationTC.notice({
      ...notificationDefaultProps,
      style: { top: -60, left: 0 },
    });
  };

  const show = (color, mensagem) =>
    showNotification({
      notification() {
        return <FullWideNotification color={color} message={mensagem} theme={theme} />;
      },
      position: 'full',
    });

  // ----------------------------------------------- //

  useEffect(() => setErrors({}), [resetForm]);

  const validation = (fieldValues = user) => {
    const temp = { ...errors };
    if ('nome' in fieldValues) temp.nome = fieldValues.nome ? '' : 'Campo Obrigatório';
    if ('username' in fieldValues) temp.username = fieldValues.username ? '' : 'Campo Obrigatório';
    if ('recursosId' in fieldValues)
      temp.recursosId = fieldValues.recursosId.length != 0 ? '' : 'Campo Obrigatório';
    if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues) {
      if (!user.confirmarPassword) {
        temp.confirmarPassword = 'Campo Obrigatório';
      } else if (user.confirmarPassword === user.password) {
        temp.confirmarPassword = '';
      } else {
        temp.confirmarPassword = 'Verifique se a palavra passe é igual';
      }
    }

    if ('perfisId' in fieldValues)
      temp.perfisId = fieldValues.perfisId.length != 0 ? '' : 'Campo Obrigatório';
    setErrors({
      ...temp,
    });

    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  const validationPassword = (fieldValues = user) => {
    const temp = { ...errors };

    // console.log(temp, fieldValues);
    if ('password' in fieldValues) temp.password = fieldValues.password ? '' : 'Campo Obrigatório';
    if ('confirmarPassword' in fieldValues) {
      if (!user.confirmarPassword) {
        temp.confirmarPassword = 'Campo Obrigatório';
      } else if (user.confirmarPassword === user.password) {
        temp.confirmarPassword = '';
      } else {
        temp.confirmarPassword = 'Verique se a palavra passe é igual';
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (user.password || user.confirmarPassword) validationPassword();
    });
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.confirmarPassword, user.password]);

  const handleFields = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    if (validateOnChange) validation({ [e.target.name]: e.target.value });
    // if (validateOnChange) validation();
    // if (validateOnChange) validationPassword();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const ydata = [
      {
        nome: user.nome,
        username: user.username,
        password: user.password,
        // confirmarPassword: user.confirmarPassword,
        perfisId: user.perfisId,
        recursosId: user.recursosId,
        permissoes: permissoes
          .map((p) => p.lista)
          .flat()
          .map((permissao) => {
            if (permissao.permissao) {
              return permissao.id;
            }
            return null;
          })
          .filter((permissao) => {
            return permissao != null;
          }),
      },
    ];

    if (validation()) {
      setLoading(true);
      dispatch(AdicionarUser(ydata, show, toggle))
        .then(() => handleClose('adicionar', e))
        .then(() => fetchUsers())
        .then(() => resetForm());
    }
  };

  // -----------------------Handle Tabs---------------------//
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // ------------------------------------------------------//

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdicionar}
        // toggle={toggle}
        onClose={(e) => handleClose('adicionar', e)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAdicionar}>
          <Box sx={style}>
            <div className="header-modal">
              <h3 id="transition-modal-title" className="text-underline-title-dark">
                Adicionar Utilizador
              </h3>
              <p id="transition-modal-description">Pode adicionar aqui um novo utilizador.</p>
            </div>
            <div>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Informações" {...a11yProps(0)} />
                  <Tab label="Permissões" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                <Form
                  handleFields={handleFields}
                  user={user}
                  setUser={setUser}
                  onSubmit={handleSubmit}
                  toggle={toggle}
                  errors={errors}
                  resetForm={resetForm}
                  validation={validation}
                  validationPassword={validationPassword}
                  handleClose={handleClose}
                />
              </TabPanel>
              <TabPanel value={value} index={1} style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                <TabPermissoes openAdicionar={openAdicionar} />
              </TabPanel>
            </div>
            <div className={classes.modalBtns}>
              <Button
                className={loading ? classes.btnConfirmarLoading : classes.btnConfirmar}
                disabled={!!loading}
                variant="contained"
                onClick={handleSubmit}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: '#9d9d9d', marginRight: '10px' }} />
                )}
                Confirmar
              </Button>

              <Button
                className={classes.btnCancelar}
                variant="contained"
                onClick={(e) => handleClose('adicionar', e)}
              >
                Cancelar
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalAdicionar;
