import Instance from '../../../../utils/Axios';

const GetValidacaoIndividualCD = (id) => async (dispatch) => {
  await Instance()
    .get(`/obras/validacao/chefedepartamento/${id}`)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_VALIDACAO_INDIVIDUAL_CD', payload: data.yData[0] });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetValidacaoIndividualCD;
