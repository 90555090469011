import Instance from '../../../../utils/Axios';

const RelatorioColaboradoresAtivos = (dataRegisto) => async (dispatch) => {
  await Instance()
    .get(`/relatorios/colaboradoresativos`, {
      params: { dataRegisto },
    })
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: 'GET_RELATORIO_COLABORADORES_ATIVOS', payload: data });
    })
    .catch(({ err }) => console.log(err));
};

export default RelatorioColaboradoresAtivos;
