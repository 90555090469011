import React from 'react';
import { Box, Button } from '@mui/material';

const HorasButtons = ({ recurso, classes, handleData }) => {
  return (
    <Box sx={{ marginLeft: '2%', justifyContent: 'flex-end' }}>
      {recurso.qtdDiario <= 0 ? null : (
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtdDiario - 1);
          }}
          className={classes.buttons}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          -
        </Button>
      )}
      {recurso.qtdDiario >= 24 ? (
        <Button className={classes.buttons} color="primary" size="sm" disabled>
          +
        </Button>
      ) : (
        <Button
          className={classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtdDiario + 1);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +
        </Button>
      )}
      {recurso.qtdDiario > 20 ? (
        <Button className={classes.buttons} color="primary" size="sm" disabled>
          +4h
        </Button>
      ) : (
        <Button
          className={classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtdDiario + 4);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +4h
        </Button>
      )}
      {recurso.qtdDiario > 16 ? (
        <Button className={classes.buttons} color="primary" size="sm" disabled>
          +8h
        </Button>
      ) : (
        <Button
          className={classes.buttons}
          color="primary"
          size="sm"
          onClick={() => {
            handleData(recurso.qtdDiario + 8);
          }}
          disabled={recurso && (recurso.validadoCD || recurso.validadoAD || recurso.validadoRH)}
        >
          +8h
        </Button>
      )}
    </Box>
  );
};

export default HorasButtons;
