import { authInitialState } from '../InitialState/auth';

const authReducer = (state = authInitialState, { type, payload }) => {
  switch (type) {
    case 'SIGN_IN':
      return {
        ...state,
        token: payload.token,
        exp: payload.expiresIn,
        username: payload.user.username,
        id: payload.user.id,
        role: payload.user.perfis_id,
        nome: payload.user.nome,
        permissoes: payload.user.permissoes,
      };
    default:
      return state;
  }
};

export default authReducer;
