/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { eachDayOfInterval, getDate, getDay } from 'date-fns';
import { Button } from '@mui/material';
// Datatable
import MUIDataTable from 'mui-datatables';

// eslint-disable-next-line no-unused-vars
const HistoricoCompleto = ({
  mes,
  ano,
  horas,
  dataFim,
  dataInicio,
  handlePdf,
  valorHora,
  classes,
}) => {
  const [responsive] = useState('standard');
  const [tableBodyHeight] = useState('100%');
  const [tableBodyMaxHeight] = useState('');

  // let horas = useSelector((state) => state.obras.historicoMensal);

  let mesAnterior = mes - 1;
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  if (horas && horas.length > 0) {
    horas = horas.map((a) => {
      a.recursos_id = a.recursos_id.toString();
      a.recursos_nome2 = a.recursos_nome2 === '' ? a.recursos_nome : a.recursos_nome2;
      // console.log('a', a);
      a.d1 = a.d1.toString();
      a.d2 = a.d2.toString();
      a.d3 = a.d3.toString();
      a.d4 = a.d4.toString();
      a.d5 = a.d5.toString();
      a.d6 = a.d6.toString();
      a.d7 = a.d7.toString();
      a.d8 = a.d8.toString();
      a.d9 = a.d9.toString();
      a.d10 = a.d10.toString();
      a.d11 = a.d11.toString();
      a.d12 = a.d12.toString();
      a.d13 = a.d13.toString();
      a.d14 = a.d14.toString();
      a.d15 = a.d15.toString();
      a.d16 = a.d16.toString();
      a.d17 = a.d17.toString();
      a.d18 = a.d18.toString();
      a.d19 = a.d19.toString();
      a.d20 = a.d20.toString();
      a.d21 = a.d21.toString();
      a.d22 = a.d22.toString();
      a.d23 = a.d23.toString();
      a.d24 = a.d24.toString();
      a.d25 = a.d25.toString();
      a.d26 = a.d26.toString();
      a.d27 = a.d27.toString();
      a.d28 = a.d28.toString();
      if (mesAnterior === 2) {
        if (ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0)) {
          a.d29 = a.d29.toString();
        }
      } else {
        a.d29 = a.d29 && a.d29.toString();
        a.d30 = a.d30 && a.d30.toString();

        if ([1, 3, 5, 7, 8, 10, 12].indexOf(mesAnterior) >= 0) {
          a.d31 = a.d31 && a.d31.toString();
        }
      }
      a.totalHoras = a.totalHoras.toString();
      if (typeof a.total == 'number') {
        a.total = `${a.total.toFixed(2).toString()} €`;
        // a.total = parseInt(`${a.total}`, 10).toFixed(2);
      }
      if (valorHora) {
        if (typeof a.valorPorHora == 'number') {
          a.valorPorHora = `${a.valorPorHora.toFixed(2).toString()} €`;
        }
      }

      return a;
    });
  }

  const columns = [
    {
      name: 'recursos_nome2',
      label: 'Nome',
      options: {
        filter: true,
        sort: false,
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          if (horas.length - 1 != rowIndex) {
            return {
              style: {
                minWidth: '250px',
                maxWidth: '350px',
                border: '1px solid rgb(200, 200, 200)',
              },
            };
          }
          return {
            style: {
              minWidth: '250px',
              maxWidth: '350px',
            },
          };
        },

        setCellHeaderProps: () => ({
          style: {
            background: 'rgb(230, 230, 230)',
            border: '1px solid rgb(200, 200, 200)',
          },
        }),
      },
    },
  ];

  if (valorHora) {
    columns.push({
      name: 'valorPorHora',
      label: 'Valor',
      options: {
        filter: true,
        sort: false,
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          if (horas.length - 1 != rowIndex) {
            return {
              style: {
                border: '1px solid rgb(200, 200, 200)',
              },
            };
          }
          return {
            style: {
              minWidth: '80px',
              maxWidth: '100px',
            },
          };
        },
        setCellHeaderProps: () => ({
          align: 'center',
          style: {
            background: 'rgb(230, 230, 230)',
            border: '1px solid rgb(200, 200, 200)',
          },
        }),
      },
    });
  }

  const todosDias = eachDayOfInterval({
    start: new Date(dataInicio),
    end: new Date(dataFim),
  });

  todosDias.forEach((TodosDias) => {
    const dia = getDate(TodosDias);
    columns.push({
      name: `d${dia}`,
      label: `${dia}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const index = tableMeta.rowIndex;
          const horasLength = horas && horas.length - 1;

          const property = `d${dia}cor`;
          const horasDia = `d${dia}`;
          const ausencias = `d${dia}ausencia`;

          return (
            <>
              {getDay(TodosDias) !== 0 && getDay(TodosDias) !== 6 ? (
                <div style={{ textAlign: 'center' }}>
                  {horas[index][ausencias] != null ? (
                    <>{horas[index][ausencias]}</>
                  ) : (
                    <>{horas[index][horasDia]}</>
                  )}
                </div>
              ) : (
                <div>
                  {horas[index][ausencias] != null ? <>{horas[index][ausencias]}</> : null}
                  {horas[index][horasDia] > 0 ? (
                    <>{horas[index][horasDia]}</>
                  ) : (
                    <>
                      {horasLength !== tableMeta.rowIndex ? (
                        <div style={{ textAlign: 'center' }}>
                          {!horas[index][ausencias] && <span>-</span>}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </>
          );
        },
        setCellHeaderProps: (value) => {
          if (getDay(TodosDias) === 0 || getDay(TodosDias) === 6) {
            return {
              style: {
                background: 'lightgreen',
                border: '1px solid rgb(200, 200, 200)',
                textAlign: 'center',
              },
            };
          }
          return {
            style: {
              background: 'rgb(230, 230, 230)',
              border: '1px solid rgb(200, 200, 200)',
              textAlign: 'center',
            },
          };
        },
        setCellProps: (cellValue, rowIndex, columnIndex) => {
          const cor = `d${dia}cor`;
          const horasDia = `d${dia}`;
          const ausencias = `d${dia}ausencia`;
          const horasLength = horas && horas.length - 1;

          if (getDay(TodosDias) === 0 || getDay(TodosDias) === 6) {
            if (horas[rowIndex][horasDia] > 0) {
              return {
                style: {
                  background: `${horas[rowIndex][cor]}`,
                  border: '1px solid rgb(200, 200, 200)',
                },
              };
            }
            if (horasLength != rowIndex) {
              return {
                style: {
                  border: '1px solid rgb(200, 200, 200)',
                },
              };
            }
          }
          if (horas[rowIndex][horasDia] >= 0)
            return {
              style: {
                background: `${horas[rowIndex][cor]}`,
                border: '1px solid rgb(200, 200, 200)',
              },
            };

          return null;
        },
      },
    });
  });

  columns.push({
    name: 'totalHoras',
    label: 'Total Horas',
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'right', border: '1px solid rgb(200, 200, 200)' },
      }),
      setCellHeaderProps: () => ({
        align: 'center',
        style: {
          background: 'rgb(230, 230, 230)',
          border: '1px solid rgb(200, 200, 200)',

          borderBottom: '1px solid rgb(200, 200, 200)',
        },
      }),
    },
  });
  if (valorHora) {
    columns.push({
      name: 'total',
      label: 'Total',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            minWidth: '100px',
            maxWidth: '300px',
            textAlign: 'right',
            border: '1px solid rgb(200, 200, 200)',
            borderRight: '1px solid rgb(200, 200, 200)',
          },
        }),
        setCellHeaderProps: () => ({
          align: 'center',
          style: {
            background: 'rgb(230, 230, 230)',
            border: '1px solid rgb(200, 200, 200)',
            borderRight: '1px solid rgb(200, 200, 200)',
          },
        }),
      },
    });
  }
  let Total = 0;

  if (horas && horas.length > 0) {
    if (!horas.find((valTotal) => valTotal.totalHoras === 'Total')) {
      // eslint-disable-next-line no-unused-expressions
      horas &&
        // eslint-disable-next-line array-callback-return
        horas.map((dados) => {
          Total += parseFloat(dados.total, 10);
        });

      if (valorHora) {
        horas.push({
          totalHoras: 'Total',
          total: `${Total} €`,
        });
      } else {
        horas.push({
          totalHoras: '',
          total: '',
        });
      }
    }
  }

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    viewColumns: false,
    elevation: 0,
    pagination: true,
    print: false,
    download: false,
    textLabels: {
      body: {
        noMatch: 'Sem Registos encontrados',
        toolTip: 'Dia',
        columnHeaderTooltip: (column) => `Dia ${column.label}`,
      },
      pagination: {
        next: 'Proxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Mostrar:',
        displayRows: 'de',
      },
    },
  };

  console.log(horas);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Button
          size="small"
          // color="primary"
          variant="contained"
          onClick={handlePdf(horas)}
          style={{ marginTop: '2px' }}
          className={classes.btnGuardar}
          disabled={horas <= 0}
        >
          Gerar PDF
        </Button>
      </div>
      <MUIDataTable
        title="Historico"
        description="Nada para mostrar"
        data={horas}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default HistoricoCompleto;
