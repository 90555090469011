import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
// import Link from "@mui/material/Link";

const BreadHistoricoObras = () => (
  <Breadcrumbs
    separator="›"
    aria-label="breadcrumb"
    style={{ marginBottom: '10px', marginTop: '5px' }}
  >
    <Link to="/app" color="textPrimary">
      Inicio
    </Link>
    <Link to="/app/obras" color="textPrimary">
      Lista de Obras
    </Link>
    <Typography color="textPrimary">Detalhes</Typography>
  </Breadcrumbs>
);
export default BreadHistoricoObras;
