import Instance from '../../../utils/Axios';

// New Actions for the app

const GetTiposAtrasosDropdown = () => async (dispatch) => {
  await Instance()
    .get(`/tipoatrasos/dropdown`)
    .then(({ data }) => {
      dispatch({ type: 'GET_TIPOATRASOS_DROPDOWN', payload: data.yData });
    })
    .catch((err) => {
      console.log(err);
    });
};

export default GetTiposAtrasosDropdown;
