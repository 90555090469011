export const dashboardInitialState = {
  graficos: {
    AlocacaoRecursos: {
      recursosAlocados: 0,
      recursosNaoAlocados: 0,
    },
    EstadosObras: {
      obrasAbertas: 0,
      obrasFechadas: 0,
    },
  },
};
