import Instance from '../../../../utils/Axios';

const GetFuncionariosEscritorio = () => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .get('/obras/registohorasescritorio/funcionarios')
      .then(({ data }) => {
        if (stateToken) {
          dispatch({ type: 'GET_FUNCIONARIOS_ESCRITORIO', payload: data.yData });
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

export default GetFuncionariosEscritorio;
