import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
// import Link from "@mui/material/Link";

const BreadRegistarHoras = () => (
  <Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: '10px' }}>
    <Link to="/app" color="textPrimary">
      Inicio
    </Link>
    <Link to="/app/registarHoras/operacionais" color="textPrimary">
      Lista de Obras
    </Link>
    <Typography color="textPrimary">Registar Horas</Typography>
  </Breadcrumbs>
);
export default BreadRegistarHoras;
