import React from 'react';
import { useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Checkboxes = ({ checkboxes, setCheckboxes, funcionarioStore }) => {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });

    // if (event.target.name == 'ativo') {
    // eslint-disable-next-line no-param-reassign
    funcionarioStore.ativo = !event.target.checked;
    dispatch({
      type: 'GET_USER',
      payload: { ...funcionarioStore },
    });
    //   console.log(funcionarioStore);
    // }
  };

  return (
    <>
      {/* //<FormGroup> */}
      <FormControlLabel
        control={<Checkbox checked={checkboxes.ativo} onChange={handleChange} name="ativo" />}
        label="Ativo"
        style={{ width: '48%', marginTop: '20px' }}
      />
      <FormControlLabel
        control={
          <Checkbox checked={checkboxes.operacional} onChange={handleChange} name="operacional" />
        }
        label="Operacional"
        style={{ width: '48%', marginTop: '20px' }}
      />

      {/* </FormGroup> */}
    </>
  );
};

export default Checkboxes;
