import Instance from '../../../../utils/Axios';

const EnviarValidacaoSoAdmin = (id, ydata, checkboxes, oldValidacao, show) => async (dispatch) => {
  const stateToken = localStorage.token;

  if (stateToken) {
    await Instance()
      .put(`/obras/${id}/validacaoSoAdmin`, { ydata })
      .then(({ data }) => {
        if (checkboxes.validacaoAdmin == oldValidacao) {
          show('warning', 'Restrição de validação não modificada');
        } else if (checkboxes.validacaoAdmin != oldValidacao && stateToken) {
          dispatch({ type: 'VALIDACAO_OBRA_ADMIN', payload: data.yData[0] });
          show('success', 'A restrição da validação da obra foi mudada!');
        }
      })
      .catch(({ err }) => console.log(err));
  }
};

// Meter aqui o put para enviar o HE

export default EnviarValidacaoSoAdmin;
